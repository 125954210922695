<script>
export default {
    name: "agreement-edit",

    props: ['agreement'],

    data() {
        return {
            errors: [],
            agreementUpdate: {},
            councilsData: [],
        }
    },

    mounted() {
        this.agreementUpdate = JSON.parse(this.agreement);
    },

    methods: {
        update() {
            this.errors = [];

            axios.patch('/admin/agreements/' + this.agreementUpdate.id, {
                data: {
                    id: this.agreementUpdate.id,
                    name: this.agreementUpdate.name
                }
            }).then(res => {

            }).catch(error => {
                this.errors = error.response.data.errors;
            })
        },
    }
}
</script>
