<template>
    <div class="">
        <div class="modal fade" tabindex="-1" id="itemModal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mb-0">{{ modaltitle }}</h5>

                        <!--begin::Close-->
                        <div class="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            data-bs-dismiss="modal" aria-label="Close">
                            <span class="svg-icon svg-icon-2x"></span>
                        </div>
                        <!--end::Close-->
                    </div>

                    <div class="modal-body">
                        <p class="mb-0">{{ modaltext }}</p>
                    </div>

                    <div class="modal-footer d-flex justify-content-between">
                        <button type="button" class="btn btn-light" data-bs-dismiss="modal" v-text="$gettext('Close')"></button>
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="modalConfirm()">{{ buttontext }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
    export default {
        props: ['modaltitle', 'modaltext', 'buttontext'],

        methods: {
            modalConfirm(){
                this.$root.$emit('modal-confirmed');
            }
        },
    }
</script>
