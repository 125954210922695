<template>
    <div class="container">
        <div class="row mx-0">
            <div class="col-lg-6 col-md-10 mx-auto px-0">
                <div class="card my-3 p-4 shadow-sm opacity">
                    <div class="card-header bg-white p-0 border-0 mb-2 d-flex justify-content-between">
                        <h1 class="title fw-bold">Wachtwoord vergeten</h1>

                        <router-link to="/" class="text-dark text-decoration-none close-button">×</router-link>
                    </div>

                    <div class="card-body p-0">
                        <form @submit.prevent="sendEmail()" v-if="!loader && !success">
                            <div class="form-group mb-3">
                                <input type="email" class="form-control bg-transparent fw-bold shadow-none"
                                       placeholder="E-mailadres" required v-model="email">

                                <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                      v-if="errors.email" v-for="(name, index) in errors.email">
                                    <strong v-text="errors.email[index]"></strong>
                                </span>
                            </div>

                            <div class="d-flex justify-content-center sign-in-btn">
                                <!-- Submit button -->
                                <button type="submit" class="btn btn-success fw-bold text-white w-100">Verstuur</button>
                            </div>
                        </form>

                        <div v-else-if="loader" class="text-center">
                            <div class="spinner-border text-success" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>

                        <div v-else-if="success" class="text-center">
                            <img class="mb-4" :src="asset('images/approved.png')" alt="Goedgekeurd">

                            <h2 class="title">Uw aanvraag is ingediend!</h2>
                            <h4 class="title">Controleer uw e-mailadres en klik op de link om uw wachtwoord opnieuw in te stellen.</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ImageMixin from "../mixins/asset";

export default {
    name: "ResetPassword",

    mixins: [ImageMixin],

    data() {
        return {
            email: '',
            errors: [],
            loader: false,
            success: false,
        }
    },

    methods: {
        sendEmail() {
            this.loader = true;

            axios.post('/forgot-password', {
                email: this.email
            }).then(res => {
                if (res.status == 200) {
                    this.loader = false;
                    this.success = true;
                }
            }).catch((error) => {
                this.loader = false;
                this.errors = error.response.data.errors
            });
        }
    },
}
</script>

<style scoped>
.form-control {
    border: none;
    border-bottom: 1px solid #d3d3d3;
    height: 50px;
    font-size: 16px;
}

.sign-in-btn button {
    font-size: 25px;
}
</style>
