<template>
    <div class="container">
        <div class="row mx-0">
            <div class="col-lg-6 col-md-10 col-12 mx-auto px-0">
                <div class="card my-3 p-4 shadow-sm">
                    <div class="card-body p-md-3 p-2">
                        <div class="text-right p-2">
                            <router-link to="/" class="text-dark text-decoration-none close-button">×</router-link>
                        </div>
                        <form @submit.prevent="submitForm()">
                            <div class="mb-3">
                                <div class="form-group mb-4">
                                    <!-- Email input -->
                                    <input class="form-control bg-transparent fw-bold shadow-none" type="email"
                                           name="email" placeholder="E-mailadres" v-model="form.email" required/>

                                    <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                          v-if="errors.email" v-for="(name, index) in errors.email">
                                        <strong v-text="errors.email[index]"></strong>
                                    </span>
                                </div>

                                <!-- Password input -->
                                <div class="form-group mb-4">
                                    <input class="form-control bg-transparent fw-bold shadow-none" type="password"
                                           name="password" placeholder="Wachtwoord" v-model="form.password" required/>

                                    <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                          v-if="errors.password" v-for="(name, index) in errors.password">
                                        <strong v-text="errors.password[index]"></strong>
                                    </span>
                                </div>

                                <!-- Checkbox -->
                                <div class="form-check d-flex">
                                    <input class="form-check-input" type="checkbox" value=""/>
                                    <label class="form-check-label ms-1" for="form1Example3">Onthoud mij</label>
                                </div>
                            </div>

                            <div class="d-flex justify-content-center sign-in-btn mb-4">
                                <!-- Submit button -->
                                <button type="submit" class="btn btn-success fw-bold text-white w-100">Inloggen</button>
                            </div>

                            <div class="text-center mb-4">
                                <p class="mb-0">
                                    Ben je nog geen lid van Verbeter de buurt?
                                    <router-link to="/registreren" class="text-decoration-none text-success">
                                        Registreren
                                    </router-link>
                                </p>

                                <p>
                                    <router-link to="/wachtwoord" class="text-decoration-none text-success">
                                        Wachtwoord Vergeten
                                    </router-link>
                                </p>
                            </div>

                            <div class="text-center my-4">
                                <h4 class="h4 middle-border opacity">Of</h4>
                            </div>

                            <div class="d-flex justify-content-center align-items-center social-icons">
                                <a href="social-register/apple" class="btn btn-apple">
                                    <i class="fab fa-apple"></i>
                                </a>
                                <a href="social-register/google" class="btn btn-google">
                                    <i class="fab fa-google text-white"></i>
                                </a>
<!--                                <button type="button" class="btn btn-facebook" @click="socialLogin('facebook')">
                                    <i class="fab fa-facebook-square text-white"></i>
                                </button>
                                <button type="button" class="btn btn-twitter" @click="socialLogin('twitter')">
                                    <i class="fab fa-twitter text-white"></i>
                                </button>-->
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ImageMixin from "../mixins/asset";

export default {
    name: "Login",

    mixins: [ImageMixin],

    mounted() {
        if (this.$root.authUser) this.$root.$router.push({ name: "/" });

        if (this.$route.query) this.form.email = this.$route.query.email
    },

    data() {
        return {
            form: {
                email: "",
                password: "",
            },
            errors: {
                email: "",
                password: "",
            },
        };
    },

    methods: {
        submitForm() {
            this.errors.email = "";
            this.errors.password = "";

            axios.post("/login", this.form).then((response) => {
                // Perform Success Action
                this.$root.authUser = true;
                this.$root.user = response.data.user;

                sessionStorage.removeItem('message');

                sessionStorage.setItem("message", "Je bent ingelogd als " + this.$root.user.first_name);
                sessionStorage.setItem("alert_type", "success");

                this.$root.$emit("show-flash-message");
                this.$root.$router.push({ name: "home" });
            }).catch((error) => {
                for (let key in error.response.data.errors) {
                    this.errors[key] = error.response.data.errors[key];
                }
            }).finally(() => {
                //Perform action in always
            });
        },

        socialLogin(provider) {
            axios.post('/social-register', {
                'provider': provider,
            }, ).then(res => {
                this.$root.authUser = true;
                this.$root.user = res.data.user;

                sessionStorage.removeItem('message');

                sessionStorage.setItem("message", "Je bent ingelogd als " + this.$root.user.first_name);
                sessionStorage.setItem("alert_type", "success");

                this.$root.$emit("show-flash-message");
                this.$root.$router.push({ name: "home" });
            })
        }
    },
};
</script>

<style scoped>
.card {
    background: rgba(255,255,255,.9);
}

.form-control {
    border: none;
    border-bottom: 1px solid #d3d3d3;
    height: 50px;
    font-size: 16px;
}

.sign-in-btn button {
    font-size: 25px;
}
</style>
