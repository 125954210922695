<template>
    <div class="modal fade" tabindex="-1" id="grantReportModal">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ modaltitle }}</h5>

                    <!--begin::Close-->
                    <div class="btn btn-icon btn-sm btn-active-light-primary ms-2"
                         data-bs-dismiss="modal" aria-label="Close">
                        <span class="svg-icon svg-icon-2x"></span>
                    </div>
                    <!--end::Close-->
                </div>

                <div class="modal-body" v-if="modaltext">
                    <p>{{ modaltext }}</p>
                </div>

                <div class="m-4">
                    <v-autocomplete dense auto-select-first clearable filled solo ref="user"
                        hide-selected v-model="selectedUser" :items="users"
                        color="white" item-text="full_name" item-value="id" class="form-control me-md-2"
                        :placeholder="$gettext('Please choose option...')"
                        @change="hideMenu('user')" @input="showMenu('user')"
                        @mouseup="showMenu('user')" @click:append-outer="toggleMenu('user')">
                    </v-autocomplete>
                </div>

                <div class="modal-footer d-flex justify-content-between">
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal"
                            v-text="$gettext('Close')"></button>
                    <button type="button" class="btn admin-create-button"
                            data-bs-dismiss="modal" @click="grant()" :disabled="selectedUser === ''">
                        {{ buttontext }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
import AutocompleteMixin from "../mixins/autocomplete";

export default {
    name: 'grant-report-to-user',

    props: [
        'modaltitle',
        'modaltext',
        'buttontext',
        'report_id',
    ],

    mixins: [
        AutocompleteMixin
    ],

    data() {
        return {
            users: [],
            selectedUser: '',
        };
    },

    methods: {
        grant() {
            axios.post("/admin/assign-user-to-report", {
                user_id: this.selectedUser,
                report_id: this.report_id,
            }).then(() => {
                axios.post('/admin/reports/update-status/' + this.report_id, {
                    status: 'in_treatment'
                }).then(() => {

                }).catch(() => {
                    this.$root.$emit('modal-message-ajax', {
                        title: 'Error',
                        body: 'ERROR: Something went wrong. Status cannot be assigned.',
                    });
                });

                let userDetails = this.users.filter(el => el.id === this.selectedUser)[0];

                this.$root.$emit('modal-message-ajax', {
                    title: 'Success',
                    body: 'The report was assigned to ' + userDetails.first_name + userDetails.last_name,
                });
            }).catch(() => {
                this.$root.$emit('modal-message-ajax', {
                    title: 'Error',
                    body: 'ERROR: Something went wrong',
                });
            }).finally(() => {

            });
        },
    },

    mounted() {
        let self = this;

        this.$root.$on("get-users", function () {
            axios.post("/admin/users/all/admins", {
                report_id:self.report_id,

            }).then((response) => {
                self.users = response.data.data;
            }).catch((error) => {
            }).finally(() => {
                //Perform action in always
            });

            self.selectedUser = self.$parent.$parent.$data.report.assignee_id ?? '';
        });
    },
};
</script>

<style>
    #grantReportModal .v-input,
    #grantReportModal .v-input__control {
        width: 100%;
    }
</style>
