<template>
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-10 mx-auto px-0">
                <div class="card my-3 p-3 shadow-sm opacity">
                    <div class="card-header bg-white p-0 border-0 d-flex justify-content-between">
                        <h1 class="title fw-bold">Contacten</h1>

                        <router-link to="/" class="text-dark text-decoration-none close-button">×</router-link>
                    </div>

                    <div class="card-body p-0">
                        <form action="" @submit.prevent="submit()">
                            <div class="form-group">
                                <label for="first_name" class="col-form-label">Naam:</label>
                                <input type="text" class="form-control" name="first_name" id="first_name"
                                       v-model="first_name" placeholder="Naam">
                            </div>

                            <div class="form-group">
                                <label for="surname" class="col-form-label">Achternaam:</label>
                                <input type="text" class="form-control" name="surname" id="surname"
                                       v-model="surname" placeholder="Achternaam">
                            </div>

                            <div class="form-group">
                                <label for="email" class="col-form-label">E-mailadres: *</label>
                                <input type="email" class="form-control" name="email" id="email"
                                       v-model="email" placeholder="E-mailadres" required>
                            </div>

                            <div class="form-group">
                                <label for="subject" class="col-form-label">Onderwerp:</label>
                                <input type="text" class="form-control" name="subject" id="subject"
                                       v-model="subject" placeholder="Onderwerp">
                            </div>

                            <div class="form-group mb-3">
                                <label for="message" class="col-form-label">Bericht: *</label>
                                <textarea class="form-control" name="message" id="message" rows="5"
                                          v-model="message" placeholder="Bericht" required></textarea>
                            </div>

                            <h6 class="text-danger h6">Houd er rekening mee dat meldingen die via dit contactformulier worden ingediend NIET in behandeling genomen zullen worden!</h6>

                            <vue-recaptcha
                                ref="recaptcha" @verify="verify"
                                @expired="resetCaptcha()" size="invisible"
                                :sitekey="key" :loadRecaptchaScript="true">
                            </vue-recaptcha>

                            <div class="form-group text-right mt-3">
                                <button type="submit" class="btn btn-success text-white" :disabled="disabled">Versturen</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ImageMixin from "../mixins/asset";
import {VueRecaptcha} from "vue-recaptcha";

export default {
    name: "Contacts",

    mixins: [ImageMixin],

    components: {VueRecaptcha},

    data() {
        return {
            first_name: '',
            surname: '',
            email: '',
            subject: '',
            message: '',
            errors: [],
            isSubmitting: false,
            myForm: null,
            key: '',
            disabled: false,
        }
    },

    created() {
        this.key = process.env.MIX_RECAPTCHA_SITE_KEY;
    },

    methods: {
        submit() {
            this.$refs.recaptcha.execute()
        },

        verify(response) {
            this.disabled = true;

            axios({
                method: 'POST',
                url: '/api/contact-form/submit',
                data: {
                    'g-recaptcha-response': response,
                    'first_name': this.first_name,
                    'surname': this.surname,
                    'email': this.email,
                    'subject': this.subject,
                    'message': this.message,

                }
            }).then((res) => {
                this.errors = []

                if (res.status == 200) {
                    // all good
                    this.first_name = '';
                    this.surname = '';
                    this.email = '';
                    this.subject = '';
                    this.message = '';
                    this.disabled = false;

                    sessionStorage.setItem("alert_type", "success");
                    sessionStorage.setItem("message", 'Bedankt voor je feedback! Het zal worden beoordeeld en kan in een van onze toekomstige releases terechtkomen.');

                    this.$root.$emit("show-flash-message");

                    this.resetCaptcha()
                }
            }).catch((err) => {
                this.isSubmitting = false

                this.errors = err.response.data.errors
            })
        },

        resetCaptcha() {
            this.$refs.recaptcha.reset()
        }
    }
}
</script>
