<template>
    <div id="background">
        <GmapMap ref="map" id="googlemaps" :center="$root.coordinates" :zoom="$root.zoom" class="position-absolute"
                 @dragend="getCouncilOnMove()" @zoom_changed="getCouncilOnMove()" :options="options"
                 v-if="$route.matched.some(({ name }) => name === 'home') || $route.matched.some(({ name }) => name === 'reports.new')">
            <gmap-cluster :gridSize="30" :zoomOnClick="true" :enableRetinaIcons="true"
                          :minimumClusterSize="3" ref="cluster" v-if="$route.matched.some(({ name }) => name === 'home')">
                <GmapMarker :key="index" v-for="(m, index) in markers" :position="m.position" :icon="m.icon"
                            :clickable="m.clickable" @click="toggleInfoWindow(m, index)"/>
            </gmap-cluster>
            <gmap-info-window class="bg-success rounded border border-primary p-0"
                  v-if="$route.matched.some(({ name }) => name === 'home')"
                  :options="infoOptions" :position="infoWindowPos"
                  :opened="infoWinOpen" @closeclick="infoWinOpen = false">
                <div class="header d-flex justify-content-between align-items-center p-3">
                    <h2 class="text-white mb-0">Object informatie</h2>
                    <button class="fw-bold text-white btn"
                            style="font-size: 25px;"
                            @click="infoWinOpen = false;">X</button>
                </div>

                <div class="bg-white p-3 info-window-body">
                    <div v-if="marker.position">
                        <div v-if="marker.photo" class="rounded mb-2">
                            <router-link :to="'/melding/' + marker.id" class="text-dark text-decoration-none">
                                <img :src="marker.photo" :alt="marker.title" class="w-100 marker-image">
                            </router-link>
                        </div>
                        <div v-else class="rounded mb-2">
                            <router-link :to="'/melding/' + marker.id" class="text-dark text-decoration-none">
                                <img :src="'https://maps.googleapis.com/maps/api/staticmap?center=' +
                             marker.position.lat() + ',' + marker.position.lng() + '&markers=' + marker.position.lat() + ',' + marker.position.lng() +
                             '&zoom=15&size=600x400&maptype=satellite&key=' + $root.mapsKey" :alt="marker.title" class="w-100">
                            </router-link>
                        </div>
                    </div>
                    <div class="info-window-description mb-1">
                        <h5 class="h5">
                            <router-link :to="'/melding/' + marker.id"
                                         class="text-dark text-decoration-none">
                                <span>Naam:</span>
                                <span v-text="marker.title"></span>
                            </router-link>
                        </h5>
                        <p v-if="marker.category">
                            <span>Categorie:</span>
                            <span v-text="marker.category"></span>
                        </p>
                        <p :class="marker.city ? '' : 'mb-0'" v-if="marker.status">
                            <span>Status:</span>
                            <span v-text="marker.status"></span>
                        </p>
                        <p class="mb-0" v-if="marker.city">
                            <span>Plaats:</span>
                            <span v-text="marker.city"></span>
                        </p>
                    </div>
                </div>
            </gmap-info-window>
        </GmapMap>

        <div class="container position-absolute mx-auto px-4" id="footerMenu" v-if="$route.matched.some(({ name }) => name === 'home')">
            <button @click="toggleFooter()" id="moreInfo"
                    class="bg-white text-success fw-bold position-absolute border-0 py-2 px-3 fw-bold rounded">
                Meer info <i class="fas fa-arrow-alt-circle-right"></i>
            </button>

            <footer class="hide" id="footer">
                <div class="row justify-content-center">
                    <div class="col-md-6 col-12 me-auto">
                        <ul class="list-unstyled d-flex justify-content-between align-items-center rounded border py-2 px-3 bg-white">
                            <li>
                                <router-link to="/apps" class="text-decoration-none text-success fw-bold">Apps</router-link>
                            </li>

                            <li>
                                <router-link to="/contacten" class="text-decoration-none text-success fw-bold">Contacten</router-link>
                            </li>

                            <li>
                                <router-link to="/privacybeleid" class="text-decoration-none text-success fw-bold">Privacybeleid</router-link>
                            </li>

                            <li>
                                <a target="_blank" href="https://www.facebook.com/verbeterdebuurt/"
                                   class="text-decoration-none text-success fw-bold">
                                    <img :src="asset('images/facebook.png')" alt="facebook">
                                </a>
                            </li>

                            <li>
                                <a target="_blank" href="https://twitter.com/verbeterdebuurt"
                                   class="text-decoration-none text-success fw-bold">
                                    <img :src="asset('images/twitter.png')" alt="twitter">
                                </a>
                            </li>

                            <li>
                                <a href="#" @click="toggleFooter()"
                                   class="text-decoration-none text-success fw-bold">
                                    <i class="fas fa-arrow-alt-circle-left"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
import ImageMixin from "../mixins/asset";
import GmapCluster from "vue2-google-maps/dist/components/cluster";
import {eventBus} from "../app";

export default {
    name: "MapTemplate",

    mixins: [ImageMixin],

    components: {
        GmapCluster,
    },

    data() {
        return {
            markers: [],
            marker: {},
            infoContent: "",
            infoWindowPos: {
                lat: 0,
                lng: 0,
            },
            infoWinOpen: false,
            currentMapsIndex: null,
            infoOptions: {
                pixelOffset: {
                    width: 0,
                    height: -35,
                },
            },
            options: {
                styles: [
                    {
                        featureType: "poi",
                        elementType: "labels",
                        stylers: [
                            {visibility: "off"}
                        ]
                    }
                ],
                minZoom: 9,
                streetViewControl: false,
                fullscreenControl: false,
            },
            mapLoaded: false,
            controller: null,
            tempMarker: null
        };
    },

    mounted() {
        this.tempMarker = JSON.parse(window.localStorage.getItem('tempMarker'))

        if (this.tempMarker) {
            this.getCouncil(this.tempMarker.lat, this.tempMarker.lng);
        } else {
            this.getCouncil(this.$root.coordinates.lat, this.$root.coordinates.lng);
        }
    },

    watch: {
        '$root.coordinates'() {
            this.getCouncil(this.$root.coordinates.lat, this.$root.coordinates.lng);
        },
        'this.$root.mapLoaded'(newValue) {
            this.mapLoaded = newValue;

            this.getCouncil(this.$root.coordinates.lat, this.$root.coordinates.lng);

            if (this.$root.isLocationAllowed) {
                let currentLocation = new this.$root.google.maps.Marker({
                    position: this.$root.myLocation,
                    draggable: false,
                    clickable: false,
                    icon: this.asset('images/location-icon.png'),
                    title: 'Mijn locatie',
                    optimized: true,
                })

                this.markers.push(currentLocation);
            }
        },
        'this.$root.isLocationAllowed'() {
            let currentLocation = new this.$root.google.maps.Marker({
                position: this.$root.myLocation,
                draggable: false,
                clickable: false,
                icon: this.asset('images/location-icon.png'),
                title: 'Mijn locatie',
                optimized: true,
            })

            this.markers.push(currentLocation);
        },
    },

    methods: {
        toggleInfoWindow(marker, index, from_single = false) {
            this.infoWindowPos = marker.position;
            this.marker = marker;
            this.$root.zoom = 17;

            if (from_single) {
                this.currentMapsIndex = null;
                this.infoWinOpen = false;
            }

            setTimeout(() => {
                this.$root.coordinates = {
                    lat: marker.position.lat(),
                    lng: marker.position.lng(),
                };
            }, 100)

            if (this.currentMapsIndex == index) {
                // Check if it's the same marker that was selected if yes toggle
                this.infoWinOpen = !this.infoWinOpen;
            } else {
                // If different marker set info window to open and reset current marker index
                this.infoWinOpen = true;
                this.currentMapsIndex = index;
            }
        },

        getCouncil(lat, lng) {
            if (this.controller) this.controller.abort();

            this.controller = new AbortController();
            this.markers = [];
            let marker = {};

            axios.get("/api/reports?latitude=" + lat + "&longitude=" + lng + '&skip_old_fixed=true', {
                signal: this.controller.signal
            }).then((res) => {
                let data = res.data.data;

                for (let i = 0; i < data.reports.length; i++) {
                    let obj = data.reports[i];

                    //Initializing the Marker object.
                    marker = new this.$root.google.maps.Marker({
                        position: {
                            lat: obj.latitude,
                            lng: obj.longitude,
                        },
                        photo: obj.urls[0],
                        icon: obj.is_fixed ? (obj.type === 'problem' ? this.asset('images/problem_fixed.png') : this.asset('images/idea_fixed.png')) :
                            (obj.type === 'problem' ? this.asset('images/icon_problem_normal.png') : this.asset('images/idea_not_fixed.png')),
                        title: obj.title,
                        id: obj.id,
                        status: obj.status_formatted,
                        category: obj.category ? obj.category.name : '',
                        city: obj.city ? obj.city.name : null,
                        optimized: true,
                    });

                    this.markers.push(marker);
                }

                if (this.tempMarker) {
                    this.$root.coordinates.lat = this.tempMarker.lat;
                    this.$root.coordinates.lng = this.tempMarker.lng;
                    this.$root.zoom = 18;

                    let marker = this.markers.filter(el => {
                        return el.id == this.tempMarker.id;
                    })

                    let index = this.markers.findIndex((el) => el.id == this.tempMarker.id);

                    if (marker.length > 0) {
                        this.toggleInfoWindow(marker[0], index, true)
                    }

                    window.localStorage.removeItem('tempMarker')

                    this.tempMarker = null;
                }

                eventBus.$emit('mapChangePosition', data, lat, lng)
            });
        },

        getCouncilOnMove() {
            this.$root.coordinates = {
                lat: this.$refs.map.$mapObject.center.lat(),
                lng: this.$refs.map.$mapObject.center.lng(),
            };

            this.$root.zoom = this.$refs.map.$mapObject.zoom;
        },

        toggleFooter() {
            let footer = document.getElementById("footer");
            let footerButton = document.getElementById("moreInfo");

            footer.classList.toggle("hide");
            footerButton.classList.toggle("d-none");
        }
    },
};
</script>

<style>
.gm-style .gm-style-iw-c {
    background: #fff;
    padding: 0;
    opacity: .95;
}

.gm-style .gm-style-iw-c .header {
    background: #3ab53a;
}

.gm-style .gm-style-iw-c .gm-style-iw-d {
    padding: 0;
    overflow: hidden !important;
}

.info-window-body {
    height: 100%;
    max-height: 390px;
    overflow: hidden;
    width: 375px;
}

.info-window-description {
    border-radius: 8px 8px 0 0;
}

button.gm-ui-hover-effect {
    top: 20px !important;
    right: 10px !important;
}

button.gm-ui-hover-effect > span {
    background: #fff !important;
}

.gm-style-iw-chr {
    display: none !important;
}
</style>
