<script>
import AutocompleteMixin from "../../mixins/autocomplete";

export default {
    name: "category-update",

    mixins: [
        AutocompleteMixin
    ],

    props: [
        'category',
        'loggedUserCouncils'
    ],

    data() {
        return {
            errors: [],
            categoryUpdate: {},
            categories: [],
            councils: [],
            canUpdate: false,
        }
    },

    mounted() {
        this.categoryUpdate = JSON.parse(this.category);

        this.categoryUpdate.selected_councils = this.categoryUpdate.councils.map(el => {
            return el.id;
        })

        this.categoryUpdate.is_attached = JSON.parse(this.loggedUserCouncils).some(r=> this.categoryUpdate.selected_councils.includes(r))
        this.canUpdate = this.categoryUpdate.is_attached ||
            (!this.categoryUpdate.is_attached && !this.categoryUpdate.parent_id) ||
            this.$root.user.roles.find(el => el.name === 'super admin');

        if (!this.canUpdate) this.$refs.canUpdate.classList.remove('d-none');

        if (this.canUpdate) this.$refs.canUpdateSubmit.classList.remove('d-none');

        if (this.categoryUpdate.is_attached || !this.categoryUpdate.parent_id) {
            if (this.$refs.canUpdateCheckbox) this.$refs.canUpdateCheckbox.classList.remove('d-none');
        }

        this.getCategories();
        this.getCouncils();
    },

    methods: {
        update() {
            this.errors = [];

            axios.patch('/admin/categories/' + this.categoryUpdate.id, {
                name: this.categoryUpdate.name,
                parent_id: this.categoryUpdate.parent_id,
                councils: this.categoryUpdate.selected_councils,
                is_attached: this.categoryUpdate.is_attached,
            }).then(res => {
                if (res.status == 200) {
                    sessionStorage.setItem("message", "Category has been successfully updated");
                    sessionStorage.setItem("alert_type", "success");
                    window.location.reload();
                }
            }).catch((error) => {
                this.errors = error.response.data.errors
            })
        },

        getCategories() {
            axios.get('/api/report-categories').then(res => {
                this.categories = res.data.data.filter(el => el.parent_id == null);
            })
        },

        getCouncils() {
            axios.get('/admin/categories/councils').then(res => {
                this.councils = res.data.data;
            })
        },
    }
}
</script>
