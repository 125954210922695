<template>
    <div>
        <select
            class="border-0 px-3 py-2"
            name="language"
            v-model="$language.current"
        >
            <option v-for="(language, key) in $language.available" :value="key">
                {{ language }}
            </option>
        </select>
    </div>
</template>
<script>
export default {
    name: "translate-button",
};
</script>
<style scoped>
</style>
