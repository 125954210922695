<template>
    <vueper-slides>
        <vueper-slide
            v-for="(slide, i) in reportPhotos" :key="i" :image="slide">
        </vueper-slide>

        <template #bullet="{ active, slideIndex, index }">
            <button type="button"></button>
        </template>
    </vueper-slides>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
    props: ['photos'],

    name: "carousel-gallery",

    data() {
        return {
            reportPhotos: {},
        }
    },

    components: {
        VueperSlides,
        VueperSlide
    },

    mounted() {
        this.reportPhotos = JSON.parse(this.photos)
    }
}
</script>

<style>
.vueperslides__parallax-wrapper {
    height: 400px;
}

.vueperslides__bullet, .vueperslides__bullets button {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    transition: opacity .6s ease;
    opacity: .5;
}

.vueperslides__bullet.vueperslides__bullet--active,
.vueperslides__bullet.vueperslides__bullet--active button {
    opacity: 1;
}
</style>
