<template>
    <div class="row mx-auto">
        <div class="col-md-10 col-12 mx-auto">
            <h1>{{ $gettext('Mail Templates') }}</h1>

            <div id="table-wrapper">
                <data-table
                    ref="mailTemplatesTable"
                    order-by="id"
                    order-dir="asc"
                    :per-page="['10', '25', '50', '100']"
                    :columns="columns"
                    :translate="translate"
                    :debounce-delay="500"
                    :data="mailTemplates"
                    @on-table-props-changed="reloadTable">
                    <div slot="filters" slot-scope="{ tableData, perPage }">
                        <div class="row mb-2">
                            <div class="col-md-4">
                                <select class="form-control w-auto me-md-2 me-0 form-input-height" v-model="tableProps.length"
                                        @change="getTemplates(); $refs.mailTemplatesTable.page = 1">
                                    <option :key="page" v-for="page in perPage">{{ page }}</option>
                                </select>
                            </div>
                            <div class="col-md-8 d-flex flex-column flex-md-row justify-content-end">
                                <input name="search" id="search" class="form-control w-auto form-input-height"
                                       v-model="tableProps.search" @input="debounceInput"
                                       :placeholder="$gettext('Search templates...')">
                            </div>
                        </div>
                    </div>
                    <tbody slot="body" slot-scope="{ data }">
                    <tr :key="item.id" v-for="item in data">
                        <td :key="column.name" v-for="column in columns"
                            @click="column.name !=='Edit' ? showRowNumber(item.id, item.type) : ''">
                            <data-table-cell
                                :value="item"
                                :name="column.name"
                                :meta="column.meta"
                                :comp="column.component"
                                :classes="column.classes"
                            >
                            </data-table-cell>
                        </td>
                    </tr>
                    </tbody>
                </data-table>
            </div>

            <modal
                :modaltitle="$gettext('Delete Template')"
                :modaltext="$gettext('Do you want to delete this Mail Template?')"
                :buttontext="$gettext('Delete')"
            ></modal>
        </div>
    </div>
</template>

<script>
import ActionTableButtons from "./ActionTableButtons.vue";
import { DataTable } from "laravel-vue-datatable";
import {debounce} from "lodash";

export default {
    name: "mail-templates-table",

    props: ["base_url"],

    components: {
        ActionTableButtons,
        DataTable,
    },

    data() {
        return {
            url: "",
            showActionButtons: false,
            rowId: "",
            mailTemplate: {},
            mailTemplates: {},
            tableProps: {
                length: 10,
                search: '',
                column: 'id',
                dir: 'asc',
            },
            columns: [
                {
                    label: "Id",
                    name: "id",
                    orderable: true,
                },
                {
                    label: this.$gettext("Description"),
                    name: "description",
                    orderable: true,
                },
                {
                    label: this.$gettext("Type"),
                    name: "mail_type_formatted",
                    columnName: "mail_type",
                    orderable: true,
                },
                {
                    label: this.$gettext("Council"),
                    name: "council_name",
                    orderable: false,
                },
                {
                    label: this.$gettext("Recipient role"),
                    name: "recipient_role",
                    orderable: false,
                },
                {
                    label: this.$gettext("Report type"),
                    name: "report_type",
                    orderable: true,
                },
                {
                    label: this.$gettext("Active"),
                    name: "active_formatted",
                    columnName: "is_active",
                    orderable: true,
                },
                {
                    label: this.$gettext("Actions"),
                    name: "Edit",
                    orderable: false,
                    classes: {
                        btn: true,
                        "btn-primary": true,
                        "btn-sm": true,
                    },
                    component: ActionTableButtons,
                    meta: {
                        url: this.base_url + "/admin/mail-templates",
                        showActionButtons: this.showActionButtons
                    },
                },
            ],
            translate: {
                nextButton: this.$gettext('Next'),
                previousButton: this.$gettext('Previous'),
                placeholderSearch: this.$gettext('Search templates...'),
            },
        };
    },

    beforeCreate() {
        this.showActionButtons = (this.$root.user.roles.find(el => el.name === 'super admin')) ? true : false;
    },

    mounted() {
        this.url = this.base_url + "/admin/mail-templates-datatable";

        this.getTemplates();

        let self = this;

        this.$root.$on("get-datatable-row-id", function (id) {
            this.rowId = id;
        });

        this.$root.$on("modal-confirmed", function () {
            self.deleteRow(this.rowId);
        });
    },

    methods: {
        getTemplates(url = this.url, options = this.tableProps) {
            axios.get(url, {
                params: {
                    search: options.search,
                    length: options.length,
                    page: options.page,
                    column: options.column,
                    dir: options.dir,
                }
            }).then(response => {
                this.mailTemplates = response.data;
            })
        },

        showRowNumber(id) {
            window.location = "/admin/mail-templates/" + id;
        },

        debounceInput: debounce(function (e) {
            this.$refs.mailTemplatesTable.page = 1;

            this.getTemplates();
        }, 1000),

        deleteRow(id) {
            let url = "/admin/mail-templates/delete/" + id;

            axios.post(url).then((res) => {
                if (res.status === 200) {
                    sessionStorage.setItem("alert_type", "success");
                    sessionStorage.setItem("message", this.$gettext("Mail Template deleted successfully"));

                    this.$root.$emit("show-flash-message");

                    this.getTemplates(this.url);
                }
            }).catch(errors => {
                sessionStorage.setItem("alert_type", "danger");
                sessionStorage.setItem("message", this.$gettext("Something went wrong"));

                this.$root.$emit("show-flash-message");
            });
        },

        reloadTable(tableProps) {
            tableProps['length'] = this.tableProps.length;
            tableProps['search'] = this.tableProps.search;

            this.getTemplates(this.url, tableProps);
        },
    },
};
</script>
