<template>
    <div class="d-flex" v-if="(meta['showActionButtons'] == true)">
        <a :href="meta['url'] + '/' + data.id">
            <button class="btn text-success border-success btn-edit shadow-sm mr-2 p-0">
                <i class="fa fa-edit"></i>
            </button>
        </a>

        <button class="btn btn-login shadow-sm p-0" v-if="meta['loginAs'] &&
        $root.user.roles.find(el => el.name === 'super admin')" @click.prevent="impersonateUser(data.id)">
            <i class="fas fa-sign-in-alt"></i>
        </button>

        <button class="btn btn-delete shadow-sm p-0" v-if="!meta['hideDelete'] == true"
                @click.prevent="getRowId(data)" data-bs-toggle="modal" data-bs-target="#itemModal">
            <i class="fa fa-trash"></i>
        </button>
    </div>
    <div v-else>
        -
    </div>
</template>

<script>
export default {
    name: "action-buttons",

    props: {
        data: {
            type: Object,
            required: true,
        },
        meta: {
            type: Object,
            required: true,
        },
    },

    methods: {
        getRowId(data) {
            this.$root.$emit('get-datatable-row-id', data.id);
        },

        impersonateUser(id) {
            const self = this;

            axios.post(this.meta['impersonateEndpoint'], {
                'id': id
            }).then((res) => {
                if (res.status == 200) {
                    if(res.data.find(name => name === 'citizen')) {
                        window.location = '/';
                    } else {
                        window.location = '/admin';
                    }
                }
            }).catch(function(response){
                self.$root.$emit('modal-message-ajax', {
                    title: self.$gettext('Error'),
                    body: self.$gettext(response.response.data.message),
                });
            });
        },
    },
};
</script>

<style>
.btn-login,
.btn-edit,
.btn-delete {
    width: 40px;
    height: 40px;
}

.btn-login:hover {
    background-color: #003a78;
}

.btn-edit:hover {
    background-color: #38c172;
}

.btn-delete:hover {
    background-color: #e42313;
}

.btn-login:hover i,
.btn-delete:hover i,
.btn-edit:hover i {
    color: #fff;
}
</style>
