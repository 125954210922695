<template>
    <div class="container">
        <div class="row">
            <div class="col-6 mx-auto px-0">
                <div class="card my-3 p-5 shadow-sm opacity">
                    <div class="card-header bg-white p-0 border-0 d-flex justify-content-between">
                        <h1 class="title fw-bold" v-text="report.title"></h1>

                        <router-link :to="'/mijn-meldingen/' + report.id" class="text-secondary text-decoration-none back-button">
                            <i class="fas fa-arrow-alt-circle-left"></i>
                        </router-link>
                    </div>

                    <div class="card-body p-0">
                        <div v-if="!report.is_fixed">
                            <form method="POST">
                                <div>
                                    <label class="rating">Waardering:</label>
                                    <AwesomeVueStarRating
                                        ref="reportRating"
                                        :star="rating"
                                        :maxstars="maxstars"
                                        :starsize="starsize"
                                        :hasresults="hasresults"
                                        :hasdescription="hasdescription"
                                        :ratingdescription="ratingdescription"/>
                                </div>

                                <div class="form-group mb-3">
                                    <label for="feedback" class="col-form-label">Feedback:</label>
                                    <textarea class="form-control" name="feedback" id="" rows="5" cols="30" v-model="feedback"></textarea>
                                </div>

                                <div class="form-group text-right">
                                    <button type="button" class="btn btn-success text-white" @click="closeReport()">
                                        Afsluiten
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div class="text-center" v-else>
                            <img class="mb-4" :src="asset('images/approved.png')" alt="Goedgekeurd">

                            <h2 class="title">Dit probleem is al gesloten!</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ImageMixin from "../mixins/asset";
import AwesomeVueStarRating from "awesome-vue-star-rating";

export default {
    name: "rate-report",

    mixins: [ImageMixin],

    components: {
        AwesomeVueStarRating,
    },

    mounted() {
        if (this.$root.authUser) {
            this.getReport(this.$route.params.id);
        } else {
            this.$router.push({name: 'login'})
        }
    },

    data() {
        return {
            report: {},
            rating: 5, // default star
            ratingdescription: [
                {
                    text: "Slecht",
                    class: "star-poor",
                },
                {
                    text: "Onder het gemiddelde",
                    class: "star-belowAverage",
                },
                {
                    text: "Gemiddeld",
                    class: "star-average",
                },
                {
                    text: "Mooi zo",
                    class: "star-good",
                },
                {
                    text: "Uitstekend",
                    class: "star-excellent",
                },
            ],
            hasresults: false,
            hasdescription: false,
            starsize: "lg", //[xs,lg,1x,2x,3x,4x,5x,6x,7x,8x,9x,10x],
            maxstars: 5,
            feedback: "",
        };
    },

    methods: {
        getReport(id) {
            axios.get("/api/reports/" + id, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                }
            }).then((response) => {
                this.report = response.data.data;
            }).catch();
        },

        passData() {
            this.$refs.reportRating.$children[0].$data.stars = 5;
            this.rating = 5;
            this.feedback = "";
        },

        closeReport() {
            axios.patch("/api/reports/" + this.report.id, {
                is_fixed: 1,
                rating: this.$refs.reportRating.$children[0].$data.stars,
                feedback: this.feedback,
            }).then(() => {

                this.$router.push('/mijn-meldingen/' + this.report.id)
            });
        },
    },
};
</script>

<style scoped>
.opacity {
    opacity: 0.96;
    padding: 20px;
}
</style>
