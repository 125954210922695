<template>
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-10 mx-auto px-0">
                <div class="card my-3 p-4 shadow-sm opacity">
                    <div class="card-header bg-white p-0 border-0 mb-2 d-flex justify-content-between">
                        <h1 class="title fw-bold">Accountbevestiging</h1>

                        <router-link to="/" class="text-dark text-decoration-none close-button">×</router-link>
                    </div>
                    <div class="card-body p-0">
                        <div v-if="errors.length == 0" class="text-center">
                            <img class="mb-4" :src="asset('images/approved.png')" alt="Goedgekeurd">

                            <h4 class="title" v-text="message"></h4>
                        </div>

                        <div v-else>
                            <p class="alert-danger bg-transparent my-1 text-danger" role="alert"
                               v-if="errors.id" v-for="(name, index) in errors.id">
                                <strong v-text="errors.id[index]"></strong>
                            </p>
                            <p class="alert-danger bg-transparent my-1 text-danger" role="alert"
                               v-if="errors.signature" v-for="(name, index) in errors.signature">
                                <strong class="text-capitalize" v-text="errors.signature[index]"></strong>
                            </p>
                            <p class="alert-danger bg-transparent my-1 text-danger" role="alert"
                               v-if="errors.verified" v-for="(name, index) in errors.verified">
                                <strong class="text-capitalize" v-text="errors.verified[index]"></strong>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ImageMixin from "../mixins/asset";

export default {
    name: "VerifyRegistration",

    mixins: [ImageMixin],

    data() {
        return {
            message: '',
            errors: []
        }
    },

    mounted() {
        axios.post('/api/users/verify-email/' + this.$route.params.id + '/' + this.$route.params.hash +
            '?expires=' + this.$route.query.expires + '&signature=' + this.$route.query.signature).then(res => {
            this.message = res.data.data.verified[0];
            this.$root.user.is_verified = true;

            window.setTimeout(() => {
                if (this.$root.authUser) {
                    this.$router.push({name: 'settings'});
                } else {
                    this.$router.push({name: 'login'});
                }
            }, 3000)
        }).catch((error) => {
            this.isConfirmed = false;
            this.errors = error.response.data.errors

            window.setTimeout(() => {
                if (this.$root.authUser) {
                    this.$router.push({name: 'settings'});
                } else {
                    this.$router.push({name: 'login'});
                }
            }, 3000)
        });
    },
}
</script>

<style scoped>

</style>
