<script>
    import AutocompleteMixin from "../mixins/autocomplete";

    export default {
        name: "sub-category-update",

        mixins: [
            AutocompleteMixin
        ],

        props: ['category'],

        data() {
            return {
                errors: [],
                categoryUpdate: {},
                categories: [],
            }
        },

        mounted() {
            this.categoryUpdate = JSON.parse(this.category)

            this.getCategories();
        },

        methods: {
            update() {
                axios.patch('/admin/categories/sub/' + this.categoryUpdate.id, {
                    name: this.categoryUpdate.name,
                    category_id: this.categoryUpdate.category_id,
                    user_id: this.categoryUpdate.user_id,
                }).then(res => {

                }).catch(error => {
                    this.errors = error.response.data.errors;
                })
            },

            getCategories() {
                axios.get('/api/report-categories').then(res => {
                    this.categories = res.data.data;
                })
            },
        }
    }
</script>
