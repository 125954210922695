<template>
    <div class="alert alert-dismissible fade show" :class="compClasses" v-if="showFlashMessage" role="alert">
        <div>
            {{ message }}
        </div>
        <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="showFlashMessage = false;">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</template>

<script>
export default {
    name: "flash-message",

    props: [
        'alert_type',
        'session_message'
    ],

    data() {
        return {
            showFlashMessage: false,
            selected_status: "",
            message: "",
            classes: {
                "alert": true,
                "alert-success": false,
                "alert-primary": false,
                "alert-danger": false,
                "alert-warning": false
            }
        }
    },

    mounted() {
        let self = this;

        this.$root.$on('show-flash-message', function () {
            self.messageInit();
            self.openFlashMessage();
        });

        if (this.messageInit()) {
            self.openFlashMessage();
        }
    },

    computed: {
        compClasses: function () {
            return {
                "alert-success": this.classes['alert-success'],
                "alert-primary": this.classes['alert-primary'],
                "alert-danger": this.classes['alert-danger'],
                "alert-warning": this.classes['alert-warning'],
                "alert": this.classes.alert,
                "mb-0": true,
            }
        }
    },

    methods: {
        openFlashMessage() {
            this.showFlashMessage = true;

            this.alertClassInit();

            setTimeout(() => {
                this.closeFlashMessage();
            }, 2500);
        },

        messageInit() {
            if (this.session_message) {
                this.message = this.session_message;

                return true;
            }

            if (sessionStorage.getItem('message')) {
                this.message = sessionStorage.getItem('message');

                return true;
            }

            return false;
        },

        closeFlashMessage() {
            sessionStorage.clear();

            this.message = null;
            this.showFlashMessage = false;
        },

        alertClassInit() {
            let alert_type = (!this.alert_type || this.alert_type == '') ? sessionStorage.getItem('alert_type') : this.alert_type;

            switch (alert_type) {
                case 'success':
                    this.classes['alert-success'] = true;
                    break;
                case 'primary':
                    this.classes['alert-primary'] = true;
                    break;
                case 'danger':
                    this.classes['alert-danger'] = true;
                    break;
                case 'warning':
                    this.classes['alert-warning'] = true;
                    break;
            }
        }
    },
};
</script>

<style scoped>
.alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}
.alert-dismissible {
    padding-right: 4rem;
}
.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}
button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
}
.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit;
}
.alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
}
.alert-primary {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
}
.alert-success {
    color: #3ab53a;
    background-color: #d4edda;
    border-color: #c3e6cb;
}
.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}
.close:not(:disabled):not(.disabled) {
    cursor: pointer;
}
</style>
