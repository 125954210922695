<template>
    <div class="d-flex" v-if="data.rating">
        <i
            class="fa fa-star text-success"
            v-for="n in parseInt(data.rating)"
            :key="n"
        ></i>
    </div>
</template>

<script>
export default {
    name: "action-stars",

    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {};
    },
    mounted() {},
};
</script>

<style>
.btn-view,
.btn-edit,
.btn-delete {
    width: 40px;
    height: 40px;
}

.btn-view:hover {
    background-color: #003a78;
}

.btn-edit:hover {
    background-color: #38c172;
}

.btn-delete:hover {
    background-color: #e42313;
}

.btn-view:hover i,
.btn-delete:hover i,
.btn-edit:hover i {
    color: #fff;
}
</style>
