<template>
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-10 mx-auto px-2">
                <div class="card my-3 p-4 shadow-sm opacity">
                    <div class="card-header bg-white p-0 border-0 mb-2 d-flex justify-content-between">
                        <h1 class="title fw-bold">Apps</h1>

                        <router-link to="/" class="text-dark text-decoration-none close-button">×</router-link>
                    </div>

                    <div class="card-body p-0">
                        <div class="row">
                            <div class="col-md-6 text-center mb-3 mb-md-0">
                                <a href="https://play.google.com/store/apps/details?id=nl.verbeterdebuurt" target="_blank">
                                    <img :src="asset('images/GP-button.png')" alt="Google play">
                                </a>
                            </div>
                            <div class="col-md-6 text-center">
                                <a href="https://apps.apple.com/us/app/verbeterdebuurt/id398177951" target="_blank">
                                    <img :src="asset('images/Ios-button.png')" alt="iOS">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ImageMixin from "../mixins/asset";

export default {
    name: "Apps",

    mixins: [ImageMixin],
}
</script>

<style scoped>
    @media only screen and (min-width: 768px) and (max-width: 1199px) {
        img {
            width: 80%;
        }
    }
</style>
