<template>
    <div class="row mx-auto">
        <div class="col-md-8 col-12 mx-auto">
            <h1>{{ $gettext('Mail logs') }}</h1>

            <div id="table-wrapper">
                <data-table
                    ref="mailLogsTable"
                    :columns="columns"
                    :per-page="['10', '25', '50', '100']"
                    order-by="id"
                    order-dir="desc"
                    :debounce-delay="500"
                    :translate="translate"
                    :data="logs"
                    @on-table-props-changed="reloadTable">
                    <div slot="filters" slot-scope="{ tableData, perPage }">
                        <div class="row mb-2">
                            <div class="col-md-4">
                                <select class="form-control w-auto me-md-2 me-0 form-input-height" v-model="tableProps.length"
                                        @change="getLogs(); $refs.mailLogsTable.page = 1">
                                    <option :key="page" v-for="page in ['10', '25', '50', '100']">{{ page }}</option>
                                </select>
                            </div>
                            <div class="col-md-8 d-flex flex-column flex-md-row justify-content-end">
                                <input name="search" id="search" :placeholder="$gettext('Search logs...')"
                                       class="form-control w-auto form-input-height"
                                       v-model="tableProps.search" @input="debounceInput">
                            </div>
                        </div>
                    </div>
                    <tbody slot="body" slot-scope="{ data }">
                    <tr :key="item.id" v-for="item in data">
                        <td :key="column.name" v-for="column in columns">
                            <data-table-cell
                                :value="item"
                                :name="column.name"
                                :meta="column.meta"
                                :comp="column.component"
                                :classes="column.classes"
                            >
                            </data-table-cell>
                        </td>
                    </tr>
                    </tbody>
                </data-table>
            </div>
        </div>
    </div>
</template>

<script>
import { DataTable } from "laravel-vue-datatable";
import {debounce} from "lodash";

export default {
    name: "mail-logs-table",

    props: ["base_url"],

    components: {
        DataTable,
    },

    data() {
        return {
            logs: {},
            tableProps: {
                search: '',
                length: 10,
                column: 'id',
                dir: 'desc',
            },
            columns: [
                {
                    label: "ID",
                    name: "id",
                    orderable: true,
                },
                {
                    label: this.$gettext("Report ID"),
                    name: "report_id",
                    orderable: true,
                },
                {
                    label: this.$gettext("Type"),
                    name: "template.mail_type_formatted",
                    orderable: false,
                },
                {
                    label: this.$gettext("Report"),
                    name: "report.title",
                    orderable: false,
                },
                {
                    label: this.$gettext("Recipient"),
                    name: "recipient_mail",
                    orderable: true,
                },
                {
                    label: this.$gettext("Date"),
                    name: "create_date_formatted",
                    columnName: "created_at",
                    orderable: true,
                },
            ],
            translate: {
                nextButton: this.$gettext('Next'),
                previousButton: this.$gettext('Previous'),
                placeholderSearch: this.type === 'idea' ? this.$gettext('Search ideas...') : this.$gettext('Search problems...'),
            },
        };
    },

    mounted() {
        this.getLogs();
    },

    methods: {
        getLogs(url = this.base_url + "/admin/mail-logs-datatable", options = this.tableProps) {
            axios.get(url, {
                params: {
                    search: options.search,
                    length: options.length,
                    page: options.page,
                    column: options.column,
                    dir: options.dir,
                }
            }).then(response => {
                this.logs = response.data;
            })
        },

        debounceInput: debounce(function (e) {
            this.$refs.mailLogsTable.page = 1

            this.getLogs()
        }, 500),

        reloadTable(tableProps) {
            tableProps['length'] = this.tableProps.length;
            tableProps['search'] = this.tableProps.search;

            this.getLogs(this.base_url + "/admin/mail-logs-datatable", tableProps);
        },
    }
};
</script>
