<template>
    <div class="">
        <div class="modal fade" tabindex="-1" id="councilEntryNumberDialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ modaltitle }}</h5>

                        <!--begin::Close-->
                        <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                            <span class="svg-icon svg-icon-2x"></span>
                        </div>
                        <!--end::Close-->
                    </div>

                    <div class="modal-body">
                        <p>{{ modaltext }}</p>
                    </div>

                    <div class="m-4">
                        <label for="entryNumber">Entry Number</label>
                        <input type="text" id="entryNumber" class="form-control" placeholder="Enter a entry number" v-model="entry_number">
                    </div>

                    <div class="modal-footer d-flex justify-content-between">
                        <button type="button" class="btn btn-light" data-bs-dismiss="modal" v-text="$gettext('Close')"></button>
                        <button type="button" class="btn admin-create-button" data-bs-dismiss="modal"
                                @click="accept()" :disabled="entry_number == null">{{ buttontext }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
export default {
    props: ["modaltitle", "modaltext", "buttontext", "report_id"],

    data() {
        return {
            entry_number: null,
        };
    },

    methods: {
        accept() {
            axios.post("/admin/accept-report", {
                entry_number:this.entry_number,
                report_id:this.report_id,
                council_id: 1 // hardcoded. council_id or citiy_id... to be discussed with Tsanko
            }).then((response) => {

                let url = '/admin/reports/update-status/'+this.report_id;

                axios.post(url, {
                    status: 'registered'
                }).then((response) => {

                }).catch((error) => {

                });

                this.$root.$emit('modal-message-ajax', {
                    title: 'Success',
                    body: 'The report was accepted with entry number ' + this.entry_number,
                });

                window.location.reload();
            }).catch((error) => {
                this.$root.$emit('modal-message-ajax', {
                    title: 'Error',
                    body: error.response.data,
                });
            }).finally(() => {
                //Perform action in always
            });
        },
    },
};
</script>
