<template>
    <div class="d-flex"
         v-if="$root.user.roles.find(el => el.name === 'super admin') ||
         ($root.user.roles.find(el => el.name !== 'super admin') && data.parent_id)">
        <a :href="meta[0] + '/' + data.id">
            <button class="btn text-success border-success btn-edit shadow-sm mr-2 p-0">
                <i class="fa fa-edit"></i>
            </button>
        </a>

        <button class="btn btn-delete shadow-sm p-0" @click.prevent="getRowId(data)"
                data-bs-toggle="modal" data-bs-target="#itemModal">
            <i class="fa fa-trash"></i>
        </button>
    </div>
    <div v-else>
        -
    </div>
</template>

<script>
export default {
    name: "action-buttons",

    props: {
        data: {
            type: Object,
            required: true,
        },
        meta: {
            type: Object,
            required: true,
        },
    },

    methods: {
        getRowId(data) {
            this.$root.$emit('get-datatable-row-id', data.id);
        },
    },
};
</script>

<style>
.btn-view,
.btn-edit,
.btn-delete {
    width: 40px;
    height: 40px;
}

.btn-view:hover {
    background-color: #003a78;
}

.btn-edit:hover {
    background-color: #38c172;
}

.btn-delete:hover {
    background-color: #e42313;
}

.btn-view:hover i,
.btn-delete:hover i,
.btn-edit:hover i {
    color: #fff;
}
</style>
