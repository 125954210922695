<template>
    <div class="row mx-0 mb-8">
        <div class="col-lg-6 col-12 mx-auto">
            <div v-if="loader" class="text-center p-3">
                <div class="spinner-grow" style="width: 5rem; height: 5rem;" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>

            <div class="bg-white overflow-hidden shadow-sm sm:rounded-lg rounded" v-show="!loader">
                <div class="mapouter2 position-relative mb-2">
                    <div class="d-flex searchbar position-absolute ms-auto">
                        <GmapAutocomplete placeholder="Vul locatie in..." class="form-control"
                                          :selectFirstOnEnter="true" @place_changed="updateCoordinates($event.geometry.location)"/>
                    </div>
                    <div class="gmap_canvas">
                        <GmapMap ref="map" id="googlemaps" :center="center" :zoom="zoom" :options="options">
                            <GmapMarker :position="marker" :clickable="true" :draggable="true"
                                        :icon="marker.icon" @dragend="updateCoordinates($event.latLng)"/>
                        </GmapMap>
                    </div>
                </div>

                <div class="p-10">
                    <p class="text-dark" v-text="address"></p>

                    <form @submit.prevent="saveData()"
                          v-if="$root.agreement.services && $root.agreement.services.includes('reports')">
                        <p class="mb-0">2. {{ $gettext('Report description') }}</p>

                        <input type="hidden" name="_token" :value="csrf"/>

                        <div class="form-group">
                            <label for="title" class="col-form-label">{{ $gettext('Title') }} *</label>
                            <input type="text" name="title" id="title" v-model="report.title"
                                   class="form-control" required :placeholder="$gettext('Title')"/>

                            <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                  v-if="errors.title" v-for="(name, index) in errors.title">
                                    <strong v-text="errors.title[index]"></strong>
                                </span>
                        </div>

                        <div class="form-group">
                            <label for="category" class="col-form-label">{{ $gettext('Category') }} *</label>
                            <select name="category" id="category" v-model="report.report_category_id"
                                    class="form-control" required>
                                <option value="">{{ $gettext('Choose a category...') }}</option>
                                <option v-for="category in this.categories" :value="category.id">
                                    {{ category.name }}
                                </option>
                            </select>

                            <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                  v-if="errors.report_category_id"
                                  v-for="(name, index) in errors.report_category_id">
                                    <strong v-text="errors.report_category_id[index]"></strong>
                                </span>
                        </div>

                        <div class="form-group mb-2">
                            <label for="description" class="col-form-label">{{ $gettext('Description') }} *</label>
                            <textarea name="description" id="description" v-model="report.description"
                                      class="form-control" rows="5" required
                                      :placeholder="$gettext('Description')"></textarea>

                            <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                  v-if="errors.description" v-for="(name, index) in errors.description">
                                    <strong v-text="errors.description[index]"></strong>
                                </span>
                        </div>

                        <div class="form-group">
                            <a href="#" type="button" class="btn btn-transparent border border-dark w-100"
                               @click.prevent="showPrivate = !showPrivate">{{ $gettext('Add private note') }}</a>
                            <div v-if="showPrivate">
                                <label for="private_note" class="col-form-label">{{ $gettext('Private note') }}</label>
                                <textarea name="private_note" id="private_note" class="form-control"
                                          rows="5" v-model="report.private_note"
                                          :placeholder="$gettext('A private note is only sent to the municipality/practitioner and will not be visible on the site.')"></textarea>

                                <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                      v-if="errors.private_note" v-for="(name, index) in errors.private_note">
                                        <strong v-text="errors.private_note[index]"></strong>
                                    </span>
                            </div>
                        </div>

                        <div class="form-group mb-4">
                            <p class="col-form-label">{{ $gettext('Photos upload (Optional)') }}</p>

                            <input type="file" name="photos" id="photos" multiple
                                   accept=".jpg,.jpeg,.png,.gif" ref="photos"
                                   class="form-control" @change="onFileSelected($event)"/>

                            <sub>{{ $gettext('Max 6 images / Maximum image size 7MB / Allowed formats') }}:
                                .jpg,.jpeg,.png,.gif</sub>

                            <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                  v-if="errors.photos" v-for="(name, index) in errors.photos">
                                    <strong v-text="errors.photos[index]"></strong>
                                </span>

                            <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                  v-if="Object.keys(errorPhotos).length > 0"
                                  v-for="(error, key, index) in errorPhotos">
                                    <strong v-text="errorPhotos[key][0] + ' ' + '(Foto ' + [index + 1] + ')'"></strong>
                                    <br>
                                </span>

                            <div v-if="Object.keys(photos).length > 0" class="mt-2">
                                <div v-if="Object.keys(photos).length == 1" class="image mx-auto"
                                     :style="{backgroundImage: 'url(' + photosPreview[0] + ')'}"></div>

                                <div v-if="Object.keys(photos).length > 1">
                                    <vueper-slides>
                                        <vueper-slide
                                            v-for="(slide, i) in photosPreview"
                                            :key="i"
                                            :image="slide">
                                        </vueper-slide>

                                        <template #bullet="{ active, slideIndex, index }">
                                            <button></button>
                                        </template>
                                    </vueper-slides>
                                </div>
                            </div>
                        </div>

                        <p class="mb-0">3. Contact gegevens</p>

                        <div class="form-group">
                            <label for="full_name" class="col-form-label">{{ $gettext('Full name') }} *</label>
                            <input type="text" name="full_name" id="full_name" v-model="watcher.full_name"
                                   class="form-control" required :placeholder="$gettext('Full name')"/>

                            <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                  v-if="errors.full_name" v-for="(name, index) in errors.full_name">
                                    <strong v-text="errors.full_name[index]"></strong>
                                </span>
                        </div>

                        <div class="form-group">
                            <label for="email" class="col-form-label">{{ $gettext('E-mail') }} *</label>
                            <input type="email" name="email" id="email" v-model="watcher.email"
                                   class="form-control" required :placeholder="$gettext('E-mail')"/>

                            <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                  v-if="errors.email" v-for="(name, index) in errors.email">
                                    <strong v-text="errors.email[index]"></strong>
                                </span>
                        </div>

                        <div class="form-group mb-2">
                            <label for="phone" class="col-form-label">{{
                                    $gettext('Phone number (optional)')
                                }}</label>
                            <input type="tel" name="phone" id="phone" v-model="watcher.phone"
                                   class="form-control" :placeholder="$gettext('Phone number (optional)')"/>

                            <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                  v-if="errors.phone" v-for="(name, index) in errors.phone">
                                    <strong v-text="errors.phone[index]"></strong>
                                </span>
                        </div>

                        <div class="form-group pt-2">
                            <button class="btn btn-success" type="submit">{{ $gettext('Create') }}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as GoogleMaps from 'vue2-google-maps';
import _ from "lodash";
import asset from '../../mixins/asset';
import {VueperSlides, VueperSlide} from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
    name: "report-create",

    props: [],

    mixins: [
        asset
    ],

    components: {
        VueperSlide,
        VueperSlides,
        GoogleMaps
    },

    data() {
        return {
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
            center: {
                lat: 52.3675734,
                lng: 4.9041389,
            },
            mapLoaded: false,
            zoom: 13,
            address: '',
            options: {
                styles: [
                    {
                        featureType: "poi",
                        elementType: "labels",
                        stylers: [
                            {visibility: "off"}
                        ]
                    }
                ],
                minZoom: 9,
                streetViewControl: false,
                fullscreenControl: false,
            },
            photos: [],
            photosPreview: [],
            report: {
                title: "",
                report_category_id: "",
                description: "",
                resolution: "",
                private_note: "",
                address: "",
                city: "",
                postcode: null,
            },
            watcher: {
                full_name: '',
                email: '',
                phone: '',
            },
            showPrivate: false,
            errors: [],
            errorPhotos: [],
            categories: [],
            marker: {
                lat: 52.3675734,
                lng: 4.9041389,
                icon: this.asset('images/icon_problem_normal.png')
            },
            loader: true,
        };
    },

    mounted() {
        if (this.$root.user.councils.length) {
            this.center = {
                lat: this.$root.user.councils[0].latitude,
                lng: this.$root.user.councils[0].longitude,
            }
        }

        this.marker.lat = this.$root.user.councils[0].latitude;
        this.marker.lng = this.$root.user.councils[0].longitude;

        this.getCategories();
    },

    watch: {
        '$root.mapLoaded'() {
            this.loader = false;

            window.setTimeout(() => {
                this.geocodedAddress();
                this.updateCoordinates(this.center, true);
            }, 2000);
        },
        center() {
            if (this.$root.mapLoaded) this.geocodedAddress();
        }
    },

    methods: {
        getCategories() {
            if (this.controller) this.controller.abort();

            this.controller = new AbortController();

            axios.get("/api/report-categories?latitude=" + this.center.lat + "&longitude=" + this.center.lng, {
                signal: this.controller.signal
            }).then((res) => {
                if (res.status == 200) {
                    this.categories = res.data.data;

                    if (!_.find(this.categories, el => el.id === this.report.report_category_id)) this.report.report_category_id = '';
                }
            }).catch(() => {
            });
        },

        updateCoordinates(marker, initial = false) {
            this.center = {
                'lat': initial ? marker.lat : marker.lat(),
                'lng': initial ? marker.lng : marker.lng()
            }

            this.$root.coordinates = {
                'lat': initial ? marker.lat : marker.lat(),
                'lng': initial ? marker.lng : marker.lng()
            }

            this.marker.lat = this.$root.coordinates.lat;
            this.marker.lng = this.$root.coordinates.lng;

            this.getCategories()
        },

        geocodedAddress() {
            let self = this;
            this.report.postcode = null;
            this.report.address = null;
            this.report.city = null;

            return new Promise(function (resolve, reject) {
                let geocoder = new self.$root.google.maps.Geocoder();

                geocoder.geocode({
                    'location': {
                        'lat': self.center.lat,
                        'lng': self.center.lng,
                    },
                }, function (results, status) {
                    if (status === 'OK') {
                        if (self.timeout) clearTimeout(self.timeout);

                        let result = '';

                        if (results.filter(el => el.types[0] === 'premise').length > 0) {
                            result = results.filter(el => el.types[0] === 'premise')[0]
                        } else if (results.filter(el => el.types[0] === 'street_address').length > 0) {
                            result = results.filter(el => el.types[0] === 'street_address')[0]
                        } else {
                            result = results[0];
                        }

                        let postalCodeObj = _.find(result.address_components, function (ac) {
                            return ac.types[0] == 'postal_code'
                        });

                        let routeObj = _.find(result.address_components, function (ac) {
                            return ac.types[0] == 'route'
                        });
                        let route = '';

                        let streetNumberObj = _.find(result.address_components, function (ac) {
                            return ac.types[0] == 'street_number'
                        });
                        let streetNumber = '';

                        let city = _.find(result.address_components, function (ac) {
                            return ac.types[0] == 'locality'
                        });

                        if (postalCodeObj) self.report.postcode = postalCodeObj.short_name;
                        if (routeObj) route = routeObj.short_name;
                        if (streetNumberObj) streetNumber = streetNumberObj.short_name;
                        if (city) self.report.city = city.short_name;

                        self.report.address = route + ' ' + streetNumber;
                        if (self.report.address.trim() == '') self.report.address = result.formatted_address;

                        self.address = result.formatted_address;

                        return results.formatted_address;
                    } else {
                        if (status === 'OVER_QUERY_LIMIT') {
                            self.timeout = setTimeout(() => {
                                self.geocodedAddress()
                            }, 1000)
                        }
                    }
                })
            }).then(() => {
            })
        },

        onFileSelected(event) {
            this.photos = [];
            this.photosPreview = [];
            this.errorPhotos = [];

            this.photos = event.target.files;

            for (let i = 0; i < this.photos.length; i++) {
                let reader = new FileReader

                reader.onload = e => {
                    this.photosPreview.push(e.target.result)
                }

                reader.readAsDataURL(this.photos[i])
            }
        },

        saveData() {
            this.errors = [];
            this.loader = true;

            let form = new FormData();

            for (let key in this.report) {
                form.append(key, this.report[key]);
            }

            form.append('latitude', this.center.lat);
            form.append('longitude', this.center.lng);
            form.append('type', 'problem');
            form.append('from_admin', true);

            for (let key in this.watcher) {
                form.append(key, this.watcher[key]);
            }

            for (let i = 0; i < this.photos.length; i++) {
                form.append("photos[][file]", this.photos[i]);
            }

            axios.post("/api/reports", form, {
                "content-Type": "multipart/form-data",
            }).then((response) => {
                if (response.status == 200) {
                    window.location.href = '/admin/problems';
                }
            }).catch((error) => {
                this.errorPhotos = [];
                this.errors = error.response.data.errors
                this.loader = false;

                let search = ['photos.'];

                this.errorPhotos = _.pickBy(this.errors, (value, key) =>
                    _.some(search, str => _.includes(key, str))
                );
            });
        },
    },
};
</script>

<style>
.admin-content .vue-map-container .vue-map {
    position: relative;
    height: 400px;
}

.searchbar {
    z-index: 20;
    top: 10px;
    right: 10px;
    width: 50%;
}
</style>
