<template>
    <div class="row mx-auto">
        <div class="col-md-8 col-12 mx-auto">
            <ul class="nav nav-tabs nav-line-tabs mb-5 fs-6">
                <li class="nav-item">
                    <a class="nav-link active" data-bs-toggle="tab" href="#kt_tab_pane_1">{{ $gettext('Categories') }}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-bs-toggle="tab" href="#kt_tab_pane_2">{{ $gettext('Sub categories') }}</a>
                </li>
            </ul>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="kt_tab_pane_1" role="tabpanel">
                    <h1>{{ $gettext('Categories') }}</h1>

                    <div id="table-wrapper">
                        <data-table
                            ref="categoriesTable"
                            order-by="id"
                            order-dir="asc"
                            :per-page="['10', '25', '50', '100']"
                            :columns="columnsCategories"
                            :translate="translate"
                            :debounce-delay="500"
                            :data="categories"
                            @on-table-props-changed="reloadTable">
                            <div slot="filters" slot-scope="{ tableData, perPage }">
                                <div class="row mb-2">
                                    <div class="col-md-4">
                                        <select class="form-control w-auto me-md-2 me-0 form-input-height" v-model="tableProps.length"
                                                @change="getCategories(); $refs.categoriesTable.page = 1">
                                            <option :key="page" v-for="page in perPage">{{ page }}</option>
                                        </select>
                                    </div>
                                    <div class="col-md-8 d-flex flex-column flex-md-row justify-content-end">
                                        <v-autocomplete dense auto-select-first clearable filled solo ref="council"
                                                        hide-selected v-model="tableProps.council_id" :items="councils"
                                                        color="white" item-text="name" item-value="id" class="form-control me-md-2"
                                                        :placeholder="$gettext('All councils')"
                                                        @change="hideMenu('council'); getCategories(); $refs.categoriesTable.page = 1"
                                                        @input="showMenu('council')" @mouseup="showMenu('council')"
                                                        @click:append-outer="toggleMenu('council')">
                                        </v-autocomplete>
                                        <input name="search" id="search" class="form-control w-auto form-input-height"
                                               :placeholder="$gettext('Search categories...')"
                                               v-model="tableProps.search" @input="debounceInput">
                                    </div>
                                </div>
                            </div>
                            <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td :key="column.name" v-for="column in columnsCategories"
                                    @click="column.name !== 'Edit' ? showRowNumber(item.id, 'main') : ''">
                                    <data-table-cell
                                        :value="item"
                                        :name="column.name"
                                        :meta="column.meta"
                                        :comp="column.component"
                                        :classes="column.classes">
                                    </data-table-cell>
                                </td>
                            </tr>
                            </tbody>
                        </data-table>
                    </div>
                </div>
                <div class="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
                    <h1>{{ $gettext('Sub categories') }}</h1>

                    <div id="table-wrapper">
                        <data-table
                            ref="subCategoriesTable"
                            order-by="id"
                            order-dir="asc"
                            :per-page="['10', '25', '50', '100']"
                            :columns="columnsSubCategories"
                            :translate="translate"
                            :debounce-delay="500"
                            :data="subCategories"
                            @on-table-props-changed="reloadSubTable">
                            <div slot="filters" slot-scope="{ tableData, perPage }">
                                <div class="row mb-2">
                                    <div class="col-md-4">
                                        <select class="form-control w-auto me-md-2 me-0 form-input-height" v-model="tableSubProps.length"
                                                @change="getSubCategories(); $refs.subCategoriesTable.page = 1">
                                            <option :key="page" v-for="page in perPage">{{ page }}</option>
                                        </select>
                                    </div>
                                    <div class="col-md-8 d-flex flex-column flex-md-row justify-content-end">
                                        <v-autocomplete dense auto-select-first clearable filled solo ref="category"
                                                        hide-selected v-model="tableSubProps.category_id" :items="filterCategories"
                                                        color="white" item-text="name" item-value="id" class="form-control me-md-2"
                                                        :placeholder="$gettext('All categories')"
                                                        @change="hideMenu('category'); getSubCategories(); $refs.subCategoriesTable.page = 1"
                                                        @input="showMenu('category')" @mouseup="showMenu('category')"
                                                        @click:append-outer="toggleMenu('category')">
                                        </v-autocomplete>
                                        <input name="search" id="search" :placeholder="$gettext('Search sub categories...')"
                                               class="form-control w-auto form-input-height"
                                               v-model="tableSubProps.search" @input="debounceSubInput">
                                    </div>
                                </div>
                                <tbody slot="body" slot-scope="{ data }">
                                <tr :key="item.id" v-for="item in data">
                                    <td :key="column.name" v-for="column in columnsSubCategories"
                                        @click="column.name !=='Edit' ? showRowNumber(item.id, 'sub') : ''">
                                        <data-table-cell
                                            :value="item"
                                            :name="column.name"
                                            :meta="column.meta"
                                            :comp="column.component"
                                            :classes="column.classes">
                                        </data-table-cell>
                                    </td>
                                </tr>
                                </tbody>
                            </div>
                        </data-table>
                    </div>
                </div>
            </div>

            <modal
                :modaltitle="$gettext('Delete Category')"
                :modaltext="$gettext('Do you want to delete this category?')"
                :buttontext="$gettext('Delete')"
                :ajax_url="this.base_url + '/admin/categories'"
            ></modal>
            <modal-message-ajax redirect-back="false"></modal-message-ajax>
        </div>
    </div>
</template>

<script>
import ActionTableButtons from "./ActionTableButtonsCategories.vue";
import { DataTable } from "laravel-vue-datatable";
import AutocompleteMixin from "../../mixins/autocomplete";
import {debounce} from "lodash";

export default {
    name: "categories-table",

    props: ["base_url"],

    mixins: [
        AutocompleteMixin
    ],

    components: {
        ActionTableButtons,
        DataTable,
    },

    data() {
        return {
            url: "",
            subUrl: "",
            tableProps: {
                length: 10,
                council_id: '',
                search: '',
                column: 'id',
                dir: 'asc',
            },
            tableSubProps: {
                length: 10,
                category_id: '',
                search: '',
                column: 'id',
                dir: 'asc',
            },
            rowId: "",
            councils: [],
            selectOptions: [],
            filterCategories: [],
            category: {},
            categories: {},
            subCategories: {},
            columnsCategories: [
                {
                    label: "Id",
                    name: "id",
                    orderable: true,
                },
                {
                    label: this.$gettext("Name"),
                    name: "name",
                    orderable: true,
                },
                {
                    label: this.$gettext("Main category"),
                    name:  "parent_name",
                    columnName:  "parent_id",
                    orderable: true,
                },
                {
                    label: this.$gettext("Actions"),
                    name: "Edit",
                    orderable: false,
                    classes: {
                        btn: true,
                        "btn-primary": true,
                        "btn-sm": true,
                    },
                    component: ActionTableButtons,
                    meta: {
                        0: this.base_url + "/admin/categories",
                        1: 'categories'
                    },
                },
            ],
            columnsSubCategories: [
                {
                    label: "Id",
                    name: "id",
                    orderable: true,
                },
                {
                    label: this.$gettext("Name"),
                    name: "name",
                    orderable: true,
                },
                {
                    label: this.$gettext("Main category"),
                    name:  "parent_name",
                    orderable: false,
                },
                {
                    label: this.$gettext("Actions"),
                    name: "Edit",
                    orderable: false,
                    classes: {
                        btn: true,
                        "btn-primary": true,
                        "btn-sm": true,
                    },
                    component: ActionTableButtons,
                    meta: {
                        0: this.base_url + "/admin/categories/sub",
                        1: 'sub_categories'
                    },
                },
            ],
            translate: {
                nextButton: this.$gettext('Next'),
                previousButton: this.$gettext('Previous'),
                placeholderSearch: this.$gettext('Search categories...'),
            },
        };
    },

    mounted() {
        this.url = this.base_url + "/admin/categories/datatable";
        this.subUrl = this.base_url + "/admin/categories/sub/datatable";

        let self = this;

        this.$root.$on("get-datatable-row-id", function (id) {
            self.rowId = id;
        });

        this.$root.$on("modal-confirmed", function () {
            self.deleteRow(self.rowId);
        });

        this.getCouncils();
        this.getFilterCategories();

        this.getCategories();
        this.getSubCategories();
    },

    methods: {
        showRowNumber(id, type) {
            type === 'main' ? window.location = "/admin/categories/" + id : window.location = "/admin/categories/sub/" + id;
        },

        deleteRow(id) {

            let url = "/admin/categories/" + id;

            let self = this;

            axios.delete(url).then(() => {
                self.$refs.categoriesTable.getData();
                window.location.reload();
            }).catch(function(response){
                    self.$root.$emit('modal-message-ajax', {
                        title: self.$gettext('Error'),
                        body: self.$gettext(response.response.data.message),
                    })
            });
        },

        getCouncils() {
            axios.get('/admin/categories/councils').then(res => {
                this.councils = res.data.data;
            })
        },

        debounceInput: debounce(function (e) {
            this.$refs.categoriesTable.page = 1

            this.getCategories()
        }, 500),

        debounceSubInput: debounce(function (e) {
            this.$refs.subCategoriesTable.page = 1

            this.getSubCategories()
        }, 500),

        getCategories(url = this.url, options = this.tableProps) {
            axios.get(url, {
                params: {
                    search: options.search,
                    length: options.length,
                    page: options.page,
                    column: options.column,
                    dir: options.dir,
                    councils: this.tableProps.council_id,
                }
            }).then(response => {
                this.categories = response.data;
            })
        },

        getSubCategories(url = this.subUrl, options = this.tableSubProps) {
            axios.get(url, {
                params: {
                    search: options.search,
                    length: options.length,
                    page: options.page,
                    column: options.column,
                    dir: options.dir,
                    councils: this.tableSubProps.council_id,
                }
            }).then(response => {
                this.subCategories = response.data;
            })
        },

        getFilterCategories() {
            axios.get('/api/report-categories').then(res => {
                this.filterCategories = res.data.data;
            });
        },

        reloadTable(tableProps) {
            tableProps['length'] = this.tableProps.length;
            tableProps['search'] = this.tableProps.search;

            this.getCategories(this.url, tableProps);
        },

        reloadSubTable(tableProps) {
            tableProps['length'] = this.tableSubProps.length;
            tableProps['search'] = this.tableSubProps.search;

            this.getSubCategories(this.subUrl, tableProps);
        },
    },
};
</script>
