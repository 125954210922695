module.exports = {
    methods: {
        hideMenu(ref) {
            this.$refs[ref].$children ? this.$refs[ref].$children[1].$el.classList.add('d-none') :
                this.$refs[ref][0].$children[1].$el.classList.add('d-none');
        },

        // Show menu for autocomplete
        showMenu(ref) {
            this.$refs[ref].$children ? this.$refs[ref].$children[1].$el.classList.remove('d-none') :
                this.$refs[ref][0].$children[1].$el.classList.remove('d-none');
        },

        // Toggle menu for autocomplete
        toggleMenu(ref) {
            if (!this.$refs[ref].$children[1].$el.classList.contains('d-none')) {
                this.$refs[ref].$children[1].$el.classList.add('d-none');
            } else {
                this.$refs[ref].$children[1].$el.classList.remove('d-none');
            }
        },
    },
};
