<template>
    <div class="">
        <div class="modal fade" tabindex="-1" id="handleReportModal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ modaltitle }}</h5>

                        <!--begin::Close-->
                        <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                            <span class="svg-icon svg-icon-2x"></span>
                        </div>
                        <!--end::Close-->
                    </div>

                    <div class="modal-body">
                        <div class="form-outline">
                            <label class="form-label" for="rejectReportMessage">{{ $gettext('Message') }}</label>
                            <textarea class="form-control" id="rejectReportMessage" :placeholder="$gettext('Enter a message...')"
                                      rows="4" v-model="message"></textarea>
                        </div>
                    </div>

                    <div class="modal-footer d-flex justify-content-between">
                        <button type="button" class="btn btn-danger text-white" data-bs-dismiss="modal"
                                v-text="$gettext('Cancel')"></button>
                        <button type="button" class="btn admin-create-button" data-bs-dismiss="modal"
                            @click="submit()">{{ buttontext }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
export default {
    name: 'handle-report',

    props: [
        "modaltitle",
        "modaltext",
        "buttontext",
        "report_id"
    ],

    data() {
        return {
            message: null,
        };
    },

    methods: {
        submit() {
            axios.post('/admin/reports/update-status/' + this.report_id, {
                status: 'done',
                message: this.message,
            }).then(() => {
                window.location.reload();
            }).catch(() => {

            });
        },
    },
};
</script>
