<template>
    <div class="container">
        <div class="row mx-0">
            <div class="col-lg-8 col-md-10 col-12 mx-auto px-0">
                <div class="card my-3 shadow-sm opacity" v-show="showForm && !formSuccess">
                    <div class="card-body p-md-3 p-2">
                        <div class="text-right p-2">
                            <router-link to="/" class="text-dark text-decoration-none close-button">×</router-link>
                        </div>
                        <form @submit.prevent="saveData()">
                            <!-- First half -->
                            <div class="mb-3">

                                <div class="form-group mb-4">
                                    <input type="email" class="form-control bg-transparent fw-bold shadow-none"
                                           name="email" placeholder="E-mailadres" v-model="form.email" required/>

                                    <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                          v-if="errors.email" v-for="(name, index) in errors.email">
                                        <strong v-text="errors.email[index]"></strong>
                                    </span>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group mb-4">
                                            <input type="password" class="form-control bg-transparent fw-bold shadow-none"
                                                   name="password" min="6" placeholder="Wachtwoord" v-model="form.password" required/>

                                            <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                                  v-if="errors.password" v-for="(name, index) in errors.password">
                                                <strong v-text="errors.password[index]"></strong>
                                            </span>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group mb-4">
                                            <input type="password" class="form-control bg-transparent fw-bold shadow-none" min="6"
                                                   name="password_confirmation" placeholder="Wachtwoord herhalen" v-model="form.password_confirmation" required/>

                                            <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                                  v-if="errors.password_confirmation" v-for="(name, index) in errors.password_confirmation">
                                                <strong v-text="errors.password_confirmation[index]"></strong>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Second half -->
                            <div>
                                <h4 class="h2">Persoonsgegevens</h4>

                                <div>
                                    <div class="row mb-4">
                                        <div class="col-lg-2 col-md-6 mb-4 mb-lg-0">
                                            <select class="form-control bg-transparent fw-bold shadow-none"
                                                    v-model="form.gender" name="gender" required>
                                                <option value="male">Dhr.</option>
                                                <option value="female">Mw.</option>
                                            </select>
                                        </div>

                                        <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">
                                            <input type="text" class="form-control bg-transparent fw-bold shadow-none"
                                                   name="first_name" placeholder="Voornaam" v-model="form.first_name" required/>

                                            <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                                  v-if="errors.first_name" v-for="(name, index) in errors.first_name">
                                                <strong v-text="errors.first_name[index]"></strong>
                                            </span>
                                        </div>

                                        <div class="col-lg-2 col-md-6 mb-4 mb-lg-0">
                                            <input type="text" class="form-control bg-transparent fw-bold shadow-none"
                                                   name="middle_name" placeholder="Tussenvoegsel" v-model="form.middle_name"/>

                                            <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                                  v-if="errors.middle_name" v-for="(name, index) in errors.middle_name">
                                                <strong v-text="errors.middle_name[index]"></strong>
                                            </span>
                                        </div>

                                        <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">
                                            <input type="text" class="form-control bg-transparent fw-bold shadow-none"
                                                   name="last_name" placeholder="Achternaam" v-model="form.last_name" required/>

                                            <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                                  v-if="errors.last_name" v-for="(name, index) in errors.last_name">
                                                <strong v-text="errors.last_name[index]"></strong>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mb-4">
                                    <div class="col-lg-3 col-md-6 mb-4 mb-lg-0">
                                        <input type="text" class="form-control bg-transparent fw-bold shadow-none"
                                               name="street" placeholder="Straat" v-model="form.street" required/>

                                        <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                              v-if="errors.street" v-for="(name, index) in errors.street">
                                            <strong v-text="errors.street[index]"></strong>
                                        </span>
                                    </div>

                                    <div class="col-lg-2 col-md-6 mb-4 mb-lg-0">
                                        <input type="text" class="form-control bg-transparent fw-bold shadow-none"
                                               name="street_number" placeholder="Huisnummer" v-model="form.street_number" required/>

                                        <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                              v-if="errors.street_number" v-for="(name, index) in errors.street_number">
                                            <strong v-text="errors.street_number[index]"></strong>
                                        </span>
                                    </div>

                                    <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">
                                        <input type="text" class="form-control bg-transparent fw-bold shadow-none"
                                               name="city" placeholder="Plaats" v-model="form.city" required/>

                                        <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                              v-if="errors.city" v-for="(name, index) in errors.city">
                                            <strong v-text="errors.city[index]"></strong>
                                        </span>
                                    </div>

                                    <div class="col-lg-3 col-md-6 mb-4 mb-lg-0">
                                        <input type="text" class="form-control bg-transparent fw-bold shadow-none"
                                               name="postcode" placeholder="Postcode" v-model="form.postcode" required/>

                                        <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                              v-if="errors.postcode" v-for="(name, index) in errors.postcode">
                                            <strong v-text="errors.postcode[index]"></strong>
                                        </span>
                                    </div>
                                </div>

                                <div class="row mb-4">
                                    <div class="col-md-12">
                                        <input type="text" class="form-control bg-transparent fw-bold shadow-none"
                                               name="phone" placeholder="Telefoonnummer (optioneel)" v-model="form.phone"/>

                                        <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                              v-if="errors.phone" v-for="(name, index) in errors.phone">
                                            <strong v-text="errors.phone[index]"></strong>
                                        </span>
                                    </div>
                                </div>

                                <!-- Checkbox -->
                                <div class="form-check d-flex mb-4">
                                    <input class="form-check-input" name="newsletter" id="newsletter"
                                           type="checkbox" v-model="form.newsletter"/>
                                    <label class="form-check-label ms-1" for="newsletter">Ik wil graag de nieuwsbrief ontvangen</label>
                                </div>
                            </div>

                            <div class="d-flex justify-content-evenly sign-in-btn">
                                <!-- Submit button -->
                                <button type="button" class="btn btn-success fw-bold text-white" @click="resetSettings()">Annuleren</button>
                                <button type="submit" class="btn btn-success fw-bold text-white">Registreren</button>
                            </div>
                        </form>
                    </div>
                    <div v-if="showSpinner" class="load-spinner-container">
                        <!-- here will be the loader -->
                    </div>
                </div>

                <div class="card my-3 shadow-sm opacity" v-show="!showForm && formSuccess">
                    <div class="card-body p-md-3 p-2 text-center">
                        <img class="mb-4" :src="asset('images/approved.png')" alt="Goedgekeurd">

                        <h4 class="title">Je bent succesvol geregistreerd!</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ImageMixin from "../mixins/asset";

export default {
    name: "register",

    mixins: [ImageMixin],

    data() {
        return {
            showForm: true,
            formSuccess: false,
            showSpinner: false,
            form: {
                email: "",
                password: "",
                password_confirmation: "",
                gender: "male",
                first_name: "",
                middle_name: "",
                last_name: "",
                street: "",
                street_number: "",
                postcode: "",
                city: "",
                phone: "",
                terms: "",
                newsletter: 0,
            },
            errors: []
        };
    },

    methods: {
        saveData() {
            this.showSpinner = true;
            this.errors = [];

            axios.post("/register", this.form).then((response) => {
                if (response.status == 200) {
                    this.$root.authUser = true;
                    this.$root.user = response.data.data;

                    this.showForm = false;
                    this.formSuccess = true;
                    this.$router.push({name: 'settings'});
                    //It can't auth the user porperly without refresh. Don't know why
                    window.location.reload();
                }
            }).catch((errors) => {
                this.errors = errors.response.data.errors
            });
        },

        resetSettings() {
            this.form = {
                email: "",
                password: "",
                password_confirmation: "",
                gender: "male",
                first_name: "",
                middle_name: "",
                last_name: "",
                street: "",
                street_number: "",
                postcode: "",
                city: "",
                phone: "",
                terms: "",
                newsletter: 0,
            }
        },

        confirmModal() {
            this.$router.push({ name: "settings" });
        },
    },
};
</script>

<style scoped>
.opacity {
    opacity: .9;
}

.form-control {
    border: none;
    border-bottom: 1px solid #d3d3d3;
    height: 50px;
    font-size: 16px;
}

.sign-in-btn button {
    font-size: 25px;
}
</style>
