<template>
    <b-modal class="modal modal-message-ajax fade" tabindex="-1" @ok="ok" ok-variant="ok" id="front-page-modal-ajax" :title="message.title" ok-only hide-backdrop>
        <p class="my-4" v-text="message.body"></p>
    </b-modal>
</template>

<script>
export default {

    props: [
        'redirectBack',
    ],

    data() {
        return {
            show:false,
            message: {
                body: "",
                title: "",
            },
            redirect: true,
        }
    },

    mounted() {
        if(this.redirectBack == 'false'){
            this.redirect = false;
        };

        this.modalTrigger();
    },

    computed: {
        modal_message() {
            return JSON.parse(this.session_modal_message);
        },
    },

    methods: {
        modalTrigger() {
            let self = this;

            this.$root.$on('modal-message-ajax', function(data) {
                self.message.title = data.title;
                self.message.body = data.body;
                this.$bvModal.show("front-page-modal-ajax");
            });
        },
        ok() {
            if(this.redirect){
                window.location.reload();
            }
        }
    },


}
</script>

<style>
.btn-ok {
    background:#3ab53a;
    color:white;
}

.btn-ok:hover{
    background:#65d365;
    color:white;
}

.modal-body p {
    font-size:20px;
}

</style>
