<template>
    <div class="container">
        <div class="row mx-0">
            <div class="col-12 mx-auto px-0">
                <div class="card my-3 p-4 shadow-sm opacity">
                    <div class="card-header bg-white p-0 border-0 d-flex justify-content-between">
                        <h1 class="title fw-bold" v-text="report.title"></h1>

                        <div>
                            <a @click.prevent="goToMap()" class="text-secondary text-decoration-none back-button me-2">
                                <i class="fa-solid fa-map-location-dot"></i>
                            </a>

                            <router-link to="/" class="text-secondary text-decoration-none back-button">
                                <i class="fas fa-arrow-alt-circle-left"></i>
                            </router-link>
                        </div>
                    </div>

                    <div class="card-body p-0">
                        <div class="row" v-if="Object.keys(report).length > 0">
                            <div class="col-md-6">
                                <table class="table table-responsive-sm">
                                    <tbody>
                                    <tr>
                                        <td>Status:</td>
                                        <td v-if="report.status">
                                            <span v-text="report.status_formatted"></span>
                                        </td>
                                        <td v-else>-</td>
                                    </tr>
                                    <tr>
                                        <td>Datum aanmaak:</td>
                                        <td v-text="new Date(report.created_at).toLocaleDateString('nl', options)"></td>
                                    </tr>
                                    <tr v-if="report.type === 'problem'">
                                        <td>Categorie:</td>
                                        <td v-if="report.category" v-text="report.category.name"></td>
                                        <td v-else>-</td>
                                    </tr>
                                    <tr>
                                        <td>Plaats:</td>
                                        <td v-if="report.city" v-text="report.city.name"></td>
                                        <td v-else>-</td>
                                    </tr>
                                    <tr>
                                        <td>Adres:</td>
                                        <td v-text="report.address ?? '-'"></td>
                                    </tr>
                                    <tr>
                                        <td>Postcode:</td>
                                        <td v-text="report.postcode && report.postcode !== '' ? report.postcode : '-'"></td>
                                    </tr>
                                    <tr>
                                        <td>Beschrijving:</td>
                                        <td v-text="report.description ?? '-'"></td>
                                    </tr>
                                    <tr v-if="report.resolution && report.resolution !== ''">
                                        <td>Realisatie:</td>
                                        <td v-text="report.resolution"></td>
                                    </tr>
                                    <tr v-if="report.is_fixed && report.rating">
                                        <td>Beoordeling:</td>
                                        <td>
                                            <div class="d-flex">
                                                <i class="fa-solid fa-star text-success" v-for="n in parseInt(report.rating)"></i>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>

                                <div class="d-flex mb-2">
                                    <button class="btn btn-success text-white me-1" @click="reactAction()"
                                            :data-bs-toggle="$root.authUser && $root.user.is_verified ? 'modal' : ''" :data-bs-target="$root.authUser ? '#reactModal' : ''">
                                        <span>Reageer</span>
                                        <span class="bg-white text-success px-2 rounded-4" v-if="report.remarks"
                                              v-text="remarksLength"></span>
                                        <span class="bg-white text-success px-2 rounded-4" v-else>0</span>
                                    </button>
                                    <button class="btn btn-success text-white" @click="addVote()">
                                        <span>Stem</span>
                                        <span class="bg-white text-success px-2 rounded-4" v-text="votes"></span>
                                    </button>
                                </div>

                                <div v-if="hasVoted">
                                    <div class="alert alert-danger alert-dismissible fade show" role="alert">
                                        U heeft al gestemd!
                                        <button type="button" class="btn-close" data-bs-dismiss="alert"
                                                aria-label="Close" @click="hasVoted = false"></button>
                                    </div>
                                </div>

                                <div class="reactions-body">
                                    <div id="accordion" v-if="Object.keys(remarks).length">
                                        <div v-for="(remark, index) in remarks" :key="index" class="accordion-item">
                                            <div class="card-header border" :id="'heading' + (index + 1)">
                                                <div class="card">
                                                    <div class="card-header d-flex justify-content-between align-items-center">
                                                        <p class="mb-0">
                                                            <span v-if="remark.is_anonymous">Anonymous</span>
                                                            <span v-else v-text="remark.user_name"></span>
                                                            <span v-text="'(' + new Date(remark.created_at).toLocaleDateString() + ')'"></span>
                                                        </p>

                                                        <button class="btn btn-success text-white" type="button" data-bs-toggle="collapse"
                                                                :id="'btn_react_' + (index + 1)"
                                                                :data-bs-target="$root.authUser ? '#collapse' + (index + 1) : ''"
                                                                aria-expanded="true" :aria-controls="$root.authUser ? 'collapse' + (index + 1) : ''"
                                                                @click="reactAction(true)">
                                                            Reageren op <i class="fas fa-reply"></i>
                                                        </button>
                                                    </div>

                                                    <div class="card-body">
                                                        <p class="mb-0" v-html="remark.body"></p>

                                                        <div class="text-right mb-2" v-if="Object.keys(remark.replies).length">
                                                            <a href="#" class="text-decoration-none text-success" @click="showReplies((index + 1))">
                                                                Reacties bekijken
                                                                <span v-text="'(' + Object.keys(remark.replies).length + ')'"></span>
                                                            </a>
                                                        </div>

                                                        <div v-if="activeReplies == (index + 1)">
                                                            <div v-for="reply in remark.replies" class="card mb-2">
                                                                <div class="card-header">
                                                                    <p class="mb-0">
                                                                        <span v-if="reply.is_anonymous">Anonymous</span>
                                                                        <span v-else v-text="reply.user_name"></span>
                                                                        <span v-text="'(' + new Date(reply.created_at).toLocaleDateString() + ')'"></span>
                                                                    </p>
                                                                </div>

                                                                <div class="card-body">
                                                                    <p class="mb-0" v-text="reply.body"></p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div :id="'collapse' + (index + 1)" class="accordion-collapse collapse"
                                                             :aria-labelledby="'heading' + (index + 1)" data-bs-parent="#accordion">
                                                            <div class="accordion-body mt-2">
                                                                <form @submit.prevent="addReply(remark.id, index + 1)">
                                                                    <div class="form-group mb-2">
                                                                        <textarea class="form-control" rows="5" required v-model="reply.body"></textarea>
                                                                    </div>

                                                                    <div class="form-check d-flex">
                                                                        <input class="form-check-input" type="checkbox" value="0" v-model="reply.is_anonymous"
                                                                               :disabled="$root.user && $root.user.full_name === ''"/>
                                                                        <label class="form-check-label ms-1">Anoniem posten</label>
                                                                    </div>

                                                                    <div class="text-right mt-2">
                                                                        <button class="btn btn-success text-white">Indienen <i class="fas fa-sign-in-alt"></i></button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <hr v-if="Object.keys(remarks).length > 1 && (index + 1) <= Object.keys(remarks).length - 1">
                                        </div>
                                    </div>
                                    <div v-else>
                                        <p class="mb-0">Nog geen opmerkingen toegevoegd...</p>
                                    </div>
                                </div>
                            </div>

                            <div v-if="report.urls && Object.keys(report.urls).length > 0" class="col-md-6">
                                <div v-if="Object.keys(report.urls).length == 1" class="image mx-auto" :style="{backgroundImage: 'url(' + report.urls[0] + ')'}"></div>

                                <div v-if="Object.keys(report.urls).length > 1">
                                    <carousel-gallery :photos="JSON.stringify(report.urls)" :length="report.urls.length"></carousel-gallery>
                                </div>
                            </div>
                            <div v-else class="col-md-6">
                                <div class="image mx-auto"
                                     :style="{backgroundImage: 'url(' + 'https://maps.googleapis.com/maps/api/staticmap?center=' +
                             report.latitude + ',' + report.longitude + '&markers=' + report.latitude + ',' + report.longitude +
                             '&zoom=15&size=600x400&maptype=satellite&key=' + $root.mapsKey + ')'}">
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <h4 class="mb-0 text-danger text-center" v-text="errorMessage"></h4>
                        </div>
                    </div>
                </div>

                <div class="modal fade" tabindex="-1" id="reactModal">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">{{ report.title }}</h5>

                                <!--begin::Close-->
                                <div class="text-decoration-none close-button"
                                     data-bs-dismiss="modal" aria-label="Close">×</div>
                                <!--end::Close-->
                            </div>

                            <div class="modal-body px-3">
                                <form @submit.prevent="addRemark()">
                                    <div class="form-group mb-2">
                                        <label for="new_reaction" class="col-form-label">Nieuwe toevoegen</label>
                                        <textarea class="form-control" rows="5" id="new_reaction" required v-model="reaction.body"></textarea>
                                    </div>

                                    <div class="form-check d-flex mb-2">
                                        <input class="form-check-input" type="checkbox" value="0" v-model="reaction.is_anonymous"
                                               :disabled="$root.user && $root.user.full_name === ''"/>
                                        <label class="form-check-label ms-1">Anoniem posten</label>
                                    </div>

                                    <hr>

                                    <div class="d-flex justify-content-between">
                                        <button type="button" class="btn btn-danger text-white" data-bs-dismiss="modal">Sluiten</button>
                                        <button class="btn btn-success text-white">Indienen <i class="fas fa-sign-in-alt"></i></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ImageMixin from "../mixins/asset";
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
    name: "report",

    mixins: [ImageMixin],

    components: {
        VueperSlides,
        VueperSlide
    },

    mounted() {
        this.getReport(this.$route.params.id);

        this.reply.is_anonymous = this.$root.user && this.$root.user.full_name === '' ? 1 : 0;
        this.reaction.is_anonymous = this.$root.user && this.$root.user.full_name === '' ? 1 : 0;
    },

    data() {
        return {
            index: null,
            report: {},
            activeReaction: 0,
            activeReplies: 0,
            reply: {
                body: '',
                is_anonymous: 0,
            },
            reaction: {
                body: '',
                is_anonymous: 0,
            },
            remarks: {},
            remarksLength: 0,
            votes: 0,
            errorMessage: '',
            hasVoted: false,
            options: {
                year: "numeric",
                month: "2-digit",
                day: "2-digit"
            },
        };
    },

    methods: {
        getReport(id) {
            axios.get("/api/reports/" + id, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                }
            }).then((response) => {
                this.report = response.data.data;

                this.remarks = this.report.remarks.filter(el => el.parent_id == null)

                this.votes = this.report.votes_count ?? 0;

                this.getRemarks();
            }).catch(error => {
                this.errorMessage = error.response.data.errors.id[0];
            });
        },

        getRemarks() {
            this.remarksLength = 0;
            let remarkReplies = this.report.remarks.filter(el => el.parent_id != null)
            this.remarksLength += Object.keys(this.remarks).length;

            this.remarks.forEach((remark) => {
                remark['replies'] = remarkReplies.filter(reply => reply.parent_id == remark.id)

                this.remarksLength += remark['replies'].length
            });
        },

        showArea(id) {
            this.reply = {
                body: '',
                is_anonymous: this.$root.user && this.$root.user.full_name === '' ? 1 : 0,
            };

            this.activeReaction == id ? this.activeReaction = 0 : this.activeReaction = id;
        },

        showReplies(id) {
            this.activeReplies == id ? this.activeReplies = 0 : this.activeReplies = id;
        },

        reactAction(isReply = false, index = null) {
            if (isReply) {
                this.$root.authUser ? (this.$root.user.is_verified ? this.showArea((index + 1)) : this.$root.$router.push({name: 'settings'})) : this.$root.$router.push({name: 'login'})
            } else {
                !this.$root.authUser ? this.$root.$router.push({name: 'login'}) : (this.$root.authUser && !this.$root.user.is_verified ? this.$root.$router.push({name: 'settings'}) : '')
            }
        },

        addRemark() {
            axios.post('/api/report-remarks', {
                'report_id': this.report.id,
                'body': this.reaction.body,
                'is_anonymous': this.reaction.is_anonymous,
            }).then(res => {
                this.reaction = {
                    body: '',
                    is_anonymous: this.$root.user && this.$root.user.full_name === '' ? 1 : 0,
                };

                this.remarks.unshift(res.data.data);

                this.getRemarks();

                $('#reactModal').modal('hide');
            })
        },

        addReply(parent, index) {
            axios.post('/api/report-remarks', {
                'parent_id': parent,
                'report_id': this.report.id,
                'body': this.reply.body,
                'is_anonymous': this.reply.is_anonymous,
            }).then(res => {
                this.reply = {
                    body: '',
                    is_anonymous: this.$root.user && this.$root.user.full_name === '' ? 1 : 0,
                };

                this.remarks.filter(el => el.id == parent)[0]['replies'].unshift(res.data.data);

                this.activeReplies = index;
                this.remarksLength += 1;

                $('#btn_react_' + index).click()
            })
        },

        addVote() {
            if (this.$root.authUser) {
                axios.post('/api/reports/' + this.report.id + '/vote').then(res => {
                    if (res.data.data.exists) {
                        this.hasVoted = true;

                        setTimeout(() => {
                            this.hasVoted = false;
                        }, 3000)

                        return;
                    }

                    this.votes += 1;
                })
            } else {
                this.$router.push({name: 'login'});
            }
        },

        goToMap() {
            window.localStorage.setItem('tempMarker', JSON.stringify({
                'id': this.report.id,
                'lat': this.report.latitude,
                'lng': this.report.longitude,
            }))

            this.$router.push({name: 'home'})
        }
    },
};
</script>

<style scoped>
.accordion-item p {
    font-size: 16px;
}

.reactions-body {
    max-height: 470px;
    overflow: auto;
}

.image {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 600px;
    height: 400px;
}

.opacity {
    opacity: 0.96;
    padding: 20px;
}

@media only screen and (max-width: 1400px) {
    .image {
        width: 100%;
    }
}
</style>
