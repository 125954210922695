<template>
    <div class="container">
        <div class="card my-3 p-3 shadow-sm opacity">
            <div class="card-header bg-white p-0 border-0 d-flex justify-content-between">
                <h1 class="title fw-bold">Privacybeleid</h1>

                <router-link to="/" class="text-dark text-decoration-none close-button">×</router-link>
            </div>

            <div class="card-body p-0">
                <p>
                    <router-link to="/" class="text-decoration-none text-success fw-bold">Verbeterdebuurt
                    </router-link>
                    is toegewijd aan het beschermen van de privacy en veiligheid van onze gebruikers. Dit privacybeleid
                    legt uit hoe we persoonlijke informatie verzamelen, gebruiken en delen die via onze website is
                    verzameld.
                </p>

                <h2 class="h2">Informatie die we verzamelen</h2>

                <p>We verzamelen twee soorten informatie van onze gebruikers:</p>

                <ul>
                    <li>Persoonlijke informatie - Dit omvat alle informatie die kan worden gebruikt om u persoonlijk te
                        identificeren, zoals uw naam, e-mailadres, telefoonnummer en postadres.</li>

                    <li>Niet-persoonlijke informatie - Dit omvat informatie die niet kan worden gebruikt om u persoonlijk te
                        identificeren, zoals uw IP-adres, browsertype en besturingssysteem.</li>
                </ul>

                <p>We verzamelen persoonlijke en niet-persoonlijke informatie op de volgende manieren:</p>

                <p>Locatiegegevens - We kunnen locatiegegevens verzamelen wanneer u onze website gebruikt. Deze informatie
                    helpt ons u relevantere inhoud te bieden en onze services te verbeteren.</p>

                <ul>
                    <li>Cookies - We gebruiken cookies om niet-persoonlijke informatie te verzamelen over uw gebruik van onze
                        website. Cookies zijn kleine tekstbestanden die worden opgeslagen op uw apparaat wanneer u onze website
                        bezoekt. Ze helpen ons uw voorkeuren te onthouden en uw gebruikerservaring te verbeteren.</li>

                    <li>Analytics - We gebruiken tools van derden voor analyse, zoals Google Analytics, om informatie te
                        verzamelen over uw gebruik van onze website. Deze informatie helpt ons te begrijpen hoe onze website
                        wordt gebruikt en onze services te verbeteren.</li>
                </ul>

                <h2 class="h2">Hoe we uw informatie gebruiken</h2>

                <p>We gebruiken de informatie die we verzamelen om:</p>

                <ul>
                    <li>Onze services te leveren en te verbeteren</li>
                    <li>Met u te communiceren</li>
                    <li>Te reageren op uw verzoeken</li>
                    <li>Onze website te analyseren en te verbeteren</li>
                    <li>Frauduleuze activiteiten te voorkomen</li>
                    <li>Te voldoen aan wettelijke verplichtingen</li>
                    <li>Delen van uw informatie</li>
                </ul>

                <p>We kunnen uw persoonlijke informatie delen in de volgende situaties:</p>

                <ul>
                    <li>Met serviceproviders van derden die ons helpen bij het leveren van onze services, zoals websitehosting
                        en analytics.</li>
                    <li>Met wetshandhavingsinstanties of andere overheidsinstanties in reactie op een juridisch verzoek of
                        onderzoek.</li>
                    <li>In geval van een fusie, overname of verkoop van ons bedrijf.</li>
                    <li>We verkopen of verhuren uw persoonlijke informatie niet aan derden.</li>
                    <li>Beveiliging</li>
                </ul>

                <p>We nemen maatregelen om de beveiliging van uw persoonlijke informatie te beschermen. Geen enkele methode
                    van overdracht via internet of elektronische opslag is echter 100% veilig. Daarom kunnen we geen
                    absolute veiligheid garanderen.</p>

                <h2 class="h2">Uw keuzes</h2>

                <p>U kunt ervoor kiezen om geen promotie-e-mails van ons te ontvangen door de instructies in die e-mails te
                    volgen. U kunt er ook voor kiezen om cookies uit te schakelen in uw browserinstellingen, hoewel dit van
                    invloed kan zijn op de functionaliteit van onze website.</p>

                <h2 class="h2">Privacy van kinderen</h2>

                <p>Onze website is niet bedoeld voor gebruik door kinderen onder de 13 jaar. We verzamelen niet bewust
                    persoonlijke informatie van kinderen jonger dan 13 jaar.</p>

                <h2 class="h2">Wijzigingen in dit beleid</h2>

                <p>We kunnen dit privacybeleid van tijd tot tijd bijwerken. We zullen u op de hoogte stellen van eventuele
                    wijzigingen door het nieuwe beleid op onze website te plaatsen.</p>

                <h2 class="h2">Neem contact met ons op</h2>

                <p>Als u vragen of zorgen heeft over dit privacybeleid, neem dan contact met ons op via
                    <a href="mailto:support@verbeterdebuurt.nl">support@verbeterdebuurt.nl</a>.</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {}
</script>
