<template>
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-10 mx-auto px-0">
                <div class="card my-3 p-4 shadow-sm opacity">
                    <div v-if="!isDeleted" class="text-center">
                        <div class="spinner-border text-success" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                    <div v-else>
                        <div class="card-header bg-white p-0 border-0 mb-2 d-flex justify-content-between">
                            <h1 class="title fw-bold">Account verwijderen</h1>

                            <router-link to="/" class="text-dark text-decoration-none close-button">×</router-link>
                        </div>

                        <div class="card-body p-0">
                            <div class="text-center" v-if="errors.length == 0">
                                <img class="mb-4" :src="asset('images/approved.png')" alt="Goedgekeurd">

                                <h4 class="title">Uw account is succesvol verwijderd!</h4>
                            </div>

                            <div v-else>
                                <p class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                   v-if="errors.id" v-for="(name, index) in errors.id">
                                    <strong v-text="errors.id[index]"></strong>
                                </p>
                                <p class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                   v-if="errors.signature" v-for="(name, index) in errors.signature">
                                    <strong class="text-capitalize" v-text="errors.signature[index]"></strong>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ImageMixin from "../mixins/asset";

export default {
    name: "DeleteUser",

    mixins: [ImageMixin],

    data() {
        return {
            isDeleted: false,
            errors: []
        }
    },

    mounted() {
        axios.delete('/api/users/' + this.$route.params.id + '/' + this.$route.params.hash +
            '?expires=' + this.$route.query.expires + '&signature=' + this.$route.query.signature).then(() => {
            this.isDeleted = true;
            this.$root.authUser = false;
            this.$root.user = {};

            window.setTimeout(() => {
                this.$router.push({name: 'home'});
            }, 3000)
        }).catch((error) => {
            this.isDeleted = true;
            this.errors = error.response.data.errors
        });
    },

    methods: {

    },
}
</script>

<style scoped>

</style>
