<script>
export default {
    name: "council-edit",

    props: ['council', 'agreements'],

    data() {
        return {
            errors: [],
            councilUpdate: {},
            agreementsData: [],
        }
    },

    mounted() {
        this.councilUpdate = JSON.parse(this.council);
        this.agreementsData = JSON.parse(this.agreements);
    },

    methods: {
        update() {
            this.errors = [];

            axios.patch('/admin/councils/' + this.councilUpdate.id, {
                data: {
                    id: this.councilUpdate.id,
                    email: this.councilUpdate.email,
                    website: this.councilUpdate.website,
                    agreement_id: this.councilUpdate.agreement_id,
                    email_optout: this.councilUpdate.email_optout,
                }
            }).then(res => {
                if (res.status === 200) {
                    sessionStorage.setItem("alert_type", "success");
                    sessionStorage.setItem("message", this.$gettext("Council has been saved successfully"));

                    this.$root.$emit("show-flash-message");
                }
            }).catch(error => {
                this.errors = error.response.data.errors;
            })
        },
    }
}
</script>
