import Vue from "vue";
import Router from "vue-router";
import MyReports from "./components/MyReports";
import MyReport from "./components/MyReport";
import AddReport from "./components/AddReport";
import Report from "./components/Report";
import Login from "./components/Login";
import Register from "./components/Register";
import Settings from "./components/ProfileSettings";
import Home from "./components/Home";
import Applications from "./components/Apps";
import Contacts from "./components/Contacts";
import ResetPassword from "./components/ResetPassword";
import ResetPasswordConfirm from "./components/ResetPasswordConfirm";
import DeleteUser from "./components/DeleteUser";
import VerifyRegistration from "./components/VerifyRegistration";
import RateReport from "./components/RateReport";
import DeleteReport from "./components/DeleteReport";
import PublishReport from "./components/PublishReport";
import ReportPrint from "./components/ReportPrint";
import PrivacyPolicy from "./components/PrivacyPolicy";
import CookiePolicy from "./components/CookiePolicy";

Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        {
            path: "/",
            name: "home",
            component: Home
        },
        {
            path: "/nieuwe-melding",
            name: "reports.new",
            component: AddReport
        },
        {
            path: "/mijn-meldingen",
            name: "my-reports",
            component: MyReports
        },
        {
            path: "/mijn-meldingen/:id",
            name: "my-report",
            component: MyReport
        },
        {
            path: "/mijn-meldingen/:id/rate",
            name: "my-report-rate",
            component: RateReport
        },
        {
            path: "/api/auth/reports?paginated=true&per_page=10",
            name: "my_reports_data",
        },
        {
            path: "/melding/:id",
            name: "report",
            component: Report
        },
        {
            path: "/melding/print/:id",
            name: "report-print",
            component: ReportPrint
        },
        {
            path: "/login",
            name: "login",
            component: Login
        },
        {
            path: "/registreren",
            name: "register",
            component: Register
        },
        {
            path: "/mijn-verbeterdebuurt",
            name: "settings",
            component: Settings
        },
        {
            path: "/report-remarks",
            name: "report-remark",
        },
        {
            path: "/apps",
            name: "apps",
            component: Applications
        },
        {
            path: "/contacten",
            name: "contacts",
            component: Contacts
        },
        {
            path: "/wachtwoord",
            name: "reset-password",
            component: ResetPassword
        },
        {
            path: "/reset-password/:id",
            name: "reset-password-submit",
            component: ResetPasswordConfirm
        },
        {
            path: "/api/users/verify-email/:id/:hash",
            name: "verify-email",
            component: VerifyRegistration
        },
        {
            path: "/api/users/:id/:hash",
            name: "delete-user",
            component: DeleteUser
        },
        {
            path: "/api/reports/publish/:id/:hash",
            name: "publish-report",
            component: PublishReport
        },
        {
            path: "/api/reports/destroy/:id/:hash",
            name: "delete-report",
            component: DeleteReport
        },
        {
            path: "/privacybeleid",
            name: "privacy-policy",
            component: PrivacyPolicy
        },
        {
            path: "/cookie-beleid",
            name: "cookie-beleid",
            component: CookiePolicy
        },
    ]
});
