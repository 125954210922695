<script>
import AutocompleteMixin from "../../mixins/autocomplete";

export default {
    name: "user-profile-edit",

    props: ['isUpdate', 'submitEndpoint', 'backUrl', 'data','councils'],

    mixins: [
        AutocompleteMixin,
    ],

    data() {
        return {
            userData: {},
            errors: [],
        };
    },

    mounted() {
        this.userData = this.data ? JSON.parse(this.data) : {};
    },

    methods: {
        submit() {
            this.errors = [];

            axios.post(this.submitEndpoint, {
                'email': this.userData.email,
                'current_password': this.userData.current_password,
                'password': this.userData.password,
                'password_confirmation': this.userData.password_confirmation,
                'gender': this.userData.gender,
                'first_name': this.userData.first_name,
                'middle_name': this.userData.middle_name,
                'last_name': this.userData.last_name,
                'street': this.userData.street,
                'street_number': this.userData.street_number,
                'city': this.userData.city,
                'postcode': this.userData.postcode,
                'phone': this.userData.phone,
            }).then(res => {
                if (res.status === 200) {
                    sessionStorage.setItem("alert_type", "success");
                    sessionStorage.setItem("message", this.$gettext('Profile saved successfully'));

                    this.$root.$emit("show-flash-message");

                     window.location.reload();
                }
            }).catch(errors => {
                this.errors = errors.response.data.errors
                sessionStorage.setItem("alert_type", "danger");
                sessionStorage.setItem("message", this.$gettext('Something went wrong'));

                this.$root.$emit("show-flash-message");
            });
        }
    },
};
</script>

