<template>
    <div class="container">
        <div class="row mx-0">
            <div class="col-lg-7 col-md-10 col-12 mx-auto px-0">
                <div class="card my-3 p-4 shadow-sm opacity">
                    <div class="card-body p-md-3 p-2" v-show="showForm">
                        <div class="header d-flex justify-content-between px-1 py-2">
                            <h2 class="title">Mijn gegevens</h2>
                            <router-link to="/" class="text-secondary text-decoration-none close-button">×</router-link>
                        </div>

                        <form method="POST" @submit.prevent="update()">
                            <!-- First half -->
                            <div class="mb-3">
                                <div class="row">
                                    <div class="col-md-4" v-if="!user.is_social">
                                        <div class="form-group mb-4">
                                            <input type="password" class="form-control bg-transparent fw-bold shadow-none" name="password"
                                                   min="6" max="30" placeholder="Huidig wachtwoord" v-model="user.current_password"/>

                                            <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                                  v-if="errors.current_password" v-for="(name, index) in errors.current_password">
                                                <strong v-text="errors.current_password[index]"></strong>
                                            </span>
                                        </div>
                                    </div>

                                    <div :class="!user.is_social ? 'col-md-4' : 'col-md-6'">
                                        <div class="form-group mb-4">
                                            <input type="password" class="form-control bg-transparent fw-bold shadow-none" name="password"
                                                   min="6" max="30" placeholder="Nieuw wachtwoord" v-model="user.password"/>

                                            <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                                  v-if="errors.password" v-for="(name, index) in errors.password">
                                                    <strong v-text="errors.password[index]"></strong>
                                                </span>
                                        </div>
                                    </div>

                                    <div :class="user.is_social ? 'col-md-6' : 'col-md-4'">
                                        <div class="form-group mb-4">
                                            <input type="password" class="form-control bg-transparent fw-bold shadow-none" name="password_confirmation"
                                                   min="6" max="30" placeholder="Bevestig nieuw wachtwoord" v-model="user.password_confirmation"/>

                                            <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                                  v-if="errors.password_confirmation" v-for="(name, index) in errors.password_confirmation">
                                                    <strong v-text="errors.password_confirmation[index]"></strong>
                                                </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Second half -->
                            <div>
                                <h4 class="h2 px-1">Persoonsgegevens</h4>

                                <div>
                                    <div class="row mb-4">
                                        <div class="col-lg-2 col-md-6 mb-4 mb-lg-0">
                                            <select class="form-control bg-transparent fw-bold shadow-none"
                                                    v-model="user.gender" required>
                                                <option value="male">Dhr.</option>
                                                <option value="female">Mw.</option>
                                            </select>
                                        </div>

                                        <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">
                                            <input type="text" class="form-control bg-transparent fw-bold shadow-none"
                                                   name="first_name" placeholder="Voornaam" v-model="user.first_name" required/>

                                            <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                                  v-if="errors.first_name" v-for="(name, index) in errors.first_name">
                                                    <strong v-text="errors.first_name[index]"></strong>
                                                </span>
                                        </div>

                                        <div class="col-lg-2 col-md-6 mb-4 mb-lg-0">
                                            <input type="text" class="form-control bg-transparent fw-bold shadow-none"
                                                   name="middle_name" placeholder="Tussenvoegsel" v-model="user.middle_name"/>

                                            <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                                  v-if="errors.middle_name" v-for="(name, index) in errors.middle_name">
                                                    <strong v-text="errors.middle_name[index]"></strong>
                                                </span>
                                        </div>

                                        <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">
                                            <input type="text" class="form-control bg-transparent fw-bold shadow-none"
                                                   name="last_name" placeholder="Achternaam" v-model="user.last_name" required/>

                                            <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                                  v-if="errors.last_name" v-for="(name, index) in errors.last_name">
                                                    <strong v-text="errors.last_name[index]"></strong>
                                                </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mb-4">
                                    <div class="col-lg-3 col-md-6 mb-4 mb-lg-0">
                                        <input type="text" class="form-control bg-transparent fw-bold shadow-none"
                                               name="street" placeholder="Straat" v-model="user.street" required/>

                                        <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                              v-if="errors.street" v-for="(name, index) in errors.street">
                                                <strong v-text="errors.street[index]"></strong>
                                            </span>
                                    </div>

                                    <div class="col-lg-2 col-md-6 mb-4 mb-lg-0">
                                        <input type="text" class="form-control bg-transparent fw-bold shadow-none"
                                               name="street_number" placeholder="Huisnummer" v-model="user.street_number" required/>

                                        <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                              v-if="errors.street_number" v-for="(name, index) in errors.street_number">
                                                <strong v-text="errors.street_number[index]"></strong>
                                            </span>
                                    </div>

                                    <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">
                                        <input type="text" class="form-control bg-transparent fw-bold shadow-none"
                                               name="city" placeholder="Plaats" v-model="user.city" required/>

                                        <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                              v-if="errors.city" v-for="(name, index) in errors.city">
                                                <strong v-text="errors.city[index]"></strong>
                                            </span>
                                    </div>

                                    <div class="col-lg-3 col-md-6 mb-4 mb-lg-0">
                                        <input type="text" class="form-control bg-transparent fw-bold shadow-none"
                                               name="postcode" placeholder="Postcode" v-model="user.postcode" required/>

                                        <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                              v-if="errors.postcode" v-for="(name, index) in errors.postcode">
                                                <strong v-text="errors.postcode[index]"></strong>
                                            </span>
                                    </div>
                                </div>

                                <div class="form-group mb-4">
                                    <input type="text" class="form-control bg-transparent fw-bold shadow-none"
                                           name="phone" placeholder="Telefoonnummer (optioneel)" v-model="user.phone"/>

                                    <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                          v-if="errors.phone" v-for="(name, index) in errors.phone">
                                        <strong v-text="errors.phone[index]"></strong>
                                    </span>
                                </div>

                                <div v-if="!$root.user.is_verified" class="px-1 mb-2">
                                    <p class="text-danger mb-0">Verifieer je e-mailadress. Als je geen e-mail hebt ontvangen, klik dan
                                        <button type="button" @click="resendMail()"
                                                class="bg-transparent border-0 text-primary px-0"><u>hier</u></button>.
                                    </p>
                                </div>

                                <div v-if="confirmMail" class="px-1 mb-2">
                                    <p class="text-danger mb-0">{{ $gettext('Your email address is not verified.') }}</p>
                                </div>

                                <!-- Checkbox -->
                                <div class="form-check d-flex justify-content-between mb-4">
                                    <div class="d-flex">
                                        <input class="form-check-input" name="newsletter" type="checkbox" v-model="user.newsletter"/>
                                        <label class="form-check-label ms-1">Ik wil graag de nieuwsbrief ontvangen</label>
                                    </div>
                                    <div>
                                        <span class="delete-account" data-bs-toggle="modal"
                                              data-bs-target="#itemModal">Verwijder account</span>
                                    </div>
                                </div>
                            </div>

                            <!-- Submit button -->
                            <div class="sign-in-btn">
                                <!-- Submit button -->
                                <button type="submit" class="btn btn-success fw-bold text-white w-100">Opslaan</button>
                            </div>
                        </form>
                    </div>
                </div>
                <modal
                    :modaltitle="$gettext('Delete User')"
                    :modaltext="$gettext('Houd er rekening mee dat als u uw account verwijdert, u geen updates meer ontvangt voor uw meldingen.\n'+
                                                'Weet je zeker dat je je account wilt verwijderen?')"
                    :buttontext="$gettext('Delete')"
                ></modal>

                <div class="col-md-8 center position-sticky mx-auto">
                    <div class="card my-3 shadow-sm opacity" v-show="deleteUserSuccess">
                        <div class="card-body p-md-3 p-2 text-center">
                            <img class="mb-4" :src="asset('images/approved.png')" alt="Goedgekeurd">

                            <h4 class="title">De gebruiker is succesvol verwijderd.</h4>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
import ImageMixin from "../mixins/asset";
import ModalConfirmation from "./admin/ModalConfirmation";

export default {
    name: "settings",

    components: {ModalConfirmation},

    mixins: [ImageMixin],

    data() {
        return {
            showForm: true,
            showSpinner: false,
            confirmMail: false,
            deleteUserSuccess:false,
            user: {
                id: "",
                email: "",
                current_password: "",
                password: "",
                password_confirmation: "",
                gender: "male",
                first_name: "",
                middle_name: "",
                last_name: "",
                street: "",
                street_number: "",
                postcode: "",
                city: "",
                phone: "",
                terms: "",
                newsletter: 0,
            },
            url:'/',
            errors: []
        };
    },

    mounted() {
        self = this;

        if (this.$root.authUser) {
            this.user = JSON.parse(JSON.stringify(this.$root.user));
        } else {
            this.$root.$router.push({name: 'login'});
        }

        this.$root.$on("modal-confirmed", function () {
            self.deleteUser(this.user.id);
        });
    },

    methods: {
        update() {
            this.errors = [];
            this.confirmMail = false;

            axios.patch("/api/users/" + this.user.id, this.user).then((response) => {
                if (response.status == 200) {
                    sessionStorage.setItem("message", "Je account is succesvol bijgewerkt");
                    sessionStorage.setItem("alert_type", "success");

                    this.$root.user = response.data.data;

                    this.$root.$emit("show-flash-message");
                }
            }).catch((errors) => {
                if (errors.response.data.errors) this.errors = errors.response.data.errors;

                if (errors.response.data.message === 'Your email address is not verified.') this.confirmMail = true;
            });
        },

        resendMail() {
            axios.post('/verify-email-resend').then(() => {
            })
        },
        deleteUser(id) {

                axios.delete('/api/auth/delete').then(() => {
                    this.$root.authUser = false;
                    this.$root.user = {};
                    axios.post('logout');
                }).catch((error) => {
                    this.errors = error.response.data.errors
                });

                this.showForm = false;
                this.deleteUserSuccess = true;

                window.setTimeout(() => {
                    this.$router.push({name: 'home'});
                }, 3000)

        }
    },
};
</script>

<style scoped>
.opacity {
    opacity: .9;
}

.form-control {
    border: none;
    border-bottom: 1px solid #d3d3d3;
    height: 50px;
    font-size: 16px;
}

.sign-in-btn button {
    font-size: 25px;
}

.delete-account {
    color:red;
}

.delete-account:hover {
    cursor:pointer;
}
</style>
