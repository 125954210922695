<template>
    <div>
        <div class="row mx-0">
            <div class="col-md-10 col-12 mx-auto">
                <div class="card my-3 p-4 shadow-sm opacity">
                    <div class="card-header bg-white p-0 border-0 d-flex justify-content-between">
                        <h1 class="title fw-bold">Mijn meldingen</h1>

                        <router-link to="/" class="text-secondary text-decoration-none close-button">×</router-link>
                    </div>

                    <div v-if="loader" class="text-center">
                        <div class="spinner-grow" style="width: 5rem; height: 5rem;" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>

                    <div class="card-body p-0" v-else>
                        <div>
                            <div class="d-flex flex-column flex-lg-row align-items-center">
                                <select name="per_page" id="per_page" class="form-control w-md-auto w-100 mb-2 me-md-2"
                                        v-model="filter.length" @change="getData(1)">
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>

                                <select name="type" id="type" class="form-control w-md-auto w-100 mb-2 me-md-2"
                                        v-model="filter.type" @change="getData(1)">
                                    <option value="">Alle typen</option>
                                    <option value="problem">Probleem</option>
                                    <option value="idea">Idee</option>
                                </select>

                                <v-autocomplete dense auto-select-first clearable filled solo ref="category"
                                                hide-selected v-model="filter.category" :items="categories"
                                                color="white" item-text="name" item-value="id" return-object
                                                class="form-control me-md-2" placeholder="Alle categorieën"
                                                @change="hideMenu('category'); getData(pagination.current_page)"
                                                @input="showMenu('category')" @mouseup="showMenu('category')"
                                                @click:append-outer="toggleMenu('category')">
                                </v-autocomplete>

                                <select name="status" id="status" class="form-control w-md-auto w-100 me-md-2 mb-2"
                                        v-model="filter.status" @change="getData(1)">
                                    <option value="">Alle statussen</option>
                                    <option v-for="(status, index) in statuses" :key="index"
                                            v-text="$gettext(status)" :value="index"></option>
                                </select>

                                <input class="form-control me-md-2 mb-2 w-md-auto w-100" type="text"
                                       v-model="filter.search" @input="debounceInput" placeholder="Zoeken...">

                                <button class="btn bg-danger mb-2 me-2 text-white clear-filter" @click="clearFilters()">Wis filters</button>
                            </div>

                            <div class="mb-3 mx-0 rounded table-responsive" v-if="pagination.total">
                                <table class="table table-hover table-striped mb-3">
                                    <thead>
                                    <tr>
                                        <th>
                                            <span>Onderwerp</span>
                                            <i v-if="filter.column == 'title' && filter.dir == 'asc'"
                                               @click="updateSort('title', 'desc')" class="sort-icon-up fas fa-sort-up cursor-pointer"></i>
                                            <i v-if="(filter.column == 'title' && filter.dir == 'desc') || filter.column !== 'title'"
                                               @click="updateSort('title', 'asc')" class="sort-icon-down fas fa-sort-down cursor-pointer"></i>
                                        </th>
                                        <th>
                                            <span>Plaats</span>
                                            <i v-if="filter.column == 'city' && filter.dir == 'asc'"
                                               @click="updateSort('city', 'desc')" class="sort-icon-up fas fa-sort-up cursor-pointer"></i>
                                            <i v-if="(filter.column == 'city' && filter.dir == 'desc') || filter.column !== 'city'"
                                               @click="updateSort('city', 'asc')" class="sort-icon-down fas fa-sort-down cursor-pointer"></i>
                                        </th>
                                        <th>
                                            <span>Categorie</span>
                                            <i v-if="filter.column == 'category' && filter.dir == 'asc'"
                                               @click="updateSort('category', 'desc')" class="sort-icon-up fas fa-sort-up cursor-pointer"></i>
                                            <i v-if="(filter.column == 'category' && filter.dir == 'desc') || filter.column !== 'category'"
                                               @click="updateSort('category', 'asc')" class="sort-icon-down fas fa-sort-down cursor-pointer"></i>
                                        </th>
                                        <th>
                                            <span>Datum</span>
                                            <i v-if="filter.column == 'date' && filter.dir == 'asc'"
                                               @click="updateSort('date', 'desc')" class="sort-icon-up fas fa-sort-up cursor-pointer"></i>
                                            <i v-if="(filter.column == 'date' && filter.dir == 'desc') || filter.column !== 'date'"
                                               @click="updateSort('date', 'asc')" class="sort-icon-down fas fa-sort-down cursor-pointer"></i>
                                        </th>
                                        <th>
                                            <span>Status</span>
                                            <i v-if="filter.column == 'status' && filter.dir == 'asc'"
                                               @click="updateSort('status', 'desc')" class="sort-icon-up fas fa-sort-up cursor-pointer"></i>
                                            <i v-if="(filter.column == 'status' && filter.dir == 'desc') || filter.column !== 'status'"
                                               @click="updateSort('status', 'asc')" class="sort-icon-down fas fa-sort-down cursor-pointer"></i>
                                        </th>
                                        <th>Beoordeling</th>
                                        <th>Acties</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="row in data">
                                        <td>
                                            <router-link :to="'/mijn-meldingen/' + row.id" v-text="row.title"
                                                         class="text-decoration-none text-dark"></router-link>
                                        </td>
                                        <td>
                                            <span v-if="row.city" v-text="row.city.name"></span>
                                            <span v-else>-</span>
                                        </td>
                                        <td>
                                            <span v-if="row.category" v-text="row.category.name"></span>
                                            <span v-else>-</span>
                                        </td>
                                        <td v-text="new Date(row.created_at).toLocaleString('nl-NL')"></td>
                                        <td v-text="$gettext(row.status_formatted)"></td>
                                        <td>
                                            <div v-if="row.is_fixed">
                                                <div class="d-flex" v-if="row.rating">
                                                    <i class="fa-solid fa-star text-success" v-for="n in parseInt(row.rating)"></i>
                                                </div>
                                            </div>
                                            <p v-else>-</p>
                                        </td>
                                        <td>
                                            <div class="d-flex">
                                                <router-link :to="'/mijn-meldingen/' + row.id"
                                                             class="btn btn-success text-decoration-none text-white me-1">Bekijken</router-link>
                                                <button class="btn btn-warning me-1" data-bs-toggle="modal" v-if="!row.is_fixed && !row.deleted_at"
                                                        data-bs-target="#closeReportModal" @click="passData(row.id)">Afsluiten</button>
                                                <button v-if="!row.deleted_at" class="btn btn-danger text-white"
                                                        data-bs-toggle="modal" data-bs-target="#deleteReportModal"
                                                        @click="passData(row.id)">Verwijderen</button>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>

                                <div>
                                    <h5 class="title">
                                        <span>Van</span>
                                        <span v-text="pagination.from"></span>
                                        <span>tot</span>
                                        <span v-text="pagination.to"></span>
                                        <span>van totaal</span>
                                        <span v-text="pagination.total"></span>
                                    </h5>
                                </div>
                            </div>

                            <div class="text-center" v-else>
                                <h5 class="title">
                                    Geen resultaten gevonden
                                </h5>
                            </div>

                            <div v-if="pagination.total">
                                <nav class="d-flex justify-content-center">
                                    <ul class="pagination mb-0">
                                        <li v-if="pagination.current_page > 1" class="page-item">
                                            <a href="javascript:void(0)" aria-label="Previous"
                                               @click="changePage(pagination.current_page - 1)" class="page-link">
                                                <span aria-hidden="true">« Vorig</span>
                                            </a>
                                        </li>
                                        <li v-for="page in pagesNumber"
                                            :class="{active: page == pagination.current_page}" class="page-item">
                                            <a href="javascript:void(0)" @click="changePage(page)" v-text="page"
                                               class="page-link"></a>
                                        </li>
                                        <li v-if="pagination.current_page < pagination.last_page" class="page-item">
                                            <a href="javascript:void(0)" aria-label="Next"
                                               @click="changePage(pagination.current_page + 1)" class="page-link">
                                                <span aria-hidden="true">Volgende »</span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="closeReportModal" tabindex="-1" aria-labelledby="closeReportModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="closeReportModalLabel">Melding afsluiten</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form method="POST">
                            <div>
                                <label class="rating">Waardering:</label>
                                <AwesomeVueStarRating
                                    ref="reportRating"
                                    :star="rating"
                                    :disabled="disabled"
                                    :maxstars="maxstars"
                                    :starsize="starsize"
                                    :hasresults="hasresults"
                                    :hasdescription="hasdescription"
                                    :ratingdescription="ratingdescription"/>
                            </div>

                            <div class="form-group">
                                <label for="feedback" class="col-form-label">Feedback:</label>
                                <textarea class="form-control" name="feedback" id="" rows="5" cols="30" v-model="feedback"></textarea>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer d-flex justify-content-between">
                        <button type="button" class="btn btn-danger text-white" data-bs-dismiss="modal">Annuleren</button>
                        <button type="button" class="btn btn-success text-white" @click="closeReport()">Afsluiten</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="deleteReportModal" tabindex="-1" aria-labelledby="deleteReportModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="deleteReportModalLabel">Melding verwijderen</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        Weet je zeker dat je deze melding wil verwijderen?
                    </div>
                    <div class="modal-footer d-flex justify-content-between">
                        <button type="button" class="btn btn-danger text-white" data-bs-dismiss="modal">Annuleren</button>
                        <button type="button" class="btn btn-success text-white" @click="deleteReport()">Verwijderen</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ImageMixin from "../mixins/asset";
import AutocompleteMixin from "../mixins/autocomplete";
import AwesomeVueStarRating from "awesome-vue-star-rating";
import {debounce} from "lodash";

export default {
    name: "my-reports",

    mixins: [
        ImageMixin,
        AutocompleteMixin,
    ],

    components: {
        AwesomeVueStarRating,
    },

    props: {
        eachSide: {
            type: Number,
            default: 3,
        },
    },

    data() {
        return {
            data: {},
            pagination: {},
            rating: 5, // default star
            ratingdescription: [
                {
                    text: "Slecht",
                    class: "star-poor",
                },
                {
                    text: "Onder het gemiddelde",
                    class: "star-belowAverage",
                },
                {
                    text: "Gemiddeld",
                    class: "star-average",
                },
                {
                    text: "Mooi zo",
                    class: "star-good",
                },
                {
                    text: "Uitstekend",
                    class: "star-excellent",
                },
            ],
            hasresults: false,
            hasdescription: false,
            starsize: "lg", //[xs,lg,1x,2x,3x,4x,5x,6x,7x,8x,9x,10x],
            maxstars: 5,
            disabled: false,
            feedback: "",
            currentReport: null,
            filter: {
                length: 10,
                type: '',
                category: '',
                status: '',
                search: '',
                column: 'created_at',
                dir: 'desc',
            },
            statuses: [],
            categories: [],
            loader: true,
        };
    },

    computed: {
        pagesNumber() {
            let pagesArray = [];
            let firstPage = 1;
            let lastPage = this.pagination.last_page;

            if (this.eachSide !== "") {
                if (this.pagination.current_page - this.eachSide > firstPage)
                    firstPage = this.pagination.current_page - this.eachSide;

                if (this.eachSide + this.pagination.current_page < lastPage)
                    lastPage = this.eachSide + this.pagination.current_page;
            }

            if (firstPage !== 1) {
                pagesArray.push(1);
            }

            for (let page = firstPage; page <= lastPage; page++) {
                pagesArray.push(page);
            }

            if (lastPage !== this.pagination.last_page) {
                pagesArray.push(this.pagination.last_page);
            }

            return pagesArray;
        },
    },

    mounted() {
        if (this.$root.authUser) {
            if (!this.$root.user.is_verified) this.$router.push({name: 'settings'})

            this.getData(1);
            this.getStatuses();
            this.getCategories();
        } else {
            this.$root.$router.push({name: "login"});
        }
    },

    methods: {
        getData(page = 1) {
            this.loader = true;

            axios.get('/api/auth/reports?paginated=true', {
                params: {
                    page: page,
                    length: this.filter.length,
                    type: this.filter.type,
                    category: this.filter.category ? this.filter.category.id : '',
                    status: this.filter.status,
                    search: this.filter.search,
                    column: this.filter.column,
                    dir: this.filter.dir,
                },
            }).then(res => {
                this.$root.authUser = true;
                this.pagination = res.data
                this.data = res.data.data
                this.loader = false;
            }).catch(error => {
                if (error.response.data.errors && error.response.data.errors.column) {
                    alert(error.response.data.errors.column[0])

                    this.filter.column = 'date';
                    this.filter.dir = 'desc';

                    this.getData(1);
                }
            })
        },

        getStatuses() {
            axios.get('/api/statuses').then(res => {
                this.statuses = res.data.data;
            })
        },

        getCategories() {
            axios.get('/api/report-categories').then(res => {
                this.categories = res.data.data;
            })
        },

        getCategory(event) {
            this.filter.category = event.id;

            this.getData(1);
        },

        clearFilters() {
            this.filter = {
                length: 10,
                type: '',
                category: '',
                status: '',
                search: '',
                column: 'created_at',
                dir: 'desc',
            }

            this.getData(1);
        },

        changePage(page) {
            this.pagination.current_page = page;

            this.getData(this.pagination.current_page);
        },

        debounceInput: debounce(function (e) {
            this.getData(1)
        }, 500),

        passData(id) {
            this.currentReport = id;
            this.$refs.reportRating.$children[0].$data.stars = 5;
            this.rating = 5;
            this.feedback = "";
        },

        updateSort(sort, sortdir) {
            this.filter.column = sort;
            this.filter.dir = sortdir;

            this.getData(1)
        },

        closeReport() {
            axios.patch("/api/reports/" + this.currentReport, {
                is_fixed: 1,
                rating: this.$refs.reportRating.$children[0].$data.stars,
                feedback: this.feedback,
            }).then(() => {

                this.currentReport = null;
                this.rating = 5;
                this.feedback = "";

                $("#closeReportModal").modal("toggle");

                this.getData(1);
            });
        },

        deleteReport() {
            axios.delete("/api/reports/" + this.currentReport).then(() => {

                this.currentReport = null;
                this.rating = 5;
                this.feedback = "";

                $("#deleteReportModal").modal("toggle");

                this.getData(1);
            });
        },

        // Capitalize string
        capitalize(sentence) {
            return sentence.charAt(0).toUpperCase() + sentence.slice(1);
        },
    },
};
</script>

<style scoped>

.list span {
    width: 160px !important;
}

.opacity {
    opacity: 0.96;
    padding: 20px;
}

table {
    border-collapse: collapse;
}

table td,
table th {
    border: 2px solid rgb(192, 192, 192);
}

table tr:first-child th {
    border-top: 0;
}

table tr:last-child td {
    border-bottom: 0;
}

table tr td,
table tr th {
    border-left: 0;
}

table tr td,
table tr th {
    border-right: 0;
}

table tr td {
    font-size: 18px;
    padding: 10px;
}

select.form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 5px;
    padding: 0.375rem 1.2rem 0.375rem 0.75rem;
}

.clear-filter {
    min-width: 90px;
}
</style>
