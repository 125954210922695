<template>
    <div class="container">
        <div class="row mx-0">
            <div class="col-lg-4 col-12 mx-auto px-0">
                <div class="card my-3 p-4 shadow-sm opacity border-0 shadow-none" id="printMe" v-if="!errorMessage">
                    <div>
                        <p>
                            <span v-html="report.type == 'problem' ? 'Probleem:' : 'Idee:'" ref="type"></span>
                            <span v-html="report.title"></span>
                        </p>

                        <p>
                            <span>Melding ID:</span>
                            <span v-html="report.id"></span>
                        </p>

                        <p v-if="report.city">
                            <span>Plaats:</span>
                            <span v-html="report.city.name"></span>
                        </p>

                        <p v-if="report.address">
                            <span>Adres:</span>
                            <span v-html="report.address"></span>
                        </p>

                        <p v-if="report.postcode">
                            <span>Postcode:</span>
                            <span v-html="report.postcode"></span>
                        </p>

                        <p>
                            <span>Datum:</span>
                            <span v-html="report.create_date_formatted"></span>
                        </p>

                        <p v-if="report.type == 'problem'">
                            <span>Categorie:</span>
                            <span v-html="report.category.name"></span>
                        </p>

                        <p v-if="report.status">
                            <span>Status:</span>
                            <span v-html="report.status_formatted"></span>
                        </p>

                        <p v-if="report.assignee">
                            <span>Verantwoordelijke:</span>
                            <span v-text="report.assignee.full_name + ' (' + report.assignee.email + ')'"></span>
                        </p>

                        <p v-else>
                            <span>Verantwoordelijke:</span>
                            <span v-text="'Gemeente ' + report.city.council.name + ' (' + report.city.council.email + ')'"></span>
                        </p>

                        <div class="text-left me-auto">
                            <div class="mb-3">
                                <svg width="10cm" height="10cm" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink= "http://www.w3.org/1999/xlink">
                                    <image class="w-100" :href="'https://maps.googleapis.com/maps/api/staticmap?center=' +
                             report.latitude + ',' + report.longitude + '&markers=' + report.latitude + ',' + report.longitude +
                             '&zoom=15&size=350x350&key=' + $root.mapsKey" x="0" y="0" height="380px" width="380px"/> </svg>
                            </div>
                        </div>

                        <p>
                            <span>Link:</span>
                            <router-link :to="'/melding/' + report.id" v-text="window + '/melding/' + report.id" target= '_blank'></router-link>
                        </p>

                        <p class="description d-block">
                            <span>Omschrijving:</span>
                            <span v-html="report.description"></span>
                        </p>

                        <div class="page-break"></div>

                        <div class="images" v-if="report.urls && report.urls.length > 0">
                            <img :src="image" :alt="report.title" v-for="image in report.urls" class="mb-2"
                                 height="200px">
                        </div>

                        <button class="d-none" id="printButton" v-print="printObj">print</button>
                    </div>
                </div>
                <div class="card my-3 p-4 shadow-sm opacity border-0 shadow-none" v-else>
                    <h4 class="mb-0 text-danger text-center" v-text="errorMessage"></h4>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import print from 'vue-print-nb'

export default {
    name: "report-print",

    data() {
        return {
            report: {},
            window: '',
            errorMessage: false,
            printObj: {
                id: "printMe",
                popTitle: 'Verbetedebuurt',
                extraHead: '<meta http-equiv="Content-Language" content="nl-nl"/>',
            }
        }
    },

    directives: {
        print
    },

    mounted() {
        this.window = window.location.origin

        this.getReport(this.$route.params.id);
    },

    methods: {
        getReport(id) {
            axios.get("/api/reports/" + id, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                }
            }).then((response) => {
                this.report = response.data.data;
                this.output = response.data.data;

                if (this.report.urls.length > 0) {
                    const images = this.report.urls.map(imageSrc => {
                        return new Promise((resolve, reject) => {
                            const img = new Image();
                            img.src = imageSrc;
                            img.onload = resolve;
                            img.onerror = reject;
                        });
                    });

                    Promise.all(images).then(() => {
                        jQuery('#printButton').click()
                    }).catch(error => {});
                } else {
                    setTimeout(() => {
                        jQuery('#printButton').click()
                    }, 500)
                }
            }).catch(error => {
                this.errorMessage = error.response.data.errors.id[0];
            });
        },
    }
}
</script>

<style scoped>
.images img {
    width: 100%;
    object-fit: contain;
}

.page-break {
    clear: both;
    page-break-after: always;
    display: block;
}

@media print {
    .images {
        page-break-before: always;
    }
}
</style>
