<template>
    <b-modal ok-variant="ok" @ok="" id="front-page-modal" :title="modal.title" ok-only>
        <p class="my-4" v-text="modal.body"></p>
    </b-modal>
</template>

<script>
export default {
    props: ['session_modal_message'],

    data() {
        return {
            modal: {
                body: "",
                title: "",
            }
        }
    },

    mounted() {
        this.modalTrigger();
    },

    computed: {
        modal_message() {
            return JSON.parse(this.session_modal_message);
        },
    },

    methods: {
        modalTrigger() {
            if (Object.keys(this.modal_message).length > 0) {
                this.modal.body = this.modal_message.body;
                this.modal.title = this.modal_message.title;
                this.$bvModal.show("front-page-modal");
            }
        },
    }
}
</script>

<style>
 .btn-ok {
     background:#3ab53a;
     color:white;
 }

 .btn-ok:hover{
     background:#65d365;
     color:white;
 }
</style>
