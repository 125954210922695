<script>
import AutocompleteMixin from "../../mixins/autocomplete";
export default {
    name: "mail-templates-crud",

    props: [
        'submitEndpoint',
        'backUrl',
        'data',
        'variables',
        'councils',
        'roles',
        'mailTypes'
    ],

    mixins: [
        AutocompleteMixin
    ],

    data() {
        return {
            mailTemplates: [],
            mailTemplate: {
                subject: '',
                description: '',
                text: '',
                council_id: '',
                recipient_role: '',
                mail_type: '',
                report_type: '',
                is_active: true,
            },
            council: {},
            errors: [],
            editorConfig: {
                readOnly: false,
            },
        };
    },

    mounted() {
        this.editorConfig.readOnly = this.$root.user.roles[0].name !== 'super admin';

        let types = JSON.parse(this.mailTypes);

        Object.entries(types).forEach((b) => {
            this.mailTemplates.push({
                id: b[0],
                name: b[1],
            });
        });

        let data = this.data ? JSON.parse(this.data) : null;

        this.mailTemplate = this.data ? data : {
            id: '',
            subject: '',
            description: '',
            text: '',
            council_id: '',
            recipient_role: '',
            mail_type: '',
            report_type: '',
            is_active: true,
        };

        if (this.mailTemplate.report_type == null) this.mailTemplate.report_type = '';
    },

    methods: {
        addVariable(variable) {
            this.mailTemplate.text += variable;
        },

        submit() {
            this.errors = [];

            axios.post(this.submitEndpoint, {
                'id': this.mailTemplate.id,
                'subject': this.mailTemplate.subject,
                'description': this.mailTemplate.description,
                'text': this.mailTemplate.text,
                'council_id': this.mailTemplate.council_id,
                'recipient_role': this.mailTemplate.recipient_role,
                'mail_type': this.mailTemplate.mail_type,
                'report_type': this.mailTemplate.report_type,
                'is_active': this.mailTemplate.is_active,
            }).then(res => {
                if (res.status === 200) {
                    sessionStorage.setItem("alert_type", "success");
                    sessionStorage.setItem("message", this.$gettext("Mail Template saved successfully"));

                    this.$root.$emit("show-flash-message");

                    window.location.href = this.backUrl;
                }
            }).catch(errors => {
                this.errors = errors.response.data.errors

                sessionStorage.setItem("alert_type", "danger");
                sessionStorage.setItem("message", this.$gettext("Something went wrong"));

                this.$root.$emit("show-flash-message");
            });
        },
    },
};
</script>
