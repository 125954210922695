<script>
import AutocompleteMixin from "../../mixins/autocomplete";
export default {
    name: "category-create",

    mixins: [
        AutocompleteMixin
    ],

    props: ['back_url'],

    data() {
        return {
            errors: [],
            categoryCreate: {
                name: '',
                parent_id: '',
                selected_councils: [],
                is_attached: true,
            },
            categories: [],
            councils: [],
        }
    },

    mounted() {
        this.getCategories();
        this.getCouncils();
    },

    methods: {
        store() {
            this.errors = [];

            axios.post('/admin/categories/create', {
                name: this.categoryCreate.name,
                parent_id: this.categoryCreate.parent_id,
                councils: this.categoryCreate.selected_councils,
                is_attached: this.categoryCreate.is_attached,
            }).then(res => {
                if (res.status == 201 || res.status == 200) {
                    sessionStorage.setItem("message", "Category has been successfully created");
                    sessionStorage.setItem("alert_type", "success");

                    window.location.href = this.back_url;
                }
            }).catch(error => {
                this.errors = error.response.data.errors
            })
        },

        getCategories() {
            axios.get('/api/report-categories').then(res => {
                this.categories = res.data.data.filter(el => el.parent_id == null);
            })
        },

        getCouncils() {
            axios.get('/admin/categories/councils').then(res => {
                this.councils = res.data.data;
            })
        },
    }
}
</script>
