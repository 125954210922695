<script>
import AutocompleteMixin from "../../mixins/autocomplete";

export default {
    name: "standard-responses-crud",

    props: [
        'isUpdate',
        'submitEndpoint',
        'backUrl',
        'data',
        'variables',
        'councils',
        'id'
    ],

    mixins: [
        AutocompleteMixin,
    ],

    data() {
        return {
            standardResponse: {},
            errors: [],
        };
    },

    mounted() {
        this.standardResponse = this.data ? JSON.parse(this.data) : {
            title: '',
            text: '',
            council_id: null,
            is_active: true,
        };

        if (this.id !== '' && !this.isUpdate) this.$refs.noResponse.classList.remove('d-none');
    },

    methods: {
        addVariable(variable) {
            this.standardResponse.text += variable;
        },

        submit() {
            this.errors = [];

            axios.post(this.submitEndpoint, {
                'id': this.standardResponse.id,
                'title': this.standardResponse.title,
                'text': this.standardResponse.text,
                'council_id': this.standardResponse.council_id,
                'is_active': this.standardResponse.is_active,
            }).then(res => {
                if (res.status === 200) {
                    sessionStorage.setItem("alert_type", "success");
                    sessionStorage.setItem("message", this.$gettext('Standard Response saved successfully'));

                    this.$root.$emit("show-flash-message");

                    window.location.href = this.backUrl;
                }
            }).catch(errors => {
                this.errors = errors.response.data.errors

                sessionStorage.setItem("alert_type", "danger");
                sessionStorage.setItem("message", this.$gettext('Something went wrong'));

                this.$root.$emit("show-flash-message");
            });
        }
    },
};
</script>
