<template>
    <div class="container">
        <div class="row mx-0">
            <div class="col-lg-6 col-md-10 mx-auto px-0">
                <div class="card my-3 p-4 shadow-sm opacity">
                    <div class="card-header bg-white p-0 border-0 mb-2 d-flex justify-content-between">
                        <h1 class="title fw-bold">Bevestig wachtwoord</h1>

                        <router-link to="/" class="text-dark text-decoration-none close-button">×</router-link>
                    </div>

                    <div class="card-body p-0">
                        <form @submit.prevent="sendEmail()" v-if="!loader">
                            <div class="form-group mb-3">
                                <input type="email" name="email" id="email"
                                       class="form-control bg-transparent fw-bold shadow-none"
                                       placeholder="E-mailadres" required v-model="email">

                                <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                      v-if="errors.email" v-for="(name, index) in errors.email">
                                    <strong v-text="errors.email[index]"></strong>
                                </span>
                            </div>

                            <div class="form-group mb-3">
                                <input type="password" name="password" id="password"
                                       class="form-control bg-transparent fw-bold shadow-none"
                                       placeholder="Wachtwoord" required v-model="password">

                                <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                      v-if="errors.password" v-for="(name, index) in errors.password">
                                    <strong v-text="errors.password[index]"></strong>
                                </span>
                            </div>

                            <div class="form-group mb-3">
                                <input type="password" name="password_confirmation" id="password_confirmation"
                                       class="form-control bg-transparent fw-bold shadow-none"
                                       placeholder="Wachtwoord herhalen" required v-model="password_confirmation">

                                <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                      v-if="errors.password_confirmation" v-for="(name, index) in errors.password_confirmation">
                                    <strong v-text="errors.password_confirmation[index]"></strong>
                                </span>
                            </div>
                            <div class="form-group mb-3" v-if="errors.token" >
                                <span class="alert-danger bg-transparent mt-1 mb-3 text-danger" role="alert" v-for="(name, index) in errors.token">
                                    <strong v-text="errors.token[index]"></strong>
                                </span>
                            </div>

                            <div class="d-flex justify-content-center sign-in-btn">
                                <!-- Submit button -->
                                <button type="submit" class="btn btn-success fw-bold text-white w-100">Opslaan</button>
                            </div>
                        </form>

                        <div v-else class="text-center">
                            <div class="spinner-border text-success" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ImageMixin from "../mixins/asset";

export default {
    name: "ResetPasswordForm",

    mixins: [ImageMixin],

    data() {
        return {
            email: '',
            password: '',
            password_confirmation: '',
            token: null,
            errors: [],
            loader: false,
        }
    },

    mounted() {
        this.email = this.$route.query.email;
        this.token = this.$route.params;
    },

    methods: {
        sendEmail() {
            axios.post('/reset-password', {
                email: this.email,
                password: this.password,
                password_confirmation: this.password_confirmation,
                token: this.token.id,
            }).then(res => {
                if (res.status == 200) {
                    this.$root.authUser = true;
                    this.$root.user = res.data.data;

                    this.$router.push({name: 'settings'})
                }
            }).catch((error) => {
                this.errors = error.response.data.errors
            });
        }
    },
}
</script>

<style scoped>
.form-control {
    border: none;
    border-bottom: 1px solid #d3d3d3;
    height: 50px;
    font-size: 16px;
}

.sign-in-btn button {
    font-size: 25px;
}
</style>
