<script>
export default {
    name: "city-edit",

    props: ['city', 'councils'],

    data() {
        return {
            errors: [],
            cityUpdate: {},
            councilsData: [],
        }
    },

    mounted() {
        this.cityUpdate = JSON.parse(this.city);
        this.councilsData = JSON.parse(this.councils);
    },

    methods: {
        update() {
            this.errors = [];

            axios.patch('/admin/cities/' + this.cityUpdate.id, {
                data: {
                    id: this.cityUpdate.id,
                    council_id: this.cityUpdate.council_id
                }
            }).then(res => {
                if (res.status == 200) {
                    this.cityUpdate.state_code = res.data.data.state_code;
                }
            }).catch(error => {
                this.errors = error.response.data.errors;
            })
        },
    }
}
</script>
