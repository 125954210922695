<template>
    <div class="row mx-auto">
        <div class="col-md-8 col-12 mx-auto">

            <h1>{{ $gettext('Contact forms') }}</h1>

            <div id="table-wrapper">
                <data-table
                    order-by="id"
                    order-dir="desc"
                    :columns="columns"
                    :per-page="['10', '25', '50', '100']"
                    :url="url"
                    :filters="filters"
                    ref="contactsTable"
                    :debounce-delay="500"
                >
                    <div slot="filters" slot-scope="{ tableData, perPage }">
                        <div class="row mb-2">
                            <div class="col-md-4">
                                <select class="form-control w-auto me-md-2 me-0 form-input-height" v-model="filters.length">
                                    <option :key="page" v-for="page in perPage">{{ page }}</option>
                                </select>
                            </div>
                            <div class="col-md-8 d-flex flex-column flex-md-row justify-content-end">
                                <input name="search" id="search" :placeholder="$gettext('Search contact forms...')"
                                       class="form-control w-auto form-input-height" v-model="filters.search">
                            </div>
                        </div>
                    </div>
                    <tbody slot="body" slot-scope="{ data }">
                    <tr :key="item.id" v-for="item in data">
                        <td :key="column.name" v-for="column in columns">
                            <data-table-cell
                                :value="item"
                                :name="column.name"
                                :meta="column.meta"
                                :comp="column.component"
                                :classes="column.classes"
                            >
                            </data-table-cell>
                        </td>
                    </tr>
                    </tbody>
                </data-table>
            </div>
        </div>
    </div>
</template>

<script>
import { DataTable } from "laravel-vue-datatable";

export default {
    name: "contact-forms-table",

    props: ["base_url", "current_url"],

    components: {
        DataTable,
    },

    data() {
        return {
            url: "",
            filters: {
                length: 10,
                search: "",
            },
            perPage: ['10', '25', '50', '100'],
            columns: [
                {
                    label: this.$gettext("E-mail"),
                    name: "email",
                    orderable: true,
                },
                {
                    label: this.$gettext("Subject"),
                    name: "subject",
                    orderable: true,
                },
                {
                    label: this.$gettext("Message"),
                    name: "message",
                    orderable: true,
                },
                {
                    label: this.$gettext("Date created"),
                    name: "created_date_formatted",
                    columnName: "created_at",
                    orderable: true,
                },
            ],
        };
    },

    mounted() {
        this.url = this.base_url + "/admin/contact-forms-datatable";
    },
};
</script>
