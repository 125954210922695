<script>
import GrantReportToUser from "../GrantReportToUser";
import CouncilEntryNumberDialog from "./CouncilEntryNumberDialog";
import RejectReportDialog from "../RejectReportDialog";
import HandleReport from "../HandleReport.vue";

export default {
    name: "status-buttons",

    components: {
        CouncilEntryNumberDialog,
        RejectReportDialog,
        GrantReportToUser,
        HandleReport,
    },

    props: {
        report_id: {
            type: Number,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
        user_id: {
            type: String,
            required: true,
        },
        user_name: {
            type: String,
            required: true,
        },
        statuses: {
            type: Object,
            required: true,
        },
        current_status: {
            type: String,
            required: true,
        }
    },

    data() {
        return {
            buttons: {
                behandeling: false,
                bevestigen: false,
                toekenned: false,
                accepteren: false,
                afhandelen: false,
                open: false,
                sluiten: false,
                verwijderen: false,
                afwijzen: false
            },
            selected_status: '',
        }
    },

    created() {
        this.selected_status = this.current_status;
    },

    mounted() {
        let self = this;

        switch (this.current_status) {
            case 'unconfirmed':
                this.buttons.bevestigen = true;
                this.buttons.verwijderen = true;
                break;
            case 'published':
                // this.buttons.open = true;
                this.buttons.sluiten = true;
                this.buttons.verwijderen = true;
                break;
            case 'offered':
                this.buttons.accepteren = true;
                this.buttons.behandeling = true;
                this.buttons.toekenned = true;
                this.buttons.afhandelen = true;
                this.buttons.sluiten = true;
                this.buttons.verwijderen = true;
                this.buttons.afwijzen = true;
                break;
            case 'registered':
                this.buttons.behandeling = true;
                this.buttons.toekenned = true;
                this.buttons.afhandelen = true;
                this.buttons.sluiten = true;
                this.buttons.verwijderen = true;
                this.buttons.afwijzen = true;
                break;
            case 'in_treatment':
                this.buttons.toekenned = true;
                this.buttons.afhandelen = true;
                this.buttons.sluiten = true;
                this.buttons.verwijderen = true;
                break;
            case 'pending':
                this.buttons.toekenned = true;
                this.buttons.afhandelen = true;
                this.buttons.sluiten = true;
                this.buttons.verwijderen = true;
                break;
            case 'done':
                this.buttons.sluiten = true;
                this.buttons.verwijderen = true;
                break;
            case 'closed':
                this.buttons.bevestigen = true;
                // this.buttons.open = true;
                this.buttons.accepteren = true;
                this.buttons.behandeling = true;
                this.buttons.toekenned = true;
                this.buttons.afhandelen = true;
                this.buttons.verwijderen = true;
                this.buttons.afwijzen = true;
                break;
            case 'unclear_place':
                break;
        }

        this.$root.$on('modal-confirmed', function () {
            self.deleteReport();
        });
    },

    methods: {
        changeStatus(status = null) {
            axios.post('/admin/reports/update-status/' + this.report_id, {
                status: status ?? this.selected_status
            }).then(() => {
                window.location.reload();
            }).catch(() => {

            });
        },

        inTreatment() {
            axios.post("/admin/assign-user-to-report", {
                user_id: this.user_id,
                report_id: this.report_id,
            }).then(() => {
                this.$root.$emit('modal-message-ajax', {
                    title: 'Success',
                    body: 'The report was assigned to ' + this.user_name,
                });

                axios.post('/admin/reports/update-status/' + this.report_id, {
                    status: 'in_treatment'
                }).then(() => {

                }).catch(() => {

                });

            }).catch(() => {
                this.$root.$emit('modal-message-ajax', {
                    title: 'Error',
                    body: 'ERROR: Something went wrong',
                });
            });
        },

        rejectReport() {

        },

        getUsers() {
            this.$root.$emit('get-users');
        },

        deleteReport() {

            const self = this;

            axios.delete('/admin/reports/delete/' + this.report_id).then(() => {
                this.$root.$emit('reloadData');
                window.location.href = this.type === 'idea' ? '/admin/ideas' : '/admin/problems';
            }).catch(function(error){

                self.$root.$emit('modal-message-ajax', {
                                        title: self.$gettext('Error'),
                                        body: self.$gettext(error.response.data.message),
                                    });
            });
        }
    },
};
</script>
