<template>
    <div id="map-container" class="position-relative">
        <map-template></map-template>
        <div id="map-controls">
            <div class="container row mx-auto">
                <div class="col-md-3 col-12 mb-2 md-mb-0 position-relative">
                    <div class="card border-0 rounded mb-2">
                        <div class="report-box bg-success rounded">
                            <h1 class="mb-0 text-white fw-bold">Overzicht</h1>
                            <div class="round p-4 bg-white text-dark">
                                <p class="mb-0 fw-bold">
                                    <span>Gemeente:</span>
                                    <span v-text="council"></span>
                                </p>
                                <p v-text="$root.agreement.name"></p>
                                <p class="mb-0">
                                    <span>Meldingen per maand:</span>
                                    <span v-text="problems_per_month"></span>
                                </p>
                                <p class="mb-0">
                                    <span>Oplossingen per maand:</span>
                                    <span v-text="solutions_per_month"></span>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="card border-0 mb-2" v-if="Object.keys(problems).length > 0">
                        <div class="card-header bg-success text-white">
                            Nieuwste problemen
                        </div>

                        <div class="card-body p-0">
                            <div class="px-3 py-2 latest-report" v-for="problem in problems.slice(0, 3)">
                                <router-link :to="'/melding/' + problem.id" class="text-decoration-none text-dark">
                                    <p class="mb-0">
                                        <img :src="problem.is_fixed ? asset('images/problem_fixed.png') : asset('images/icon_problem_normal.png')"
                                             :alt="problem.title" height="25" width="25"/>
                                        <span v-text="problem.title + ', '"></span>
                                        <span v-text="problem.address"></span>
                                    </p>
                                </router-link>
                            </div>
                        </div>
                    </div>

                    <div class="card border-0" v-if="Object.keys(ideas).length > 0">
                        <div class="card-header bg-success text-white">
                            Actieve Ideeën
                        </div>
                        <div class="card-body p-0">
                            <div class="px-3 py-2 latest-report" v-for="idea in ideas.slice(0, 3)">
                                <router-link :to="'/melding/' + idea.id" class="text-decoration-none text-dark">
                                    <p class="mb-0">
                                        <img :src="idea.is_fixed ? asset('images/idea_fixed.png') : asset('images/idea_not_fixed.png')"
                                             :alt="idea.title" height="25" width="25"/>
                                        <span v-text="idea.title + ', '"></span>
                                        <span v-text="idea.address"></span>
                                    </p>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 col-12 mb-2 md-md-0 h-100 position-relative">
                    <div class="bg-warning p-3 rounded">
                        <span v-text="$root.agreement.name"></span>
                    </div>
                </div>

                <div class="col-md-3 col-12 mb-2 md-md-0 h-100 position-relative">
                    <div class="d-flex">
                        <GmapAutocomplete class="form-control" :selectFirstOnEnter="true" placeholder="Voer locatie in"
                                          @place_changed="setTempLocation($event)" ref="autocomplete"></GmapAutocomplete>
                        <button class="button my-location rounded ms-1 bg-white"
                                :style="{backgroundImage: 'url(' + asset('images/my_location.png') + ')'}"
                                v-if="$root.isLocationAllowed" @click="goToMyLocation()"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ImageMixin from "../mixins/asset";
import {eventBus} from "../app";

export default {
    name: "Home",

    mixins: [ImageMixin],

    data() {
        return {
            council: "Geen",
            problems: {},
            ideas: {},
            problems_per_month: 0,
            solutions_per_month: 0,
            tempLocation: {},
        };
    },

    mounted() {
        let self = this;

        eventBus.$on("mapChangePosition", (reports, lat, lng) => {
            self.problems = {};
            self.ideas = {};

            self.problems_per_month = reports.problems_per_month;
            self.solutions_per_month = reports.solutions_per_month;

            if (Object.keys(reports.reports).length > 0) {
                self.problems = reports.reports.filter(
                    (el) => el.type === "problem"
                );
                self.ideas = reports.reports.filter((el) => el.type === "idea");
            }

            if (reports.council && self.$refs.autocomplete) {
                self.council = reports.council;
            } else {
                self.council = 'Geen';
            }
        });
    },

    methods: {
        setTempLocation(place) {
            this.tempLocation = {};

            if (place.geometry) {
                this.tempLocation = {
                    'lat': place.geometry.location.lat(),
                    'lng': place.geometry.location.lng()
                }
            }

            if (Object.keys(this.tempLocation).length > 0) {
                this.$root.coordinates = this.tempLocation;

                window.setTimeout(() => {
                    this.$root.zoom = 14;
                }, 500)
            }
        },

        goToMyLocation() {
            this.$root.coordinates = this.$root.myLocation;

            setTimeout(() => {
                this.$root.zoom = 14;
            }, 500)
        },
    },
};
</script>
<style scoped>
.report-box h1 {
    text-align: center;
    padding: 10px;
    font-size: 25px;
}

.round {
    border-radius: 8px 8px 0 0;
}

.report-box p {
    font-size: 15px;
}

.my-location {
    width: 48px;
    height: 38px;
    background-size: 18px;
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid #ced4da;
}
</style>
