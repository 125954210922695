<template>
    <div class="row mx-auto">
        <div class="col-md-6 col-12 mx-auto">
            <h1>{{ $gettext('Users') }}</h1>

            <div id="table-wrapper">
                <data-table
                    ref="usersTable"
                    order-by="id"
                    order-dir="desc"
                    :per-page="['10', '25', '50', '100']"
                    :columns="columns"
                    :debounce-delay="500"
                    :translate="translate"
                    :data="users"
                    @on-table-props-changed="reloadTable">
                    <div slot="filters" slot-scope="{ tableData, perPage }">
                        <div class="row mb-2 mx-0">
                            <div class="col-md-3">
                                <select class="form-control w-auto me-md-2 me-0 form-input-height" v-model="tableProps.length"
                                        @change="getUsers(); $refs.usersTable.page = 1">
                                    <option :key="page" v-for="page in ['10', '25', '50', '100']">{{ page }}</option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <div class="d-flex flex-column flex-md-row justify-content-center">
                                    <select name="status" id="status" class="form-control w-auto me-md-2 mb-2 text-capitalize"
                                            v-model="tableProps.role_id" @change="getUsers(); $refs.usersTable.page = 1">
                                        <option value="">{{ $gettext('All Roles') }}</option>
                                        <option v-for="(status, index) in roles" :key="index"
                                                v-text="$gettext(status.name)" :value="status.id"></option>
                                    </select>
                                    <v-autocomplete dense auto-select-first clearable filled solo ref="council"
                                                    hide-selected v-model="tableProps.council_id" :items="councils"
                                                    color="white" item-text="name" item-value="id"
                                                    v-if="$root.user.roles[0].name === 'super admin'"
                                                    class="form-control me-md-2" :placeholder="$gettext('All councils')"
                                                    @change="hideMenu('council'); getUsers(); $refs.usersTable.page = 1"
                                                    @input="showMenu('council');" @mouseup="showMenu('council')"
                                                    @click:append-outer="toggleMenu('council')">
                                    </v-autocomplete>
                                </div>
                            </div>
                            <div class="col-md-3 d-flex flex-column flex-md-row justify-content-end">
                                <input name="search" id="search" class="form-control w-auto form-input-height"
                                       :placeholder="$gettext('Search users...')"
                                       v-model="tableProps.search" @input="debounceInput">
                            </div>
                        </div>
                    </div>
                    <tbody slot="body" slot-scope="{ data }">
                    <tr :key="item.id" v-for="item in data">
                        <td :key="column.name" v-for="column in columns"
                            @click="column.name !=='Edit' ? showRowNumber(item.id) : ''">
                            <data-table-cell
                                :value="item"
                                :name="column.name"
                                :meta="column.meta"
                                :comp="column.component"
                                :classes="column.classes"
                            >
                            </data-table-cell>
                        </td>
                    </tr>
                    </tbody>
                </data-table>
            </div>

            <modal
                :modaltitle="$gettext('Delete User')"
                :modaltext="$gettext('Do you want to delete this user?')"
                :buttontext="$gettext('Delete')"
                :ajax_url="this.base_url + '/admin/users'"
            ></modal>
            <modal-message-ajax redirect-back="false"></modal-message-ajax>
        </div>
    </div>
</template>

<script>
import ActionTableButtons from "./ActionTableButtons.vue";
import { DataTable } from "laravel-vue-datatable";
import AutocompleteMixin from "../../mixins/autocomplete";
import {debounce} from "lodash";

export default {
    name: "users-table",

    props: ["base_url"],

    mixins: [
        AutocompleteMixin
    ],

    components: {
        ActionTableButtons,
        DataTable,
    },

    data() {
        return {
            url: "",
            showActionButtons: false,
            users: {},
            roles: {},
            councils: [],
            tableProps: {
                role_id: "",
                search: "",
                council_id: "",
                length: 10,
                column: 'id',
                dir: 'desc',
            },
            rowId: "",
            selectOptions: [],
            columns: [
                {
                    label: "Id",
                    name: "id",
                    orderable: true,
                },
                {
                    label: this.$gettext("Name"),
                    name: "first_name",
                    orderable: true,
                },
                 {
                    label: this.$gettext("Email"),
                    name: "email",
                    orderable: true,
                },
                {
                    label: this.$gettext("Actions"),
                    name: "Edit",
                    orderable: false,
                    classes: {
                        btn: true,
                        "btn-primary": true,
                        "btn-sm": true,
                    },
                    component: ActionTableButtons,
                    meta: {
                        url: this.base_url + "/admin/users",
                        showActionButtons: this.showActionButtons,
                        loginAs: true,
                        impersonateEndpoint: this.base_url + '/admin/users/impersonate/user'
                    },
                },
            ],
            translate: {
                nextButton: this.$gettext('Next'),
                previousButton: this.$gettext('Previous'),
                placeholderSearch: this.$gettext('Search users...'),
            },
        };
    },

    beforeCreate() {
        this.showActionButtons = !!(this.$root.user.roles.find(el => el.name === 'super admin') ||
            this.$root.user.roles.find(el => el.name === 'council admin'));
    },

    mounted() {
        this.url = this.base_url + "/admin/users-datatable";

        let self = this;

        this.$root.$on("get-datatable-row-id", function (id) {
            this.rowId = id;
        });

        this.$root.$on("modal-confirmed", function () {
            self.deleteRow(this.rowId);
        });

        this.getUsers();
        this.getRoles();
        this.getCouncils()
    },

    methods: {
        getUsers(url = this.url, options = this.tableProps) {
            axios.get(url, {
                params: {
                    search: options.search,
                    length: options.length,
                    council_id: this.tableProps.council_id,
                    role_id: this.tableProps.role_id,
                    page: options.page,
                    column: options.column,
                    dir: options.dir,
                }
            }).then(response => {
                this.users = response.data;
            })
        },

        debounceInput: debounce(function (e) {
            this.$refs.usersTable.page = 1

            this.getUsers()
        }, 500),

        getRoles() {
            axios.get('/admin/roles-datatable', {
                params: {
                    length: 100,
                    column: 'id',
                    dir: 'asc',
                }
            }).then(res => {
                this.roles = Object.values(res.data.data).filter(el => el.name !== 'super admin');
            })
        },

        getCouncils() {
            axios.get('/admin/categories/councils').then(res => {
                this.councils = res.data.data;
            })
        },

        showRowNumber(id) {
            window.location = "/admin/users/" + id;
        },

        reloadTable(tableProps) {
            tableProps['length'] = this.tableProps.length;
            tableProps['search'] = this.tableProps.search;

            this.getUsers(this.url, tableProps);
        },

        deleteRow(id) {
            let url = "/admin/users/delete/" + id;

            axios.post(url).then(() => {
                this.getUsers(this.url, this.tableProps);
            }).catch(errors => {
                this.$root.$emit('modal-message-ajax', {
                    title: 'Forbidden',
                    body: errors.response.data.message,
                });
            });
        },
    },
};
</script>
