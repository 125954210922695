<template>
    <div class="row mx-auto">
        <div class="col-md-6 col-12 mx-auto">

            <h1>{{ $gettext('Agreements') }}</h1>

            <div id="table-wrapper">
                <data-table
                    :columns="columns"
                    :per-page="['10', '25', '50', '100']"
                    :url="url"
                    :filters="filters"
                    ref="agreementsTable"
                >
                    <div slot="filters" slot-scope="{ tableData, perPage }">
                        <div class="row mb-2">
                            <div class="col-md-4">
                                <select class="form-control w-auto me-md-2 me-0 form-input-height" v-model="filters.length">
                                    <option :key="page" v-for="page in perPage">{{ page }}</option>
                                </select>
                            </div>
                            <div class="col-md-8 d-flex flex-column flex-md-row justify-content-end">
                                <input name="search" id="search" :placeholder="$gettext('Search agreements...')"
                                       class="form-control w-auto form-input-height" v-model="filters.search">
                            </div>
                        </div>
                    </div>
                    <tbody slot="body" slot-scope="{ data }">
                    <tr
                        :key="item.id"
                        v-for="item in data"
                    >
                        <td :key="column.name" v-for="column in columns"
                            @click="column.name !=='Edit' ? showRowNumber(item.id, item.type) : ''">
                            <data-table-cell
                                :value="item"
                                :name="column.name"
                                :meta="column.meta"
                                :comp="column.component"
                                :classes="column.classes"
                            >
                            </data-table-cell>
                        </td>
                    </tr>
                    </tbody>
                </data-table>
            </div>

            <modal
                :modaltitle="$gettext('Delete Agreement')"
                :modaltext="$gettext('Do you want to delete this agreement?')"
                :buttontext="$gettext('Delete')"
                :url="this.base_url + '/admin/agreement'"
            ></modal>
        </div>
    </div>
</template>

<script>
import ActionTableButtons from "./ActionTableButtons.vue";
import { DataTable } from "laravel-vue-datatable";

export default {
    name: "users-table",

    props: ["base_url"],

    components: {
        ActionTableButtons,
        DataTable,
    },

    data() {
        return {
            url: "",
            showActionButtons:false,
            filters: {
                search: "",
                length: 10,
            },
            rowId: "",
            selectOptions: [],
            columns: [
                {
                    label: "Id",
                    name: "id",
                    orderable: true,
                },
                {
                    label: this.$gettext("Name"),
                    name: "name",
                    orderable: true,
                },
            ],
        };
    },

    beforeCreate() {
        this.showActionButtons = !!(this.$root.user.roles.find(el => el.name === 'super admin'));
    },

    mounted() {
        this.url = this.base_url + "/admin/agreements/datatable";

        let self = this;

        this.$root.$on("get-datatable-row-id", function (id) {
            this.rowId = id;
        });

        this.$root.$on("modal-confirmed", function () {
            self.deleteRow(this.rowId);
        });
    },

    methods: {

        showRowNumber(id, type) {
            window.location = "/admin/agreements/" + id;
        },

        deleteRow(id) {
            let url = "/admin/agreements/delete/" + id;

            axios.delete(url).then(() => {
                this.$refs.agreementsTable.getData();
            });
        },
    },
};
</script>
