/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import Vue from "vue";

require('./bootstrap');

export const eventBus = new Vue();
const Vuetify = require('vuetify');
import axios from 'axios';

import router from "./router";
import VueAxios from 'vue-axios';
import DataTable from 'laravel-vue-datatable';
import * as VueGoogleMaps from 'vue2-google-maps';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import GetTextPlugin from 'vue-gettext';
import translations from './translations/translations.json';

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
import Print from 'vue-print-nb'

Vue.use(VueAxios, axios);
Vue.use(Vuetify, {
    iconfont: ['md', 'mdi', 'faSvg', 'mdiSvg'],
});
Vue.use(DataTable);
Vue.use(Print);

Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.MIX_GOOGLE_MAPS_API_KEY,
        libraries: 'places',
        v: 'quarterly'
    },
});

import CKEditor from 'ckeditor4-vue';
Vue.use( CKEditor );

Vue.component('add-report', require('./components/AddReport.vue').default);
Vue.component('my-reports', require('./components/MyReports.vue').default);
Vue.component('my-report', require('./components/MyReport.vue').default);
Vue.component('home', require('./components/Home.vue').default);
Vue.component('map-template', require('./components/MapTemplate.vue').default);
Vue.component('report', require('./components/Report.vue').default);
Vue.component('modal', require('./components/admin/ModalConfirmation.vue').default);
Vue.component('status-buttons', require('./components/admin/StatusButtons.vue').default);
Vue.component('flash-message', require('./components/FlashMessage.vue').default);
Vue.component('login', require('./components/Login.vue').default);
Vue.component('register', require('./components/Register.vue').default);
Vue.component('profile-settings', require('./components/ProfileSettings.vue').default);
Vue.component('modal-message-http', require('./components/admin/ModalMessageHttp.vue').default);
Vue.component('modal-message-ajax', require('./components/admin/ModalMessageAjax.vue').default);
Vue.component('apps', require('./components/Apps.vue').default);
Vue.component('contacts', require('./components/Contacts.vue').default);
Vue.component('reset-password', require('./components/ResetPassword.vue').default);
Vue.component('translate-button', require('./components/TranslationButton.vue').default);
Vue.component('carousel-gallery', require('./components/CarouselGallery.vue').default);

Vue.component('reports-table', require('./components/admin/ReportsTable.vue').default);
Vue.component('report-create', require('./components/admin/ReportCreate.vue').default);
Vue.component('report-edit', require('./components/admin/ReportEdit.vue').default);

Vue.component('categories-table', require('./components/admin/CategoriesTable.vue').default);
Vue.component('category-update', require('./components/admin/CategoryUpdate.vue').default);
Vue.component('category-create', require('./components/admin/CategoryCreate.vue').default);
Vue.component('sub-category-update', require('./components/SubCategoryUpdate.vue').default);

Vue.component('users-table',require('./components/admin/UsersTable.vue').default);
Vue.component('roles', require('./components/admin/Roles.vue').default);
Vue.component('permissions', require('./components/admin/Permissions.vue').default);

Vue.component('councils-table', require('./components/admin/CouncilsTable.vue').default);
Vue.component('council-edit', require('./components/admin/CouncilEdit.vue').default);

Vue.component('cities-table', require('./components/admin/CitiesTable.vue').default);
Vue.component('city-edit', require('./components/admin/CityEdit.vue').default);

Vue.component('agreements-table', require('./components/admin/AgreementsTable.vue').default);
Vue.component('agreement-edit', require('./components/admin/AgreementEdit.vue').default);

Vue.component('feedbacks-table',require('./components/admin/FeedbacksTable.vue').default);

Vue.component('standard-responses-table', require('./components/admin/StandardResponsesTable.vue').default);
Vue.component('standard-responses-crud', require('./components/admin/StandardResponseCrud.vue').default);

Vue.component('mail-templates-table', require('./components/admin/MailTemplatesTable.vue').default);
Vue.component('mail-templates-crud', require('./components/admin/MailTemplatesCrud.vue').default);

Vue.component('mail-logs-table', require('./components/admin/MailLogsTable.vue').default);

Vue.component('star',require('./components/admin/ComponentStar.vue').default);

Vue.component('user-crud', require('./components/admin/UserCrud.vue').default);
Vue.component('user-create', require('./components/admin/UserCreate.vue').default);
Vue.component('user-profile-edit', require('./components/admin/UserProfileEdit.vue').default);

Vue.component('contact-forms-table', require('./components/admin/ContactFormsTable.vue').default);

Vue.component('export-reports-modal', require('./components/admin/ExportReportsModal.vue').default);

Vue.component('cookie-policy-popup', require('./components/CookiePolicyPopup.vue').default);

// Routes

Vue.prototype.$eventBus = new Vue();

function init(language) {
    Vue.use(GetTextPlugin, {
        availableLanguages: {
            en_US: 'English',
            nl_NL: 'Netherlands'
        },
        defaultLanguage: language,
        languageVmMixin: {
            computed: {
                currentKebabCase: function () {
                    return this.current.toLowerCase().replace('_', '-')
                },
            },
        },
        translations: translations,
        silent: true,
    });
    const app = new Vue({
        router,
        el: '#app',
        vuetify: new Vuetify(),
        props: ['message'],

        data() {
            return {
                authUser: window.authUser,
                loggedAs: window.loggedAs === 1,
                user: window.user ?? {},
                coordinates: {
                    lat: 52.3675734,
                    lng: 4.9041389,
                },
                myLocation: {
                    lat: 52.3675734,
                    lng: 4.9041389,
                },
                mapLoaded: false,
                zoom: 12,
                mapsKey: process.env.MIX_GOOGLE_MAPS_API_KEY,
                isLocationAllowed: false,
                adminMenuCollapse: false,
                agreement: {
                    id: 1,
                    name: "Deze gemeente heeft niet aangegeven dat zij meedoet. Zij ontvangt je melding wel.",
                }
            }
        },

        mounted() {
            this.geoLocate();
            this.getAgreement(this.coordinates)

            if (window.localStorage.getItem('from_report')) {
                window.setTimeout(() => {
                    this.$router.push({name: 'reports.new'});
                }, 1000)
            }

            this.adminMenuCollapse = (sessionStorage.getItem('adminMenuCollapse') && sessionStorage.getItem('adminMenuCollapse') === 'true');

            if (this.adminMenuCollapse) document.querySelector('body').setAttribute('data-kt-aside-minimize', 'on');

            window.axios.interceptors.response.use(function (response) {
                return response;
            }, function (error) {
                if (error.response && error.response.status === 401) {
                    window.location.reload();

                    sessionStorage.setItem("message", "Je bent uitgelogd!");
                    sessionStorage.setItem("alert_type", "danger");

                    this.$root.$emit("show-flash-message");
                }

                return Promise.reject(error);
            });

            if (!localStorage.getItem('welcomeMessage')) {
                jQuery('#welcomeModal').modal({
                    backdrop: 'static',
                    keyboard: false  // to prevent closing with Esc button (if you want this too)
                }).modal('show')
            }
        },

        computed: {
            google: VueGoogleMaps.gmapApi,
        },

        watch: {
            'coordinates'(val) {
                this.getAgreement(val)
            }
        },

        methods: {
            geoLocate() {
                let self = this;

                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition((position) => {
                        self.coordinates = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        };

                        self.myLocation = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        }

                        self.mapLoaded = true;
                        self.isLocationAllowed = true;
                    });
                }

                navigator.permissions && navigator.permissions.query({name: 'geolocation'}).then(function(PermissionStatus) {
                    if (PermissionStatus.state == 'granted') {
                        navigator.geolocation.getCurrentPosition((position) => {
                            self.coordinates = {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                            };

                            self.myLocation = {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                            }

                            self.mapLoaded = true;
                            self.isLocationAllowed = true;
                        });
                    } else if (PermissionStatus.state == 'prompt') {
                        self.mapLoaded = true;
                    } else {
                        self.mapLoaded = true;
                    }
                })
             },

            getAgreement(coordinates) {
                axios.get('/api/agreements?latitude=' + coordinates.lat + '&longitude=' + coordinates.lng).then(res => {
                    this.agreement = res.data.data;
                })
            },

            logOut() {
                axios.post("/logout").then((response) => {
                    this.authUser = false;
                    this.user = {};

                    if (this.$route.name !== 'home') this.$router.push({name: 'home'})
                });
            },

            backToUser() {
                axios.get('/impersonate/destroy').then((response) => {
                    window.location.reload();
                });
            },

            isIdea(report = null) {
                let checkString = report ?? window.location.href;
                let arr = checkString.split('/');

                return arr.includes('ideas');
            },

            setSidebar() {
                this.adminMenuCollapse = !this.adminMenuCollapse

                sessionStorage.setItem("adminMenuCollapse", this.adminMenuCollapse);
            },

            dismissCookie() {
                jQuery('#welcomeModal').modal('hide');

                localStorage.setItem("welcomeMessage", 1);
            }
        }
    });
}

let locale = (window.VueLocale !== undefined) ? (window.VueLocale == 'nl') ? 'nl_NL' : 'en_US' : 'nl_NL';

init(locale);
