<template>
    <div class="container">
        <div class="card my-3 p-3 shadow-sm opacity">
            <div class="card-header bg-white p-0 border-0 d-flex justify-content-between">
                <h1 class="title fw-bold">Cookie beleid</h1>

                <router-link to="/" class="text-dark text-decoration-none close-button">×</router-link>
            </div>

            <div class="card-body p-0">
                <p>
                    Bij
                    <router-link to="/" class="text-decoration-none text-success fw-bold">Verbeterdebuurt
                    </router-link>
                    hechten we veel waarde aan uw privacy en willen we ervoor zorgen dat u een positieve ervaring heeft
                    bij het gebruik van onze website. Als onderdeel van deze toewijding maken we gebruik van cookies om
                    u een gepersonaliseerde en efficiënte browse-ervaring te bieden. Deze Cookiebeleid legt uit hoe we
                    cookies gebruiken op onze website, en uw rechten om cookies te beheren.
                </p>

                <h2 class="h2">Wat zijn cookies?</h2>

                <p>Cookies zijn kleine tekstbestanden die worden opgeslagen op uw apparaat (computer, tablet of mobiele
                    telefoon) wanneer u een website bezoekt. Ze stellen de website in staat om uw apparaat te herkennen
                    en uw voorkeuren te onthouden, zoals uw taalvoorkeur, inloggegevens en andere instellingen. Cookies
                    kunnen ons ook helpen bij het analyseren hoe onze website wordt gebruikt en de prestaties ervan
                    verbeteren.</p>

                <h2 class="h2">Welke soorten cookies gebruiken we?</h2>

                <p>We gebruiken functionele, Google Analytics- en locatiecookies op onze website.</p>

                <p>Functionele cookies zijn noodzakelijk voor de goede werking van de website. Ze stellen ons in staat
                    om u basisfuncties te bieden, zoals paginanavigatie en toegang tot beveiligde delen van de
                    website.</p>

                <p>Google Analytics-cookies helpen ons bij het analyseren van hoe onze website wordt gebruikt en het
                    verbeteren van de prestaties ervan. Ze verzamelen anonieme informatie over uw gebruik van onze
                    website, zoals de pagina's die u bezoekt, de duur van uw bezoek en de links waarop u klikt. Deze
                    informatie wordt gebruikt om onze website te verbeteren en een betere gebruikerservaring te
                    bieden.</p>

                <p>Locatiecookies worden gebruikt om uw ervaring te personaliseren op basis van uw locatie. Ze stellen
                    ons in staat om u relevante inhoud en services te tonen op basis van uw locatie.</p>

                <h2 class="h2">Hoe kunt u cookies beheren?</h2>

                <p>U heeft het recht om cookies te beheren en te kiezen of u ze accepteert of weigert. U kunt uw
                    cookie-instellingen op elk moment wijzigen door te klikken op de link "Cookie-instellingen" op onze
                    website. U kunt ook uw browserinstellingen aanpassen om cookies uit te schakelen of cookies te
                    verwijderen die al op uw apparaat zijn opgeslagen. Houd er echter rekening mee dat het uitschakelen
                    van cookies van invloed kan zijn op uw browse-ervaring en sommige functies van onze website mogelijk
                    niet goed werken.</p>

                <h2 class="h2">Conclusie</h2>

                <p>We maken gebruik van cookies om u een gepersonaliseerde en efficiënte browse-ervaring te bieden op
                    onze website. We respecteren uw privacy en zijn toegewijd aan het beschermen van uw persoonlijke
                    informatie. Als u vragen of opmerkingen heeft over ons Cookiebeleid, neem dan contact met ons op via
                    <a href="mailto:support@verbeterdebuurt.nl">support@verbeterdebuurt.nl</a>.</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {}
</script>
