<template>
    <div class="row mx-auto reports-table">
        <div class="col-lg-10 col-12 mx-auto px-0">
            <h1 class="h1 mb-3" v-if="this.type === 'idea'">{{ $gettext('Ideas') }}</h1>
            <h1 class="h1 mb-3" v-else>{{ $gettext('Problems') }}</h1>

            <div id="table-wrapper">
                <data-table
                ref="reportsTable"
                order-by="id"
                order-dir="desc"
                :per-page="['10', '25', '50', '100']"
                :columns="columns"
                :debounce-delay="500"
                :translate="translate"
                :data="reports"
                @on-table-props-changed="reloadTable">
                <div slot="filters" class="filters" slot-scope="{ tableData, perPage }">
                    <div class="row mb-2 mx-0">
                        <div class="col-md-1 px-0 mb-2">
                            <select class="form-control w-auto me-md-2 me-0 form-input-height" v-model="tableProps.length"
                                    @change="getReports(); $refs.reportsTable.page = 1">
                                <option :key="page" v-for="page in ['10', '25', '50', '100']">{{ page }}</option>
                            </select>
                        </div>
                        <div class="col-md-11 d-flex flex-column flex-md-row justify-content-end mb-2 px-0">
                            <select name="status" id="status" class="form-control w-xl-auto w-100"
                                    v-model="tableProps.status" @change="getReports(); $root.$emit('change-reports-filters', tableProps.status, tableProps.category, tableProps.with_trashed)">
                                <option value="">{{ $gettext('All statuses') }}</option>
                                <option v-for="(status, index) in statuses" :key="index"
                                        v-text="$gettext(status)" :value="index"></option>
                            </select>
                            <v-autocomplete dense auto-select-first clearable filled solo ref="category"
                                            hide-selected v-model="tableProps.category" :items="categories"
                                            color="white" item-text="name" item-value="id" v-if="type === 'problem'"
                                            class="form-control" :placeholder="$gettext('All categories')"
                                            @change="hideMenu('category'); getReports(); $refs.reportsTable.page = 1; $root.$emit('change-reports-filters', tableProps.status, tableProps.category, tableProps.with_trashed)"
                                            @input="showMenu('category');" @mouseup="showMenu('category')"
                                            @click:append-outer="toggleMenu('category')">
                            </v-autocomplete>
                            <input name="search" id="search" class="form-control w-auto form-input-height"
                                   :placeholder="type === 'idea' ? $gettext('Search ideas...') : $gettext('Search problems...')"
                                   v-model="tableProps.search" @input="debounceInput">
                            <v-autocomplete v-if="$root.user.roles.find(el => el.name === 'super admin')"
                                            dense auto-select-first clearable filled solo ref="council"
                                            hide-selected v-model="tableProps.council_id" :items="councils"
                                            color="white" item-text="name" item-value="id" class="form-control me-md-2"
                                            :placeholder="$gettext('All councils')"
                                            @change="hideMenu('council'); getReports(); $refs.reportsTable.page = 1"
                                            @input="showMenu('council')" @mouseup="showMenu('council')"
                                            @click:append-outer="toggleMenu('council')">
                            </v-autocomplete>
                        </div>
                        <div class="col-md-12 d-flex flex-column flex-md-row justify-content-end px-0">
                            <div class="d-flex align-items-center me-2">
                                <span class="me-2" v-text="$gettext('Show deleted?')"></span>
                                <label class="switch" for="with_trashed">
                                    <input type="checkbox" v-model="tableProps.with_trashed"
                                           name="with_trashed" id="with_trashed" value="1"
                                           @change="getReports(); $refs.reportsTable.page = 1; $root.$emit('change-reports-filters', tableProps.status, tableProps.category, tableProps.with_trashed)">
                                    <span class="slider round"></span>
                                </label>
                            </div>
                            <button class="btn btn-light" data-bs-toggle="modal" data-bs-target="#exportReportsModal">
                                <span class="mdi mdi-application-export"></span>
                                <span v-text="$gettext('Export Reports')"></span>
                            </button>
                            <a href="/admin/reports/create" class="d-flex align-items-center" v-if="type === 'problem'
                            && ['council admin', 'council employee'].includes($root.user.roles[0].name)">
                                <span class="mdi mdi-application-export"></span>
                                <span v-text="$gettext('Create report')"></span>
                            </a>
                        </div>
                    </div>
                </div>
                <tbody slot="body" slot-scope="{ data }">
                <tr :key="item.id" v-for="item in data">
                    <td :key="column.name" v-for="column in columns"
                        @click="column.name !=='Edit' ? showRowNumber(item.id) : ''">
                        <data-table-cell
                            :value="item"
                            :name="column.name"
                            :meta="column.meta"
                            :comp="column.component"
                            :classes="column.classes">
                        </data-table-cell>
                    </td>
                </tr>
                </tbody>
            </data-table>
            </div>
        </div>
        <export-reports-modal :statuses="statuses" :categories="categories" :with-trashed-reports="tableProps.with_trashed"></export-reports-modal>
    </div>
</template>

<script>
import ActionTableButtons from "./ActionTableButtons.vue";
import { DataTable } from "laravel-vue-datatable";
import Star from "./ComponentStar.vue";
import AutocompleteMixin from "../../mixins/autocomplete";
import {debounce} from "lodash";

export default {
    name: "reports-table",

    props: [
        'type',
        'types',
        'reportEndpoint',
        'dataTableEndpoint',
    ],

    mixins: [
        AutocompleteMixin
    ],

    components: {
        ActionTableButtons,
        DataTable,
        Star,
    },

    data() {
        return {
            url: "",
            showActionButtons: true,
            reports: {},
            councils: [],
            tableProps: {
                status: '',
                search: '',
                category: '',
                length: 10,
                column: 'id',
                dir: 'desc',
                with_trashed: false,
                city_id: '',
                council_id: '',
            },
            category: {},
            rowId: "",
            selectOptions: [],
            categories: [],
            statuses: {},
            perPage: ['10', '25', '50', '100'],
            columns: [
                {
                    label: "Id",
                    name: "id",
                    orderable: true,
                },
                {
                    label: this.$gettext("Title"),
                    name: "title",
                    orderable: true,
                },
                {
                    label: this.$gettext("Status"),
                    columnName: "status",
                    name: "status_formatted",
                    orderable: true,
                },
                {
                    label: this.$gettext("Address"),
                    name: "address",
                    orderable: true,
                },
                {
                    label: this.$gettext("Internally assigned to"),
                    name: "assignee_name",
                    columnName: "assignee_id",
                    orderable: true,
                },
                {
                    label: this.$gettext("Category"),
                    name: this.type === 'idea' ? "" : "category.name",
                    orderable: true,
                },
                {
                    label: this.$gettext("Created at"),
                    name: "create_date_formatted",
                    columnName: "created_at",
                    orderable: true,
                },
                {
                    label: this.$gettext("To vote"),
                    name: "rating",
                    orderable: true,
                    component:Star,
                },
            ],
            translate: {
                nextButton: this.$gettext('Next'),
                previousButton: this.$gettext('Previous'),
                placeholderSearch: this.type === 'idea' ? this.$gettext('Search ideas...') : this.$gettext('Search problems...'),
            },
        };
    },

    beforeCreate() {
        // temporary hardcoded
        this.showActionButtons = true;
    },

    created() {
        if (this.type === 'idea') this.columns = this.columns.filter(el => el.name !== '');

    },

    mounted() {
        this.$root.$on("get-datatable-row-id", function (id) {
            this.rowId = id;
        });

        this.getReports();

        this.getCategories();

        this.getStatuses();

        if (this.$root.user.roles[0].name === 'super admin') this.getCouncils();
    },

    methods: {
        getReports(url = this.dataTableEndpoint, options = this.tableProps) {
            if (options.column === 'created_at') {
                options.column = 'date';
            }

            axios.get(url, {
                params: {
                    search: options.search,
                    length: options.length,
                    category: this.tableProps.category,
                    status: this.tableProps.status,
                    page: options.page,
                    column: options.column,
                    dir: options.dir,
                    type: this.type,
                    with_trashed: this.tableProps.with_trashed,
                    city_id: this.tableProps.city_id,
                    council_id: this.tableProps.council_id,
                }
            }).then(response => {
                this.reports = response.data;
            })
        },

        debounceInput: debounce(function (e) {
            this.$refs.reportsTable.page = 1

            this.getReports()
        }, 500),

        getCategories() {
            axios.get('/api/report-categories').then(res => {
                this.categories = res.data.data;
            })
        },

        getStatuses() {
            axios.get('/api/statuses').then(res => {
                this.statuses = res.data.data;
            })
        },

        getCouncils() {
            axios.get('/admin/categories/councils').then(res => {
                this.councils = res.data.data;
            })
        },

        showRowNumber(id) {
            window.location = this.reportEndpoint + '/' + id;
        },

        // Capitalize string
        capitalize(sentence) {
            return sentence.charAt(0).toUpperCase() + sentence.slice(1);
        },

        reloadTable(tableProps) {
            tableProps['length'] = this.tableProps.length;
            tableProps['search'] = this.tableProps.search;

            this.getReports(this.dataTableEndpoint, tableProps);
        },
    },
};
</script>
<style>
.report-table .filters {
    flex-direction: row;
}

.reports-table .form-control,
.reports-table .v-input {
    margin-right: .5rem;
}

.mdi-application-export {
    padding-right:5px;
}

@media only screen and (max-width: 1920px) {
    .reports-table .form-control,
    .reports-table .v-input,
    .reports-table .v-input__control {
        width: 100% !important;
        margin-bottom: .5rem;
    }
}
</style>
