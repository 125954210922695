<template>
    <div id="background">
        <GmapMap ref="map" id="googlemaps" :center="center" :zoom="$root.zoom" class="position-absolute" :options="options"
                 @dragend="mapMove('map')" @zoom_changed="mapMove('map')" v-if="$route.matched.some(({ name }) => name === 'home')
                 || $route.matched.some(({ name }) => name === 'reports.new')">
        </GmapMap>

        <div class="container">
            <div class="row">
                <div class="col-md-8 center position-sticky mx-auto" v-show="!formSuccess">
                    <div class="bg-white rounded border border-primary text-center"
                         v-if="!showProblem && !showIdea && !showForm">
                        <div>
                            <div class="bg-success d-flex justify-content-between align-items-center p-3">
                                <h1 class="h1 mb-0 text-white">Nieuwe Melding</h1>

                                <router-link to="/" class="text-white text-decoration-none close-button">×</router-link>
                            </div>

                            <div class="bg-white">
                                <p class="black-color pt-2">Meld je een probleem of heb je een idee?</p>
                                <div class="d-flex justify-content-center">
                                    <div class="col-4">
                                        <button class="btn border-0"
                                                @click.prevent="showProblem = true; showIdea = false; showForm = true; addMarker()">
                                            <img :src="asset('images/icon_problem_normal.png')" alt="Probleem" width="50"/>
                                            <p class="mb-0">Probleem</p>
                                        </button>
                                    </div>

                                    <div class="col-4">
                                        <button class="btn border-0"
                                                @click.prevent="showIdea = true; showProblem = false; showForm = true; addMarker()">
                                            <img :src="asset('images/idea_not_fixed.png')" alt="Idee" width="50"/>
                                            <p class="mb-0">Idee</p>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="bg-white rounded border border-primary report-body"
                         v-if="showForm" :class="showProblem ? 'report-problem' : 'report-idea'" v-show="!isFormSubmitted">
                        <div class="header bg-success d-flex justify-content-between align-items-center p-3">
                            <h1 v-if="showProblem" class="text-white mb-0">Nieuw Probleem</h1>
                            <h1 v-if="showIdea" class="text-white mb-0">Nieuw Idee</h1>
                            <a href="#" @click="showProblem = false; showIdea = false; showForm = false;" class="text-white text-decoration-none close-button">×</a>
                        </div>

                        <div class="p-4">
                            <p>1. Bepaal de locatie</p>

                            <div class="mapouter2 position-relative mb-2" style="height: 300px">
                                <div class="d-flex searchbar position-absolute ms-auto">
                                    <button class="button my-location rounded me-1 bg-white"
                                            :style="{backgroundImage: 'url(' + asset('images/my_location.png') + ')'}"
                                            v-if="$root.isLocationAllowed" @click="goToMyLocation()"></button>
                                    <GmapAutocomplete placeholder="Vul locatie in..." class="form-control"
                                                      :selectFirstOnEnter="true" @place_changed="setTempLocation($event)" ref="GmapAutocomplete"/>
                                </div>
                                <div class="gmap_canvas">
                                    <GmapMap :center="center" class="w-100 h-100 position-absolute top-0" :options="options"
                                             :zoom="zoom" ref="reportMap" @dragend="mapMove('reportMap')" @zoom_changed="mapMove('reportMap')">
                                        <GmapMarker :position="marker" @dragend="updateCoordinates($event.latLng)"
                                                    :clickable="true" :draggable="true" :icon="marker.icon"/>
                                    </GmapMap>
                                </div>
                            </div>

                            <div class="mb-2" v-if="showProblem || showIdea">
                                <span v-text="address"></span>
                            </div>

                            <div class="mb-2" v-if="showProblem || showIdea">
                                <span class="text-success" v-text="this.$root.agreement.name"></span>
                            </div>

                            <div v-show="!isFormSubmitted" v-if="$root.agreement.services.includes('reports')">
                                <p class="mb-0">2. Beschrijf de melding</p>

                                <form enctype="multipart/form-data" @submit.prevent="saveData()" v-show="!isFormSubmitted">
                                    <input type="hidden" name="_token" :value="csrf"/>

                                    <div class="form-group">
                                        <label for="title" class="col-form-label">Onderwerp *</label>
                                        <input type="text" name="title" id="title" v-model="report.title"
                                               class="form-control" required placeholder="Onderwerp"/>

                                        <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                              v-if="errors.title" v-for="(name, index) in errors.title">
                                            <strong v-text="errors.title[index]"></strong>
                                        </span>
                                    </div>

                                    <div v-if="showProblem" class="form-group">
                                        <label for="category" class="col-form-label">Categorie *</label>
                                        <select name="category" id="category" v-model="report.report_category_id"
                                                class="form-control" required>
                                            <option value="">Kies een categorie...</option>
                                            <option v-for="category in this.categories" :value="category.id">
                                                {{ category.name }}
                                            </option>
                                        </select>

                                        <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                              v-if="errors.report_category_id" v-for="(name, index) in errors.report_category_id">
                                            <strong v-text="errors.report_category_id[index]"></strong>
                                        </span>
                                    </div>

                                    <div class="form-group mb-2">
                                        <label for="description" class="col-form-label">Beschrijving *</label>
                                        <textarea name="description" id="description" v-model="report.description"
                                                  class="form-control" rows="5" required
                                                  :placeholder="showProblem ? 'Beschrijving' : 'Wat is er nodig om je idee te realiseren?'"></textarea>

                                        <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                              v-if="errors.description" v-for="(name, index) in errors.description">
                                            <strong v-text="errors.description[index]"></strong>
                                        </span>
                                    </div>

                                    <div v-if="showIdea" class="form-group mb-2">
                                        <label for="description" class="col-form-label">Realisatie</label>
                                        <textarea name="resolution" id="resolution" v-model="report.resolution"
                                                  class="form-control" rows="5" placeholder="Wat is er nodig om je idee te realiseren?"></textarea>

                                        <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                              v-if="errors.resolution" v-for="(name, index) in errors.resolution">
                                            <strong v-text="errors.resolution[index]"></strong>
                                        </span>
                                    </div>

                                    <div v-if="showProblem" class="form-group">
                                        <a href="#" type="button" class="btn btn-transparent border border-dark w-100"
                                           @click.prevent="showPrivate = !showPrivate">Privénotitie toevoegen</a>
                                        <div v-if="showPrivate">
                                            <label for="private_note" class="col-form-label">Privénotitie</label>
                                            <textarea name="private_note" id="private_note" class="form-control"
                                                      rows="5" placeholder="Een privénotitie wordt alleen naar de gemeente/behandelaar gestuurd en wordt niet zichtbaar op de site."
                                                      v-model="report.private_note"></textarea>

                                            <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                                  v-if="errors.private_note" v-for="(name, index) in errors.private_note">
                                                <strong v-text="errors.private_note[index]"></strong>
                                            </span>
                                        </div>
                                    </div>

                                    <div class="form-group mb-2">
                                        <p class="col-form-label">Foto uploaden (optioneel)</p>
                                        <input type="file" name="photos" id="photos" multiple
                                               accept=".jpg,.jpeg,.png,.gif" ref="photos"
                                               class="form-control" @change="onFileSelected($event)"/>
                                        <span>Max 6 afbeeldingen / Maximale afbeeldingsgrootte 7MB / Toegestane formaten: .jpg,.jpeg,.png,.gif</span>

                                        <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                              v-if="errors.photos" v-for="(name, index) in errors.photos">
                                        <strong v-text="errors.photos[index]"></strong>
                                    </span>

                                        <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                              v-if="Object.keys(errorPhotos).length > 0" v-for="(error, key, index) in errorPhotos">
                                        <strong v-text="errorPhotos[key][0] + ' ' + '(Foto ' + [index + 1] + ')'"></strong>
                                        <br>
                                    </span>

                                        <div v-if="Object.keys(photos).length > 0" class="mt-2">
                                            <div v-if="Object.keys(photos).length == 1" class="image mx-auto" :style="{backgroundImage: 'url(' + photosPreview[0] + ')'}"></div>

                                            <div v-if="Object.keys(photos).length > 1">
                                                <vueper-slides>
                                                    <vueper-slide
                                                        v-for="(slide, i) in photosPreview"
                                                        :key="i"
                                                        :image="slide">
                                                    </vueper-slide>

                                                    <template #bullet="{ active, slideIndex, index }">
                                                        <button></button>
                                                    </template>
                                                </vueper-slides>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="!$root.authUser">
                                        <p>3. Contact gegevens</p>

                                        <div class="form-outline mb-4">
                                            <div class="form-group mb-2">
                                                <input type="email" placeholder="E-mailadres" class="form-control"
                                                       name="email" v-model="newUser.email" required/>

                                                <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                                      v-if="errors.email" v-for="(name, index) in errors.email">
                                                    <strong v-text="errors.email[index]"></strong>
                                                </span>
                                            </div>

                                            <div class="form-group mb-2">
                                                <div class="row mb-4">
                                                    <div class="col-lg-6 mb-2 mb-lg-0">
                                                        <input type="password" placeholder="Wachtwoord" class="form-control"
                                                               name="password" v-model="newUser.password" required/>

                                                        <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                                              v-if="errors.password" v-for="(name, index) in errors.password">
                                                            <strong v-text="errors.password[index]"></strong>
                                                        </span>
                                                    </div>
                                                    <div class="col-lg-6 mb-2 mb-lg-0">
                                                        <input type="password" placeholder="Wachtwoord herhalen" class="form-control"
                                                               name="password_confirmation" v-model="newUser.password_confirmation" required/>

                                                        <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                                              v-if="errors.password_confirmation" v-for="(name, index) in errors.password_confirmation">
                                                            <strong v-text="errors.password_confirmation[index]"></strong>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group mb-2">
                                                <div class="row mb-2">
                                                    <div class="col-lg-2 col-md-6 mb-2 mb-lg-0">
                                                        <select class="form-control" name="gender" v-model="newUser.gender">
                                                            <option value="male">Dhr.</option>
                                                            <option value="female">Mw.</option>
                                                        </select>

                                                        <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                                              v-if="errors.gender" v-for="(name, index) in errors.gender">
                                                            <strong v-text="errors.gender[index]"></strong>
                                                        </span>
                                                    </div>

                                                    <div class="col-lg-4 col-md-6 mb-2 mb-lg-0">
                                                        <input type="text" placeholder="Naam" class="form-control"
                                                               name="first_name" v-model="newUser.first_name" required/>

                                                        <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                                              v-if="errors.first_name" v-for="(name, index) in errors.first_name">
                                                            <strong v-text="errors.first_name[index]"></strong>
                                                        </span>
                                                    </div>

                                                    <div class="col-lg-2 col-md-6 mb-2 mb-lg-0">
                                                        <input type="text" placeholder="Tussenvoegsel" class="form-control"
                                                               name="middle_name" v-model="newUser.middle_name"/>

                                                        <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                                              v-if="errors.middle_name" v-for="(name, index) in errors.middle_name">
                                                            <strong v-text="errors.middle_name[index]"></strong>
                                                        </span>
                                                    </div>

                                                    <div class="col-lg-4 col-md-6 mb-2 mb-lg-0">
                                                        <input type="text" placeholder="Achternaam" class="form-control"
                                                               name="last_name" v-model="newUser.last_name" required/>

                                                        <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                                              v-if="errors.last_name" v-for="(name, index) in errors.last_name">
                                                            <strong v-text="errors.last_name[index]"></strong>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group mb-2">
                                                <div class="row mb-2">
                                                    <div class="col-lg-3 col-md-6 mb-2 mb-lg-0">
                                                        <input type="text" placeholder="Straat" class="form-control"
                                                               name="street" v-model="newUser.street" required/>

                                                        <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                                              v-if="errors.street" v-for="(name, index) in errors.street">
                                                        <strong v-text="errors.street[index]"></strong>
                                                    </span>
                                                    </div>

                                                    <div class="col-lg-2 col-md-6 mb-2 mb-lg-0">
                                                        <input type="text" placeholder="Huisnummer" class="form-control"
                                                               name="street_number" v-model="newUser.street_number" required/>

                                                        <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                                              v-if="errors.street_number" v-for="(name, index) in errors.street_number">
                                                            <strong v-text="errors.street_number[index]"></strong>
                                                        </span>
                                                    </div>

                                                    <div class="col-lg-4 col-md-6 mb-2 mb-lg-0">
                                                        <input type="text" placeholder="Plaats" class="form-control"
                                                               name="city" v-model="newUser.user_city" required/>

                                                        <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                                              v-if="errors.city" v-for="(name, index) in errors.city">
                                                            <strong v-text="errors.city[index]"></strong>
                                                        </span>
                                                    </div>

                                                    <div class="col-lg-3 col-md-6 mb-2 mb-lg-0">
                                                        <input type="text" placeholder="Postcode" class="form-control"
                                                               name="postcode" v-model="newUser.user_zip" required/>

                                                        <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                                              v-if="errors.postcode" v-for="(name, index) in errors.postcode">
                                                            <strong v-text="errors.postcode[index]"></strong>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group mb-2">
                                                <input type="tel" placeholder="Telefoonnummer (optioneel)" class="form-control"
                                                       name="phone" v-model="newUser.phone"/>

                                                <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                                      v-if="errors.phone" v-for="(name, index) in errors.phone">
                                                    <strong v-text="errors.phone[index]"></strong>
                                                </span>
                                            </div>

                                            <!-- Checkbox -->
                                            <div class="form-check d-flex mb-4">
                                                <input class="form-check-input" type="checkbox" name="newsletter" v-model.number="newUser.newsletter"/>
                                                <label class="form-check-label ms-1">Ik wil graag de nieuwsbrief ontvangen</label>
                                            </div>

                                            <div v-if="errors.newsletter">
                                                <span class="alert-danger bg-transparent my-1 text-danger" role="alert"
                                                      v-for="(name, index) in errors.newsletter">
                                                    <strong v-text="errors.newsletter[index]"></strong>
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <button type="submit" class="btn btn-success text-white w-100">Verzenden</button>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div class="text-center p-4 pt-0" v-if="!$root.authUser && $root.agreement.services.includes('reports')">
                            <h4 class="h4 middle-border">Of</h4>

                            <h4 class="h4 mb-4">
                                <router-link to="login" class="text-decoration-none text-success">Login</router-link>
                                met uw inloggegevens of sociale media
                            </h4>

                            <div class="d-flex justify-content-center align-items-center social-icons mb-2">
                                <button type="button" @click="setSession('apple')" class="btn btn-apple">
                                    <i class="fab fa-apple"></i>
                                </button>
                                <button type="button" @click="setSession('google')" class="btn btn-google">
                                    <i class="fab fa-google text-white"></i>
                                </button>
                                <!-- <button type="button" class="btn btn-facebook" @click="socialLogin('facebook')">
                                    <i class="fab fa-facebook-square text-white"></i>
                                </button>
                                <button type="button" class="btn btn-twitter" @click="socialLogin('twitter')">
                                    <i class="fab fa-twitter text-white"></i>
                                </button>-->
                            </div>
                        </div>
                    </div>

                    <div v-show="isFormSubmitted && showForm" class="text-center bg-white rounded border border-primary p-4">
                        <div class="spinner-border text-success" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>

                <div class="col-md-8 center position-sticky mx-auto">
                    <div class="card my-3 shadow-sm opacity" v-show="formSuccess">
                        <div class="card-body p-md-3 p-2 text-center">
                            <img class="mb-4" :src="asset('images/approved.png')" alt="Goedgekeurd">

                            <h4 class="title">Uw melding is succesvol toegevoegd.</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ImageMixin from "../mixins/asset";
import _ from "lodash";
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
    name: "add-report",

    mixins: [ImageMixin],

    components: {
        VueperSlides,
        VueperSlide
    },

    data() {
        return {
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
            markers: [],
            categories: [],
            showProblem: false,
            showIdea: false,
            showForm: false,
            showPrivate: false,
            formSuccess: false,
            photos: [],
            photosPreview: [],
            report: {
                title: "",
                report_category_id: "",
                description: "",
                resolution: "",
                private_note: "",
                address: "",
                city: "",
                postcode: null,
            },
            newUser: {
                email: "",
                password: "",
                password_confirmation: "",
                gender: "male",
                first_name: "",
                middle_name: "",
                last_name: "",
                street: "",
                street_number: "",
                user_zip: "",
                user_city: "",
                phone: "",
                terms: "",
                newsletter: false,
            },
            center: {},
            mapLoaded: false,
            zoom: 12,
            options: {
                styles: [
                    {
                        featureType: "poi",
                        elementType: "labels",
                        stylers: [
                            {visibility: "off"}
                        ]
                    }
                ],
                minZoom: 9,
                streetViewControl: false,
                fullscreenControl: false,
            },
            tempLocation: {},
            marker: {},
            errors: [],
            errorPhotos: [],
            address: '',
            isFormSubmitted: false,
            timeout: null,
            controller: null,
        };
    },

    watch: {
        '$root.coordinates'(newValue) {
            this.center = newValue;
            this.marker = newValue;
            this.marker.icon = this.showProblem ? this.asset('images/icon_problem_normal.png') : this.asset('images/idea_not_fixed.png');

            this.getCategories();
            this.geocodedAddress();
        },
        '$root.mapLoaded'(newValue) {
            this.mapLoaded = newValue;
        },
        '$root.zoom'(newValue) {
            this.zoom = newValue;

            this.getCategories();
            this.geocodedAddress();
        },
    },

    mounted() {
        if (!this.$root.user.is_verified && this.$root.authUser) this.$router.push({name: 'settings'})

        this.mapLoaded = this.$root.mapLoaded;
        this.center = this.$root.coordinates;

        this.getCategories();

        if (window.localStorage.getItem('from_report')) {
            this.removeSession()
        }
    },

    methods: {
        onFileSelected(event) {
            this.photos = [];
            this.photosPreview = [];
            this.errorPhotos = [];

            this.photos = event.target.files;

            for (let i = 0; i < this.photos.length; i++) {
                let reader = new FileReader

                reader.onload = e => {
                    this.photosPreview.push(e.target.result)
                }

                reader.readAsDataURL(this.photos[i])
            }
        },

        goToMyLocation() {
            this.$root.coordinates = this.$root.myLocation;

            setTimeout(() => {
                this.$root.zoom = 14;
            }, 500)
        },

        geocodedAddress() {
            let self = this;
            this.report.postcode = null;
            this.report.address = null;
            this.report.city = null;

            return new Promise(function (resolve, reject) {
                let geocoder = new self.$root.google.maps.Geocoder();

                geocoder.geocode({'location': {
                        'lat': self.center.lat,
                        'lng': self.center.lng,
                    }, }, function (results, status) {
                    if (status === 'OK') {
                        if (self.timeout) clearTimeout(self.timeout);

                        let result = '';

                        if (results.filter(el => el.types[0] === 'premise').length > 0) {
                            result = results.filter(el => el.types[0] === 'premise')[0]
                        } else if (results.filter(el => el.types[0] === 'street_address').length > 0) {
                            result = results.filter(el => el.types[0] === 'street_address')[0]
                        } else {
                            result = results[0];
                        }

                        let postalCodeObj = _.find(result.address_components, function (ac) { return ac.types[0] == 'postal_code' });

                        let routeObj = _.find(result.address_components, function (ac) { return ac.types[0] == 'route' });
                        let route = '';

                        let streetNumberObj = _.find(result.address_components, function (ac) { return ac.types[0] == 'street_number' });
                        let streetNumber = '';

                        let city = _.find(result.address_components, function (ac) { return ac.types[0] == 'locality' });

                        if (postalCodeObj) self.report.postcode = postalCodeObj.short_name;
                        if (routeObj) route = routeObj.short_name;
                        if (streetNumberObj) streetNumber = streetNumberObj.short_name;
                        if (city) self.report.city = city.short_name;

                        self.report.address = route + ' ' + streetNumber;
                        if (self.report.address.trim() == '') self.report.address = result.formatted_address;

                        self.address = result.formatted_address;

                        return results.formatted_address;
                    } else {
                        if (status === 'OVER_QUERY_LIMIT') {
                            self.timeout = setTimeout(() => {
                                self.geocodedAddress()
                            }, 1000)
                        }
                    }
                })
            }).then(() => {})
        },

        getCategories() {
            if (this.controller) this.controller.abort();

            this.controller = new AbortController();

            axios.get("/api/report-categories?latitude=" + this.center.lat + "&longitude=" + this.center.lng, {
                signal: this.controller.signal
            }).then((res) => {
                if (res.status == 200) {
                    this.categories = res.data.data;

                    if (!_.find(this.categories, el => el.id === this.report.report_category_id)) this.report.report_category_id = '';
                }
            }).catch(() => {});
        },

        addMarker(from_social = false) {
            if (!from_social) {
                this.report = {
                    title: "",
                    report_category_id: "",
                    description: "",
                    resolution: "",
                    private_note: "",
                }
            }

            this.$root.coordinates = {
                'lat': this.center.lat,
                'lng': this.center.lng
            }

            this.zoom = this.$root.zoom;

            this.geocodedAddress();
        },

        setTempLocation(marker) {
            this.tempLocation = {};

            if (marker.geometry) {
                this.tempLocation = {
                    'lat': marker.geometry.location.lat(),
                    'lng': marker.geometry.location.lng()
                }
            }

            if (Object.keys(this.tempLocation).length > 0) {
                this.center = this.tempLocation;

                this.addMarker();
            }
        },

        mapMove(ref) {
            this.$root.coordinates = {
                lat: this.$refs[ref].$mapObject.center.lat(),
                lng: this.$refs[ref].$mapObject.center.lng(),
            };

            this.$root.zoom = this.$refs[ref].$mapObject.zoom;
        },

        updateCoordinates(marker) {
            this.$root.coordinates = {
                'lat': marker.lat(),
                'lng': marker.lng()
            }
        },

        saveData() {
            this.errors = [];
            this.isFormSubmitted = true;

            let form = new FormData();

            for (let key in this.report) {
                form.append(key, this.report[key]);
            }

            if (!this.$root.authUser) {
                for (let key in this.newUser) {
                    form.append(key, this.newUser[key]);
                }
            }

            form.append('latitude', this.center.lat);
            form.append('longitude', this.center.lng);
            form.append('type', this.showProblem ? 'problem' : 'idea');

            for (let i = 0; i < this.photos.length; i++) {
                form.append("photos[][file]", this.photos[i]);
            }

            axios.post("/api/reports", form, {
                "content-Type": "multipart/form-data",
            }).then((response) => {
                if (response.status == 200) {
                    if (!this.$root.authUser) {
                        axios.post("/login", {
                            'email': this.newUser.email,
                            'password': this.newUser.password,
                        }).then((response) => {
                            // Perform Success Action
                            this.$root.authUser = true;
                            this.$root.user = response.data.user;
                        }).catch((error) => {
                            this.isFormSubmitted = false;

                            for (let key in error.response.data.errors) {
                                this.errors[key] = error.response.data.errors[key];
                            }
                        })
                    }

                    this.showForm = false;
                    this.formSuccess = true;

                    window.setTimeout(() => {
                        this.$root.$router.push({name: "my-reports"});
                    }, 3000)
                }
            }).catch((error) => {
                this.errorPhotos = [];
                this.isFormSubmitted = false;

                this.errors = error.response.data.errors

                let search = ['photos.'];

                this.errorPhotos = _.pickBy(this.errors, (value, key) =>
                    _.some(search, str => _.includes(key, str))
                );
            });
        },

        setSession(provider) {
            let report_temp_id = Math.random().toString(36).slice(2);

            window.localStorage.setItem('from_report', true)
            window.localStorage.setItem('type', this.showProblem ? 'problem' : 'idea')
            window.localStorage.setItem('report', JSON.stringify(this.report))
            window.localStorage.setItem('center', JSON.stringify(this.center))
            window.localStorage.setItem('zoom', JSON.stringify(this.zoom))
            window.localStorage.setItem('show_private', this.showPrivate)
            window.localStorage.setItem('report_temp_id', report_temp_id)

            if (this.photosPreview.length > 0) {
                let form = new FormData();

                for (let i = 0; i < this.photos.length; i++) {
                    form.append("photos[][file]", this.photos[i]);
                }

                form.append('report_temp_id', report_temp_id)

                axios.post('/api/save-photos', form, {
                    "content-Type": "multipart/form-data",
                }).then(() => {});
            }

            window.location = '/social-register/' + provider;
        },

        removeSession() {
            if (window.localStorage.getItem('from_report')) {
                let type = window.localStorage.getItem('type');
                let data = JSON.parse(window.localStorage.getItem('report'));
                let center = JSON.parse(window.localStorage.getItem('center'));
                let zoom = JSON.parse(window.localStorage.getItem('zoom'));

                this.showForm = window.localStorage.getItem('from_report');
                this.showProblem = type && type === 'problem';
                this.showIdea = type && type === 'idea';
                this.showPrivate = window.localStorage.getItem('show_private');
                this.report = data;
                this.center = center;
                this.$root.coordinates = center;
                this.$root.zoom = zoom;
                this.photosPreview = [];

                axios.get('api/get-photos', {
                    params: {
                        report_temp_id:  window.localStorage.getItem('report_temp_id')
                    }
                }, {
                    "content-Type": "multipart/form-data",
                }).then(res => {
                    let data = res.data;
                    let self = this;

                    if (data.length) {
                        for (let i = 0; i < data.length; i++) {
                            fetch(data[i]['url']).then(res => res.blob())
                                .then(blob => {
                                    this.photos.push(new File([blob], data[i]['name'],{ type: blob.type }))

                                    let reader = new FileReader();

                                    reader.readAsDataURL(blob);

                                    reader.onloadend = function() {
                                        self.photosPreview.push(reader.result);
                                    }
                                })
                        }
                    }
                })

                this.addMarker(true)

                window.localStorage.removeItem('from_report')
                window.localStorage.removeItem('type')
                window.localStorage.removeItem('report')
                window.localStorage.removeItem('center')
                window.localStorage.removeItem('zoom')
                window.localStorage.removeItem('show_private')
                window.localStorage.removeItem('report_temp_id')
            }
        }
    },
};
</script>

<style scoped>
p {
    font-size: 20px;
}

.center {
    margin: 20px auto;
}

.searchbar {
    z-index: 20;
    top: 10px;
    right: 10px;
    width: 50%;
}

input[type="file"]::file-selector-button {
    background-color: #3ab53a;
    transition: 20s;
    color: white;
}

input[type="file"]::file-selector-button:hover {
    background-color: #58c058;
}

.report-body {
    overflow: auto;
    min-height: 300px;
    max-height: 90vh;
}

.my-location {
    width: 48px;
    height: 38px;
    background-size: 18px;
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid #ced4da;
}

.image {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 400px;
}

@media only screen and (max-width: 1920px) {
    .report-body {
        max-height: calc(100vh - 100px);
    }
}

@media only screen and (max-width: 768px) {
    .report-body {
        max-height: calc(100vh - 170px);
    }
}
</style>
