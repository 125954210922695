<script>
import AutocompleteMixin from "../../mixins/autocomplete";

export default {
    name: "user-create",

    mixins: [
        AutocompleteMixin
    ],

    props: [
        'redirectUrl',
        'councils',
        'roles',
        'userData',
        'submitUrl',
    ],

    data() {
        return {
            userRequest: {
                email: '',
                password: '',
                password_confirmation: '',
                gender: '',
                first_name: '',
                middle_name: '',
                last_name: '',
                street: '',
                street_number: '',
                city: '',
                postcode: '',
                phone: '',
                councils: null,
                roles: null,
            },
            errors: {},
        };
    },

    mounted() {
        this.userRequest = this.userData ? JSON.parse(this.userData) : {
            email: '',
            password: '',
            password_confirmation: '',
            gender: '',
            first_name: '',
            middle_name: '',
            last_name: '',
            street: '',
            street_number: '',
            city: '',
            postcode: '',
            phone: '',
            councils: [],
            roles: [],
        };
    },

    methods: {
        submit() {
            this.errors = [];

            axios.post(this.submitUrl, {
                'email': this.userRequest.email,
                'password': this.userRequest.password,
                'password_confirmation': this.userRequest.password_confirmation,
                'gender': this.userRequest.gender,
                'first_name': this.userRequest.first_name,
                'middle_name': this.userRequest.middle_name,
                'last_name': this.userRequest.last_name,
                'street': this.userRequest.street,
                'street_number': this.userRequest.street_number,
                'city': this.userRequest.city,
                'postcode': this.userRequest.postcode,
                'phone': this.userRequest.phone,
                'councils': this.userRequest.councils,
                'roles': this.userRequest.roles,
            }).then(res => {
                if (res.status === 200) {
                    sessionStorage.setItem("alert_type", "success");
                    sessionStorage.setItem("message", this.$gettext("User has been saved successfully"));

                    this.$root.$emit("show-flash-message");

                    window.location.href = this.redirectUrl;
                }
            }).catch(errors => {
                this.errors = JSON.parse(JSON.stringify(errors.response.data.errors));

                sessionStorage.setItem("alert_type", "danger");
                sessionStorage.setItem("message", this.$gettext("Something went wrong"));

                this.$root.$emit("show-flash-message");
            });
        },
    }
};
</script>
