<template>
    <div v-if="!accepted" class="cookie-policy-popup">
        <p>Deze website maakt gebruik van <router-link to="/cookie-beleid" class="text-decoration-none text-success fw-bold">cookies
        </router-link> om ervoor te zorgen dat u onze website zo goed mogelijk beleeft.</p>
        <button @click="accept">Aanvaarden</button>
        <button @click="revoke">Toestemming intrekken</button>
    </div>
</template>

<script>
export default {
    data() {
        return {
            accepted: false,
        };
    },
    methods: {
        accept() {
            this.accepted = true;
            // Set a cookie to remember user preference
            document.cookie = "cookie_policy_accepted=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/";
            // Set cookies for location and Google Analytics (can be customized for your specific use case)
            document.cookie = "location_consent=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/";
            document.cookie = "ga_consent=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/";
            document.cookie = ('cookie_clicked=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/');
        },
        revoke() {
            this.accepted = true;
            // Delete cookies for location and Google Analytics (can be customized for your specific use case)
            document.cookie = "location_consent=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
            document.cookie = "ga_consent=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
            // Delete cookie policy acceptance cookie
            document.cookie = "cookie_policy_accepted=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
            document.cookie = ('cookie_clicked=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/');
        },
    },
    created() {
        // Check if user has already accepted the cookie policy
        const cookies = document.cookie.split(';');

        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith('cookie_clicked=')) {
                this.accepted = true;
                break;
            }
        }
    },
};
</script>

<style>
.cookie-policy-popup {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    background-color: #333;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
}
.cookie-policy-popup p {
    margin: 0;
}
.cookie-policy-popup button {
    background-color: #fff;
    color: #333;
    border: none;
    padding: 5px 10px;
    margin-left: 10px;
    cursor: pointer;
}
</style>
