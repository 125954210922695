 <template>
    <div class="row mx-auto">
        <div class="col-md-8 col-12 mx-auto">
            <h1>{{ $gettext("Feedbacks") }}</h1>

            <div id="table-wrapper">
                <data-table
                    :columns="columns"
                    order-by="id"
                    order-dir="desc"
                    :per-page="['10', '25', '50', '100']"
                    :url="url"
                    :filters="filters"
                    ref="feedbacksTable"
                >
                    <div slot="filters" slot-scope="{ tableData, perPage }">
                        <div class="row mb-2">
                            <div class="col-md-4">
                                <select class="form-control w-auto me-md-2 me-0 form-input-height" v-model="filters.length">
                                    <option :key="page" v-for="page in perPage">
                                        {{ page }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-8 d-flex flex-column flex-md-row justify-content-end">
                                <input name="search" id="search" :placeholder="$gettext('Search feedbacks...')"
                                    class="form-control w-auto form-input-height" v-model="filters.search"/>
                            </div>
                        </div>
                    </div>
                    <tbody slot="body" slot-scope="{ data }">
                        <tr :key="item.id" v-for="item in data">
                            <td :key="column.name" v-for="column in columns">
                                <data-table-cell
                                    :value="item"
                                    :name="column.name"
                                    :meta="column.meta"
                                    :comp="column.component"
                                    :classes="column.classes"
                                >
                                </data-table-cell>
                            </td>
                        </tr>
                    </tbody>
                </data-table>
            </div>
        </div>
    </div>
</template>

<script>
import { DataTable } from "laravel-vue-datatable";
import Star from "./ComponentStar.vue";

export default {
    name: "feedbacks-table",

    props: ["base_url"],

    components: {
        DataTable,
        Star,
    },

    data() {
        return {
            url: "",
            filters: {
                search: "",
                length: 10,
            },
            columns: [
                {
                    label: "Id",
                    name: "id",
                    orderable: true,
                },
                {
                    label: this.$gettext("Title"),
                    name: "title",
                    orderable: true,
                },
                {
                    label: this.$gettext("Feedback"),
                    name: "feedback",
                    orderable: true,
                },
                {
                    label: this.$gettext("Rating"),
                    name: "rating",
                    orderable: true,
                    component: Star,
                },
            ],
        };
    },

    mounted() {
        this.url = this.base_url + "/admin/feedbacks/datatable";
    },
};
</script>
