<script>
import FlashMessage from "../FlashMessage";
import AutocompleteMixin from "../../mixins/autocomplete";
import ModalConfirmation from "./ModalConfirmation.vue";

export default {
    name: "report-edit",

    props: [
        'defaultContractor',
        'defaultAssignee',
        'templates',
        'mailTemplates',
        'contractorMailEndpoint',
        'reportData',
        'contractors',
        'deleteRemarkEndpoint'
    ],

    mixins: [
        AutocompleteMixin,
    ],

    components: {
        FlashMessage,
        ModalConfirmation,
    },

    data() {
        return {
            activeReaction: 0,
            activeReplies: 0,
            reply: {
                body: '',
                isInternal: 0,
            },
            reaction: {
                body: '',
                isInternal: 0,
            },
            googleApi: '',
            contractorId: '',
            assigneeId: '',
            templateId: '',
            emailTemplateId: '',
            success: false,
            report: {},
            errors: [],
            tempRemarkId: null,
            showAccordion: false,
            lastAccordionIndex: null,
            options: {
                year: "numeric",
                month: "2-digit",
                day: "2-digit"
            },
            loader: true,
            showDownloadLink:false,
        };
    },

    mounted() {
        this.reply.is_anonymous = this.$root.user && this.$root.user.full_name === '' ? 1 : 0;
        this.reaction.is_anonymous = this.$root.user && this.$root.user.full_name === '' ? 1 : 0;

        this.googleApi = this.$root.mapsKey;

        this.contractorId = this.defaultContractor ? parseInt(this.defaultContractor) : '';
        this.assigneeId = this.defaultAssignee ? parseInt(this.defaultAssignee) : '';

        this.report = JSON.parse(this.reportData);

        this.loader = false;

        this.$refs.reportContainer.classList.remove('d-none');

        this.showDownloadLink = (this.report.urls.length > 0) ? true : false;
    },

    methods: {
        showArea(id) {
            this.reply = {
                body: '',
                isInternal: this.$root.user && this.$root.user.full_name === '' ? 1 : 0,
            };

            this.activeReaction == id ? this.activeReaction = 0 : this.activeReaction = id;
        },

        showReplies(id) {
            this.activeReplies == id ? this.activeReplies = 0 : this.activeReplies = id;
        },

        hideModal(id) {
            let buttonClose = document.getElementById(id).getElementsByClassName('close');

            if (buttonClose) buttonClose[0].click();
        },

        addRemark(id) {
            axios.post('/api/report-remarks', {
                'report_id': id,
                'body': this.reaction.body,
                'is_internal': this.reaction.isInternal,
                'is_anonymous': this.reaction.is_anonymous,
                'is_backend': true,
            }).then(res => {
                if (res.status === 200) {
                    this.reaction = {
                        body: '',
                        is_anonymous: this.$root.user && this.$root.user.full_name === '' ? 1 : 0,
                    };

                    this.showAccordion = false;

                    let remark = res.data.data;

                    remark.children = [];

                    this.report.remarks_with_internal.unshift(remark)

                    this.hideModal('reactModal')

                    sessionStorage.setItem("alert_type", "success");
                    sessionStorage.setItem("message", this.$gettext("Remark has been added successfully"));

                    this.$root.$emit("show-flash-message");
                }
            }).catch(errors => {

                sessionStorage.setItem("alert_type", "danger");
                sessionStorage.setItem("message", this.$gettext("Something went wrong"));

                this.$root.$emit("show-flash-message");

                this.$refs.Close.click();

                this.$root.$emit('modal-message-ajax', {
                    title: this.$gettext('Error'),
                    body: this.$gettext(errors.response.data.message),
                });
            });
        },

        addReply(parent, id) {
            axios.post('/api/report-remarks', {
                'parent_id': parent,
                'report_id': id,
                'body': this.reply.body,
                'is_internal': this.reply.isInternal,
                'is_anonymous': this.reply.is_anonymous,
                'is_backend' : true,
            }).then(res => {
                if (res.status === 200) {
                    this.reply = {
                        body: '',
                        is_anonymous: this.$root.user && this.$root.user.full_name === '' ? 1 : 0,
                    };

                    this.showAccordion = false;

                    let reply = res.data.data;

                    reply.children = [];

                    this.report.remarks_with_internal.find(el => el.id === parent)['children'].unshift(reply);

                    this.report.remarks_with_internal.unshift(reply)

                    this.showAccordion = true;

                    this.resetFields(this.lastAccordionIndex, true);

                    sessionStorage.setItem("alert_type", "success");
                    sessionStorage.setItem("message", this.$gettext("Reply has been added successfully"));

                    this.$root.$emit("show-flash-message");
                }
            }).catch(errors => {
                sessionStorage.setItem("alert_type", "danger");
                sessionStorage.setItem("message", this.$gettext("Something went wrong"));

                this.$root.$emit("show-flash-message");

                this.$refs.Close.click();

                this.$root.$emit('modal-message-ajax', {
                    title: this.$gettext('Error'),
                    body: this.$gettext(errors.response.data.message),
                });
            });
        },

        changeContractor(id) {
            this.contractorId = this.contractorId != '' ? this.contractorId : null;

            axios.patch('/api/reports/' + id, {
                'contractor_id': this.contractorId,
            }).then(res => {
                if (res.status == 200) {
                    this.success = true;

                    this.report.contractor_id = this.contractorId;
                    this.report.contractor = JSON.parse(this.contractors).filter(el => el.id === this.contractorId)[0];
                }
            }).catch(errors => {

                sessionStorage.setItem("alert_type", "danger");
                sessionStorage.setItem("message", this.$gettext("Something went wrong"));

                this.$root.$emit("show-flash-message");

                this.$root.$emit('modal-message-ajax', {
                    title: this.$gettext('Error'),
                    body: this.$gettext(errors.response.data.message),
                });
            });
        },

        changeAssignee(id) {
            axios.patch('/api/reports/' + id, {
                'assignee_id': this.assigneeId,
            }).then(res => {
                if (res.status == 200) this.success = true;
            })
        },

        getBody(type) {
            if (this.templateId !== '') {
                this[type].body = JSON.parse(this.templates).filter(el => el.id === parseInt(this.templateId))[0].text;
            } else {
                this[type].body = '';
            }
        },

        resetFields(index, fromSubmit = false) {
            window.setTimeout(() => {
                if (this.lastAccordionIndex !== null && parseInt(this.lastAccordionIndex) === parseInt(fromSubmit ? (index + 1) : index)) {
                    this.showAccordion = !this.showAccordion;
                } else {
                    this.showAccordion = true;
                }

                if (fromSubmit) {
                    let collapses = document.getElementsByClassName('accordion-collapse')

                    collapses.forEach((el) => {
                        let bsCollapse = new bootstrap.Collapse(el, {
                            toggle: false,
                        })

                        bsCollapse.hide()
                    })
                } else {
                    let collapse = document.getElementById('collapse' + index)

                    if (collapse) {
                        let bsCollapse = new bootstrap.Collapse(collapse, {
                            toggle: false,
                        })

                        if (!this.showAccordion) bsCollapse.hide()
                    }
                }

                this.lastAccordionIndex = index;
            }, 500)

            this.templateId = '';

            this.reply = {
                body: '',
                isInternal: this.$root.user && this.$root.user.full_name === '' ? 1 : 0,
            };

            this.reaction = {
                body: '',
                isInternal: this.$root.user && this.$root.user.full_name === '' ? 1 : 0,
            };
        },

        sendContractorMail(report_id, contractor_id) {
            axios.post(this.contractorMailEndpoint, {
                template_id: this.emailTemplateId,
                report_id: report_id,
                contractor_id: contractor_id
            }).then(() => {
                this.emailTemplateId = '';
            })
        },

        deleteRemark() {
            axios.delete(this.deleteRemarkEndpoint + '?id=' + this.tempRemarkId).then(res => {
                let self = this;

                if (res.status == 200) {
                    sessionStorage.setItem("alert_type", "success");
                    sessionStorage.setItem("message", this.$gettext("Remark has been deleted successfully"));

                    this.$root.$emit("show-flash-message");

                    let deletedReport = this.report.remarks_with_internal.find(function(el) {
                        return el.id === self.tempRemarkId;
                    })

                    this.report.remarks_with_internal = this.report.remarks_with_internal.filter(function(el) {
                        return el.id != self.tempRemarkId;
                    });

                    if (deletedReport.parent_id) {
                        let parent = this.report.remarks_with_internal.find(
                            function(el) { return el.id === deletedReport.parent_id; }
                        );

                        let children = parent['children'].filter(function(el) {
                            return el.id != deletedReport.id;
                        });

                        parent['children'] = children;
                    } else {
                        this.report.remarks_with_internal = this.report.remarks_with_internal.filter(function(el) {
                            return el.parent_id != self.tempRemarkId;
                        });
                    }
                }
            }).catch(errors => {
                this.errors = errors.response.data.errors

                sessionStorage.setItem("alert_type", "danger");
                sessionStorage.setItem("message", this.$gettext("Something went wrong"));

                this.$root.$emit("show-flash-message");
            });
        },

        dateFormat(date) {
            return new Date(date).toLocaleTimeString('nl', this.options);
        },
    },
};
</script>

<style scoped>
.accordion-item p {
    font-size: 16px;
}

.image {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 600px;
    height: 400px;
}

.image-container {
    position:relative;
}

.image-container a {
    position:absolute;
    top:90%;
    left:92%;
    z-index:99;
}

.image-container a:hover{
    cursor:pointer;
    font-size:21px;
}

.image-container a > i{
    color:#3ab53a;
}

.single-image-container {
    background: linear-gradient(to bottom, #f1f1f1, #ffffff, #f3f3f3);
}

@media only screen and (max-width: 1400px) {
    .image {
        width: 100%;
    }
}
</style>
